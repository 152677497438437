import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Layout from '../src/layout/layout'
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux';

import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


import './index.css';
import '../src/assets/css/custom.css';
import store from './store';


const app = (
    <React.Fragment>
        <Provider store={store}>
            <Router>
                <Layout>
                    <App/>
                </Layout>
            </Router>
        </Provider>
        <ToastContainer/>
    </React.Fragment>
);

ReactDOM.render(app, document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
