import axios from 'axios';
import endpoints from './api_endpoints';
import cookie from 'react-cookies';
import {LOGIN} from "../store/auth/actionTypes";
import {getCurrentUser} from "../store/auth/actions";
import {clearForm} from "../store/basf/actions";
// import {UPLOAD_NEW_UPDATE} from "../store/user/actionTypes";
import {UPLOAD_NEW_UPDATE} from "../store/act_observation/actionTypes";
import {getPhotosAndVideosUpdates} from "../store/common/actions";
import {DISABLE_CLIENT} from "../store/distributor/actionTypes";
import {getDistributorClients} from "../store/distributor/actions";




const logSuccess = (res, type) => {
    if (process.env.NODE_ENV === "production") {
        return;
    }

    console.log(
        '%cAPI Response', 'background: green; color: black', type, '\n',
        // 'URL:', res.config.url, '\n',
        'Response:', res, '\n',
        'Data:', res.data,
    )
};

const logFailure = (err, type) => {
    if (process.env.NODE_ENV === "production") {
        return;
    }

    if (err.response !== undefined) {
        console.error(
            '%cAPI Error', 'background: red; color: white', type, '\n',
            'Error:', err, '\n',
            // 'URL:', err.config.url, '\n',
            'Response:', err.response,
            'Data:', err.response.data,
        )
    } else {
        console.error(
            '%cAPI Error', 'background: red; color: white', type, '\n',
            'Error:', err, '\n',
            // 'URL:', err.config.url, '\n',
        )
    }
};

const handleAlternativeActions = (dispatch, type, payload, state, previousPayload) => {
    if (type === LOGIN.concat("_SUCCESS")) {
        dispatch(getCurrentUser());
    }
    if (type === UPLOAD_NEW_UPDATE.concat("_SUCCESS")) {
        // dispatch(getPhotosAndVideosUpdates());
        dispatch(clearForm());
    }
    if (type === DISABLE_CLIENT.concat("_SUCCESS")) {
        dispatch(getDistributorClients());
    }
};

const handleErrorActions = (dispatch, type) => {
    // if (type === LEAVE_CHAT) {
    //     dispatch(fetchChannels())

    // }

};

const handleApiCall = (store, action) => {
    // console.log("API_URL", process.env.REACT_APP_API_HOST)
    const state = store.getState();
    const {dispatch} = store;
    const instance = axios.create();


    instance.interceptors.response.use(
        response => response,
        (error) => {
            return Promise.reject(error)
        },
    );

    instance.request({
        baseURL: process.env.REACT_APP_API_HOST,
        headers: {
            'Content-Type': action.file ? 'multipart/form-data' : 'application/json',
            ...(cookie.load('token') ? {'Bearer': cookie.load('token')} : {})
        },
        method: endpoints[action.type]?.method,
        url: endpoints[action.type]?.endpoint(state, action),
        data: (
            endpoints[action.type]?.payload === undefined
                ? undefined
                : endpoints[action.type].payload(state, action)
        ),
    })
        .then((res) => {
            dispatch({
                type: action.type.concat('_SUCCESS'),
                payload: res.data,
                requestAction: action,
            });
            handleAlternativeActions(dispatch, action.type.concat('_SUCCESS'), res.data, state, action.payload)
        })
        .catch((err) => {
            dispatch({type: action.type.concat('_FAILURE'), payload: err.response, requestAction: action});

            logFailure(err, action.type);
            handleErrorActions(dispatch, action.type.concat('_FAILURE'));

            if (err.response !== undefined) {
                // handleErrorMessage(
                //     dispatch,
                //     err.response.data,
                // )
            }
        })
};


export default (store) => (next) => (action) => {
    if (action.api === true) handleApiCall(store, action);
    next(action)
}