export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const INIT_RECOVER_PASSWORD_PROCEDURE = 'INIT_RECOVER_PASSWORD_PROCEDURE';
export const CONFIRM_NEW_PASSWORD = 'CONFIRM_NEW_PASSWORD';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const SET_REDIRECT_TO_LOGIN_FALSE = 'SET_REDIRECT_TO_LOGIN_FALSE';





