import React from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import {
    useLocation
} from "react-router-dom";

const Layout = (props) => {
    const location = useLocation();
    return (
        <div>
            {location.pathname === '/recover-password' || location.pathname === '/coming-soon' || location.pathname.includes('/confirm-password') ?
                <>{props.children}</>
                :
                <><Header/>
                    <div className='layout-wrapper'>
                        {props.children}
                    </div>
                    <Footer/>
                </>
            }
        </div>
    )
}

export default Layout;