import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { doLogin, setRedirectToLoginFalse } from '../store/auth/actions'

import PermIdentityIcon from '@material-ui/icons/PermIdentity'

import '../assets/css/style.min.css'
import '../assets/css/colors/green.css'

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user_data: {
        email: '',
        password: '',
      },
      errors: {},
    }
  }

  componentDidMount() {
    const { setRedirectToLoginFalse, redirect_to_login } = this.props
    if (redirect_to_login) {
      setRedirectToLoginFalse()
    }
  }

  handleLogin = (e) => {
    e.preventDefault()
    const { doLogin } = this.props
    const { user_data } = this.state
    if (this.handleValidation()) {
      doLogin(user_data)
    }
  }

  handleValidation = () => {
    let form_is_valid = true
    const { user_data, errors } = this.state
    let mail_regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (!user_data.email.trim() || !mail_regex.test(user_data.email)) {
      form_is_valid = false
      errors.email = true
    }
    if (!user_data.password.trim()) {
      form_is_valid = false
      errors.password = true
    }
    this.setState((prevState) => ({
      ...prevState,
      errors: errors,
    }))

    return form_is_valid
  }

  initRecoverPassword = () => {
    this.props.history.push('/recover-password')
  }

  render() {
    const { errors } = this.state
    
    console.log("CURRECT_USER", this.props.auth.user);

    // setTimeout(() => {
    //   if (this.props.auth.token !== null && this.props.auth.loading === false) {      
    //     const url = '/'.concat(this.props.auth.user?.roleName.toLowerCase().concat('-profile'))
    //     console.log("URL", url);
    //     return this.props.history.push(url)
    //   }
    // }, 500)


    setTimeout(() => {
      if (this.props.auth.token !== null && this.props.auth.loading === false) {      
        // const url = '/'.concat(this.props.auth.user?.role.toLowerCase().concat('-profile'))

        console.log("Roleeeeeeeeee", this.props.auth.user.role.toLowerCase());
        
        if(this.props.auth.user.role.toLowerCase() === 'a' || this.props.auth.user.role.toLowerCase() === 'm'){
          return this.props.history.push("/dashboard")
        }else if(this.props.auth.user.role.toLowerCase() === 'o'){
          return this.props.history.push('/akt-konstatim')
        }
        
      }
    }, 500)

    return (
      <React.Fragment>
        <section className="bg-auth-home">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-7 m-auto">
                <div className="card shadow rounded border-0">
                  <div className="card-body">
                    <h4 className="card-title text-left" style={{ color: '#124057' }}>Identifikohu</h4>
                    <p className="border-bottom pb-2" />
                    <form className="login-form mt-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <label
                              className={
                                errors.email
                                  ? 'form-label label_error'
                                  : 'form-label'
                              }
                            >
                              E-mail<span className="text-danger">*</span>
                            </label>
                            <div className="form-icon position-relative">
                              <PermIdentityIcon className="fea icon-sm custom_icon" />
                              <input
                                type="email"                                
                                autoComplete="off"
                                className={
                                  errors.email
                                    ? 'form-control ps-5 input_error'
                                    : 'form-control ps-5'
                                }
                                onChange={(event) => {
                                  const value = event.target.value
                                  this.setState((prevState) => ({
                                    ...prevState,
                                    user_data: {
                                      ...prevState.user_data,
                                      email: value,
                                    },
                                    errors: {
                                      ...prevState.errors,
                                      email: false,
                                    },
                                  }))
                                }}
                                placeholder="Email"
                                name="email"
                                required=""
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="mb-3">
                            <label
                              className={
                                errors.password
                                  ? 'form-label label_error'
                                  : 'form-label'
                              }
                            >
                              Passwordi<span className="text-danger">*</span>
                            </label>
                            <div className="form-icon position-relative">
                              <i
                                data-feather="key"
                                className="uil uil-key-skeleton fea icon-sm custom_icon"
                              />
                              <input
                                type="password"
                                autoComplete="new-password"
                                className={
                                  errors.password
                                    ? 'form-control ps-5 input_error'
                                    : 'form-control ps-5'
                                }
                                onChange={(event) => {
                                  const value = event.target.value
                                  this.setState((prevState) => ({
                                    ...prevState,
                                    user_data: {
                                      ...prevState.user_data,
                                      password: value,
                                    },
                                    errors: {
                                      ...prevState.errors,
                                      password: false,
                                    },
                                  }))
                                }}
                                placeholder="Password"
                                required=""
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="d-flex justify-content-between">
                            <div className="mb-3 invisible">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                />
                                <label className="form-check-label" htmlFor="flexCheckDefault">Ricordami</label>
                              </div>
                            </div>
                            <p className="forgot-pass mb-0">
                              <a className="text-dark fw-bold cursor_pointer" onClick={() => this.initRecoverPassword()}>
                                Keni harruar fjalëkalimin?
                              </a>
                            </p>
                          </div>
                        </div>

                        <div className="col-lg-12 mb-0">
                          <div className="d-grid">
                            <button className="btn btn-primary border-button" onClick={(e) => this.handleLogin(e)}>Hyr</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = {
  doLogin,
  setRedirectToLoginFalse,
}

const mapStateToProps = (state) => {
  return {
    redirect_to_login: state.auth.redirect_to_login,
    auth: state.auth,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
