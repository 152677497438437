import React, { Component } from 'react'
import { Icon } from '@iconify/react'
import RadioButton from '../components/styled_components/RadioButton'
import Swal from 'sweetalert2'

//ak_firma_af
//ak_firma_gp

import { connect } from 'react-redux'
import {
  getDistrictList,
  getMunicipalityList,
  getAdministrativeList,
  getVillageList,
  setActObservationValue,
  setFamilyMemberValue,
  registerAktObservation,
  setErrorMapTrue,
  setErrorMapFalse,
  setErrorCodeFalse,
  uploadMedia,
  uploadNewUpdate,
  uploadWorkingGroupSignature,
  uploadFamilyMembersSignature,
  // setNewUpdateValue,
} from '../store/act_observation/actions'
import { toast } from "react-toastify";


import Map from '../components/vector_map/vector_map'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import SimpleBar from 'simplebar-react'
import { sorting } from '../assets/helpers/helpers'
import DotLoader from 'react-spinners/DotLoader';

import BeatLoader from 'react-spinners/BeatLoader';

import SyncLoader from 'react-spinners/SyncLoader';

import 'simplebar/dist/simplebar.min.css'
import '../assets/css/materialdesignicons.min.css'
import '../assets/css/custom.css'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Button, Col, Row } from 'reactstrap'
import SignatureCanvas from 'react-signature-canvas'

import {
  capitalize,
  clearApiBaseUrl,
  formatUpdateCreationDate,
} from '../assets/helpers/helpers'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import Cookies from "js-cookie";

class ActObservation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      act_observation_data: props.act_observation.act_observation_data,
      errors: {
        bussines_logo_error_show: false,
        product_image_error_show: false,
      },
      bussines_logo_error_show: false,
      product_image_error_show: false,
      bussines_product_img_error_show: false,
      family_member_errors: {},
      new_update: props.act_observation.new_update,
      year: new Date().getFullYear(),
      showAlertFamilyMember: false,
      show_save_act_observation_data_alert: false,
      family_members_form_data: {},
      default_district_value: 'Zgjidh qarkun ...',
      default_municipality_value: 'Zgjidh bashkinë ...',
      default_administrative_unit_value: 'Zgjidh njësin administrative ...',
      default_village_value: 'Zgjidh Fshatin ...',
      surname_equal_to_head_family_memeber: false,
      
      
      education_list: [
        ['I ulët', 'i ulet'],
        ['I mesëm', 'i mesem'],
        ['I lart', 'i lart'],
        ['Tjetër', 'Tjetër'],
      ],
      defaultEducation: 'ulet',
      employeed_list: [
        ['Minoren', 'minoren'],
        ['I/E punësuar', 'punesuar'],
        ['I/E vetpunësuar', 'vetpunesuar'],
        ['I/E papunë', 'papune'],        
      ],

      selected_employeed_value: 'jo',

      agricultural_land_list: [
        ['Po', 'po'],
        ['Jo', 'jo'],
      ],
      selected_agriculture_land_value: 'jo',

      livestock_list: [
        ['Po', 'po'],
        ['Jo', 'jo'],
      ],
      selected_livestock_value: 'jo',

      kohzgjatja_e_perfitimit_ne_skemen_e_ndihmes_ekonomike_values: [
        ['1 Vit', '1-vit'],
        ['1-2 Vite', '1-2-vite'],
        ['3 Vite', '3-vite'],
        ['Më shumë se 3 Vite', '>3vite'],
      ],

      selected_kohzgjatja_e_perfitimit_value: '1-vit',

      disability_pension_values: [
        ['Po', 'po'],
        ['Jo', 'jo'],
      ],
      selected_disability_pension_value: 'po',

      
      old_age_pension_values: [
        ['Po', 'po'],
        ['Jo', 'jo'],
      ],

      possible_values: [
        ['Po', 'po'],
        ['Jo', 'jo'],
      ],


      social_economic_possible_values: [
        ['Po', 'po'],
        ['Jo', 'jo'],
        ['Do Shqyrtohet', 'do_shqyrtohet'],
      ],


      motor_vehicles_owned_by_family_values: [
        ['Motoçikletë/Motor me kubik të vogël', 'Motoçikletë/Motor me kubik të vogël'],
        ['Makinë/Veturë', 'Makinë/Veturë'],
        ['Kamion','Kamion'],
        ['Makinë Dumdum','Makinë Dumdum'],
        ['Makinë bujqësore','Makinë bujqësore'],
        ['Ska mjet motorrik','Ska mjet motorrik'],
        ['Tjetër', 'Tjetër']        
      ],


      major_members_found_in_the_apartment: [
        ['Mario Rossi', 'Mario Rossi'],
        ['Angelo Di Maria', 'Angelo Di Maria'],
        ['Adrian Timori', 'Adrian Timori'],       
      ],

      type_of_housing_values:[
        ['Shtëpi private','Shtëpi private'],
        ['Apartament','Apartament'],
        ['Tjetër', 'Tjetër']
      ],

      apartment_condition_values: [
        ['Gjendje shumë e mirë', 'Gjendje shumë e mirë'],
        ['Gjendje e mirë', 'Gjendje e mirë'],
        ['E përshtatshme për jetesë','E përshtatshme për jetesë'],
        ['E papërshtatshme për jetesë','E papërshtatshme për jetesë'],
        ['Në ndërtim', 'Në ndërtim']
      ],

      location: false,
      latitude: null,
      longitude: null,
      max_file_upload_limit: 5,
      selectedFiles: [],

      need_family_other_services_values: [
        ['Për shtëpi', 'Për shtëpi'],
        ['Për shërbime shëndetesore', 'Për shërbime shëndetesore'],
        ['Tjetër', 'Tjetër']
      ],

      members_who_sign: {id: '', firstName: '', lastName: ''},
      
    }
    
  }

  
  working_group_sign = {}  
  family_members_who_sign= {}


  componentDidMount() {
    window.scrollTo(0, 0)
    const {
      setActObservationValue,
      setErrorMapFalse,
      act_observation,
      getDistrictList,
      getMunicipalityList,
      getAdministrativeList,
      getVillageList,
    } = this.props
    if (act_observation.error_map) {
      setErrorMapFalse()
    }

    // console.log('DISTRICT_LISt', act_observation)
    if (
      act_observation.district_list.list.data.length === 0 &&
      act_observation.district_list.list.loading === false
    ) {
      getDistrictList()
    }

    if (
      act_observation.municipality_list.list.data.length === 0 &&
      act_observation.municipality_list.list.loading === false
    ) {
      getMunicipalityList()
    }

    if (
      act_observation.administrative_unit_list.list.data.length === 0 &&
      act_observation.administrative_unit_list.list.loading === false
    ) {
      getAdministrativeList()
    }

    if (
      act_observation.village_list.list.data.length === 0 &&
      act_observation.village_list.list.loading === false
    ) {
      getVillageList()
    }

    if(!act_observation.act_observation_data.userID){
      const userID = Cookies.get("id");
      setActObservationValue("userID", userID)
    }

  }



  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.act_observation.redirect_to_home !== this.props.act_observation.redirect_to_home) {

      // if(prevProps.act_observation.uploaded_documents.length > 0){
      //   const {uploadNewUpdate} = this.props;

      //   toast.info("please wait uploading documents");
      //   const data = this.props.act_observation.uploaded_documents;
      //   data.user_id = this.props.act_observation.act_observation_data.userID;
      //   data.act_observation_id = this.props.act_observation.act_observation.act_observation_id;


      //   // uploadNewUpdate(data);        
      //   // uploadNewUpdate(data);
      // }

      this.props.history.push('/dashboard')
      

      // setTimeout(
      //   function() {
          
      //     window.scrollTo(0, 0, 'smooth');

      //     window.location.reload(false);
      //   }
      //     .bind(this),
      //     3000
      // );
    }
  }



  handleFormValidation = () => {
    const {
      errors,
      family_member_errors,
      bussines_logo_error_show,
      product_image_error_show,
    } = this.state
    const form_data = this.props.act_observation.act_observation_data
    const { setErrorMapTrue, setActObservationValue } = this.props
    let form_is_valid = true
    let mail_regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let phone_regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g

    if (!form_data?.name?.trim()) {
      errors.name = true
      form_is_valid = false
    }

    if (!form_data?.surname?.trim()) {
      errors.surname = true
      form_is_valid = false
    }

    if (!form_data?.fatherName?.trim()) {
      errors.fatherName = true
      form_is_valid = false
    }

    // if (!form_data?.cardID?.trim()) {
    //   errors.cardID = true
    //   form_is_valid = false
    // }

    if (!form_data?.district?.trim()) {
      errors.district = true
      form_is_valid = false
    }

    
    if (!form_data?.municipality?.trim()) {
      errors.municipality = true
      form_is_valid = false
    }


    if (!form_data?.administrative_unit?.trim()) {
      errors.administrative_unit = true
      form_is_valid = false
    }

    if (!form_data?.village?.trim()) {
      errors.village = true
      form_is_valid = false
    }

    if (!form_data?.economic_assistance?.trim()) {
      errors.economic_assistance = true
      form_is_valid = false
    }

    if (!form_data?.disability_pension?.trim()) {
      errors.disability_pension = true
      form_is_valid = false
    }
    
    if (!form_data?.old_age_pension?.trim()) {
      errors.old_age_pension = true
      form_is_valid = false
    }

    if (!form_data?.employeed_in_akpda?.trim()) {
      errors.employeed_in_akpda = true
      form_is_valid = false
    }

    if (!form_data?.motor_vehicle_owned?.trim()) {
      errors.motor_vehicle_owned = true
      form_is_valid = false
    }

    if (!form_data?.type_of_housing?.trim()) {
      errors.type_of_housing = true
      form_is_valid = false
    }


    if (!form_data?.type_of_housing_other?.trim()) {
      errors.type_of_housing_other = true
      form_is_valid = false
    }


    if (!form_data?.apartment_condition?.trim()) {
      errors.apartment_condition = true
      form_is_valid = false
    }
        

    if (!form_data?.located_family_condition?.trim()) {
      errors.located_family_condition = true
      form_is_valid = false
    }

    
    if (!form_data?.social_economic_assessment?.trim()) {
      errors.social_economic_assessment = true
      form_is_valid = false
    }

    if(form_data.social_economic_assessment === 'po'){
      if (!form_data?.family_economic_bad_condition?.trim()) {
        errors.family_economic_bad_condition = true
        form_is_valid = false
      }


      if (form_data.family_economic_bad_condition === 'po'){
        if (!form_data?.need_family_other_services?.trim()) {
          errors.need_family_other_services = true
          form_is_valid = false
        }
      }

    }

    

    
    // if(form_data.form_was_filled_at_home === 'po'){
    //   if (!form_data?.currentLocation?.trim()) {
    //     errors.currentLocation = true
    //     form_is_valid = false
    //   }
    // }
    
    // if (!form_data?.file?.trim()) {
    //   errors.file = true
    //   form_is_valid = false
    // }

    
    // if (!form_data?.currentLocation?.trim()) {
    //   errors.currentLocation = true
    //   form_is_valid = false
    // }

    // if (!form_data?.family_members_signature?.trim()) {
    //   errors.family_members_signature = true
    //   form_is_valid = false
    // }


    // if (!form_data?.working_group_members_signature?.trim()) {
    //   errors.working_group_members_signature = true
    //   form_is_valid = false
    // }

    
    // if (!form_data?.form_was_filled_at_home?.trim()) {
    //   errors.form_was_filled_at_home = true
    //   form_is_valid = false
    // }

    
    

    console.log('ACT_OBSERVATION_FORM_DATA', form_data)
    console.log('ACT_OBSERVATION_FORM_DATA_ERRORS', errors)




    if(form_data.family_present_in_the_apartment === 'jo'){
      errors.economic_assistance = false
      errors.disability_pension = false
      errors.old_age_pension = false
      errors.employeed_in_akpda = false
      errors.motor_vehicle_owned = false
      errors.type_of_housing = false
      errors.type_of_housing_other = false
      errors.apartment_condition = false
      errors.located_family_condition = false
      errors.social_economic_assessment = false
      errors.currentLocation = false      
    }

    if(form_data.type_of_housing !== "Tjetër"){
      errors.type_of_housing_other = false
    }


    if(form_data.family_economic_bad_condition !== "Tjetër"){
      errors.type_of_housing_other = false
    }

    if(form_data.social_economic_assessment !== 'po'){
      errors.family_economic_bad_condition = false
      errors.need_family_other_services = false;
    }else{
      if(form_data.family_economic_bad_condition === 'jo'){
        errors.need_family_other_services = false;
      }
    }



    const all_errors = Object.entries(errors).filter(
      (error) => error[1] === true
    )

    console.log("MILAN", all_errors.length, all_errors)
    console.log("INTER", form_is_valid)

    if(all_errors.length === 0){
      form_is_valid = true

      this.setState((prevState) => ({
        ...prevState,
        errors: errors,
      }))
      return form_is_valid
    }else{

      // this.scrollToFirstError(errors)

      // toast.error("Sigurohuni te keni plotesuar te gjitha fushat!", {position: "top-center"})

      Swal.fire({
        position: "top-center",
        icon: "error",
        title: "KUJDES...",
        text: `Sigurohuni te keni plotesuar te gjitha fushat!`,
      });

      this.setState((prevState) => ({
        ...prevState,
        errors: errors,
      }))
      return form_is_valid

    }

    // this.scrollToFirstError(errors)

    // this.setState((prevState) => ({
    //   ...prevState,
    //   errors: errors,
    // }))
    // return form_is_valid
  }


  // convertImageToBase64 = (file, callback) => {
  //   let reader = new FileReader()
  //   reader.readAsDataURL(file)

  //   reader.onload = function () {
  //     const image = new Image()
  //     image.crossOrigin = 'anonymous'
  //     image.onload = () => {
  //       callback(reader.result, image.naturalWidth, image.naturalHeight)
  //     }
  //     image.src = reader.result
  //   }
  //   reader.onerror = function (error) {
  //     console.log('Error: ', error)
  //   }
  // }

  initActObservationRegistration = (event) => {
    const self = this
    event.preventDefault()
    const { registerAktObservation, act_observation, setActObservationValue, uploadNewUpdate, uploadWorkingGroupSignature, uploadFamilyMembersSignature} = self.props
    const act_observation_data = act_observation.act_observation_data
    // const data = this.props.act_observation.new_update

    // self.setState({
    //   show_save_act_observation_data_alert: true
    // })

    if (self.handleFormValidation()) {

      // const data = this.props.act_observation.uploaded_documents;
      // data.user_id = this.props.act_observation.act_observation_data.userID;

      // uploadNewUpdate(data);
      
      registerAktObservation(act_observation_data)
      
    }
  }

  //===================== BEGIN WORKING GROUP SIGN =================================
  handleSaveWorkingGroupSign = (event) => {
    const files = [];
    const self = this;
    event.preventDefault()
    const {uploadWorkingGroupSignature} = self.props;

    // console.log("FIRMA", self.working_group_sign);

    // console.log("FIRMA", self.working_group_sign.getTrimmedCanvas().toDataURL('image/png'));
    const file = this.dataUrlToFile(self.working_group_sign.getTrimmedCanvas().toDataURL('image/png'))

    console.log("FILE TO UPLOAD", file);

    files.push(file)

    let data = Array.from(files);
    data.user_id = this.props.act_observation.act_observation_data.userID;
    data.file_reference = 'ak_firma_grupi_punes';
    uploadWorkingGroupSignature(data)
  }
  
  
  handleClearWorkingGroupSign = (event) => {
    const self = this;
    event.preventDefault()
    self.working_group_sign.clear();
  }

  //===================== END WORKING GROUP SIGN =================================







  //===================== BEGIN FAMILY MEMBERS SIGN =================================

  handle_save_family_members_sign = (event) => {
    const files = [];
    const self = this;
    event.preventDefault()
    const {uploadFamilyMembersSignature} = self.props;

    // console.log("FIRMA", self.working_group_sign.getTrimmedCanvas().toDataURL('image/png'));
    const file = self.dataUrlToFile(self.family_members_who_sign.getTrimmedCanvas().toDataURL('image/png'))

    console.log("FAMILY MEMBER FILE TO UPLOAD", file);

    files.push(file)

    let data = Array.from(files);
    data.user_id = self.props.act_observation.act_observation_data.userID;
    data.file_reference = 'ak_firma_antaret_e_familjes';

    uploadFamilyMembersSignature(data)
  }


  handle_clear_family_members_sign = (event) => {
    const self = this;
    event.preventDefault()
    self.family_members_who_sign.clear();
  }
//===================== END FAMILY MEMBERS SIGN =================================


  // convertBase64ToFile = (base64) => {
  //   const binaryString = atob(base64);
  //   const arrayBuffer = new ArrayBuffer(binaryString.length);
  //   const uintArray = new Uint8Array(arrayBuffer);

  //   for (let i = 0; i < binaryString.length; i++) {
  //     uintArray[i] = binaryString.charCodeAt(i);
  //   }

  //   const blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });
  //   const fileName = 'working_grouop_sign.png'; // Specify the desired filename and extension

  //   return new File([blob], fileName, { type: 'application/octet-stream' });
  // };


  dataUrlToFile(base64){
    const arr = base64.split(',');
    if (arr.length < 2) { return undefined; }

    const mimeArr = arr[0].match(/:(.*?);/);
    if (!mimeArr || mimeArr.length < 2) { return undefined; }
    const mime = mimeArr[1];
    const buff = Buffer.from(arr[1], 'base64');
    const fileName = 'working_grouop_sign.png'; 

    return new File([buff], fileName, {type:mime});
  }




  scrollToFirstError = (errors) => {
    const all_errors = Object.entries(errors).filter(
      (error) => error[1] === true
    )

    const sorted_errors = all_errors.sort(function (a, b) {
      return sorting.indexOf(a[0]) - sorting.indexOf(b[0])
    })

    if (sorted_errors.length) {
      const input = document.getElementById(sorted_errors[0][0])
      input.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      })
    }
  }

  scrollToModalFirstError = (errors) => {
    const all_errors = Object.entries(errors).filter(
      (error) => error[1] === true
    )

    const sorted_errors = all_errors.sort(function (a, b) {
      return sorting.indexOf(a[0]) - sorting.indexOf(b[0])
    })

    if (sorted_errors.length) {
      const input = document.getElementById(sorted_errors[0][0])
      input.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      })
    }
  }

  openModal = () => {
    const self = this
    self.setState({ showAlertFamilyMember: true })
    const { setFamilyMemberValue } = this.props
  }

  closeModal = () => {
    const self = this
    self.setState({
      showAlertFamilyMember: false,
      family_member_errors: {},
      family_members_form_data: {},
    })
  }


  // closeSaveActObservationAlert = () => {
  //   const self = this
  //   self.setState({
  //     show_save_act_observation_data_alert: false,
  //   })
  // }





  uploadMultipleFiles = (e) => {
    const self = this;
    const {uploadMedia, uploadNewUpdate} = self.props;
    console.log(Array.from(e.target.files).length, self.state.max_file_upload_limit)


    if (Array.from(e.target.files).length > self.state.max_file_upload_limit) {
      e.preventDefault();
      // toast.error(`KUJDES! Nuk mund të ngarkoni më shumë se ${self.state.max_file_upload_limit} dokumenta`, {position: "top-center"});
      Swal.fire({
        position: "top-center",
        icon: "error",
        title: "KUJDES...",
        text: `Nuk mund të ngarkoni më shumë se ${self.state.max_file_upload_limit} dokumenta!`,
      });
      return;
    }


    self.setState((prevState) => {
      const currentDocuments = prevState.act_observation_data?.uploaded_documents ?? []

      console.log("UPLOADED_DOCUMENTS", [...currentDocuments,  ...Array.from(e.target.files)])

      // setActObservationValue('uploaded_documents', [...currentDocuments, ...Array.from(e.target.files)])

      // uploadMedia([...currentDocuments, ...Array.from(e.target.files)])

      // toast.info("Dokumentat po ngarkohen...ju lutem prisni");
      // const data = this.props.act_observation.uploaded_documents;

      // console.log("Data......" ,data);
      // data.user_id = this.props.act_observation.act_observation_data.userID;
      // data.file_reference = 'ak_dokumenta';
      // uploadNewUpdate(data);


      let newData = Array.from(e.target.files);
      newData.user_id = this.props.act_observation.act_observation_data.userID;
      newData.file_reference = 'ak_dokumenta';

      uploadNewUpdate(newData)
     
    })

  }

  

  clearCurrentLocation = () => {
    const self = this;
    self.setState({
      latitude: null,
      longitude: null,
      location: false
    })
  }
  

  getUserLocation = () => { 
    const self = this;
    const {setActObservationValue} = self.props;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
            // what to do once we have the position
            const { latitude, longitude } = position.coords;
            self.setState({
              latitude: latitude,
              longitude: longitude,
              location: true
            })

            setActObservationValue("currentLocation", {'location': true, 'latitude': latitude, 'longitude': longitude})
        },
        (error) => {
            // display an error if we cant get the users position
            console.error('Error getting user location:', error);
          }
      );
    }
    else {
        // display an error if not supported
        // toast.error('Geolocation is not supported by this browser.', {position: "top-center"});
        Swal.fire({
          position: "top-center",
          icon: "error",
          title: "KUJDES...",
          text: `Geolocation is not supported by this browser!`,
        });
    }
  
  };


  toggleHandler = (item) => () => {
    const self = this;

    self.setState((prevState) => ({
      ...prevState,
      members_who_sign: {
        ...prevState.members_who_sign,
        [item.id]:prevState[item.id] ? null : {id: item.id, firstName: item.fm_first_name, lastName: item.fm_last_name}
      },
    }))
  
  };

  


  // +++++++++++++++++++++
  // SHTO ANETAR FAMILJE
  // +++++++++++++++++++++
  addFamilyMember() {
    const { family_member_errors, family_members_form_data } = this.state
    const { setActObservationValue } = this.props
    const family_members_data = this.props.act_observation.family_members_data

    console.log('FAMILY_MEMBERS_DATA', family_members_form_data)


    if (!family_members_form_data?.fm_first_name?.trim()) {
      this.setState((prevState) => ({
        ...prevState,
        family_member_errors: {
          ...prevState.family_member_errors,
          fm_first_name: true,
        },
      }))
    }

    if (!family_members_form_data?.fm_last_name?.trim()) {
      this.setState((prevState) => ({
        ...prevState,
        family_member_errors: {
          ...prevState.family_member_errors,
          fm_last_name: true,
        },
      }))
    }

    if (!family_members_form_data?.fm_education?.trim()) {
      this.setState((prevState) => ({
        ...prevState,
        family_member_errors: {
          ...prevState.family_member_errors,
          fm_education: true,
        },
      }))
    }

    if (!family_members_form_data?.fm_job_position?.trim()) {
      this.setState((prevState) => ({
        ...prevState,
        family_member_errors: {
          ...prevState.family_member_errors,
          fm_job_position: true,
        },
      }))
    }

    
    // if (!family_members_form_data?.fm_salary_from_employment?.trim()) {
    //   this.setState((prevState) => ({
    //     ...prevState,
    //     family_member_errors: {
    //       ...prevState.family_member_errors,
    //       fm_salary_from_employment: true,
    //     },
    //   }))
    // }

    // console.log("TIRANE", family_members_form_data.fm_job_position)

    // if(family_members_form_data.fm_job_position === 'punesuar' || family_members_form_data.fm_job_position === 'vetpunesuar'){

    //   // if (!family_members_form_data?.fm_salary_from_employment?.trim()) {
    //     // this.setState((prevState) => ({
    //     //   ...prevState,
    //     //   family_member_errors: {
    //     //     ...prevState.family_member_errors,
    //     //     fm_salary_from_employment: false,
    //     //   },
    //     // }))

    //   // }

    //   if (!family_members_form_data?.fm_salary_from_employment?.trim()) {
    //     this.setState((prevState) => ({
    //       ...prevState,
    //       family_member_errors: {
    //         ...prevState.family_member_errors,
    //         fm_salary_from_employment: true,
    //       },
    //     }))
    //   }
    // }else{
    //   console.log("OK")
    // }

    console.log(family_member_errors, 'family_member_errors')

    

    if ( 
        !family_members_form_data.fm_first_name || 
        !family_members_form_data.fm_last_name || 
        !family_members_form_data.fm_education || 
        !family_members_form_data.fm_job_position
        ) {
          console.log('invalid data')
          return false
    } else {

      this.setState((prevState) => {
        const currentFamilyMembers = prevState.act_observation_data?.fmembers ?? []
        console.log("COOLAB", [...currentFamilyMembers, family_members_data])

        if ([...currentFamilyMembers, family_members_data].length > 0) {
          
          setActObservationValue('fmembers', [...currentFamilyMembers, family_members_data])

          console.log('FINAL_DATA', this.props.act_observation)

          return {
            ...prevState,
            act_observation_data: {
              ...prevState.act_observation_data,
              fmembers: [...currentFamilyMembers, family_members_data],
            },
            showAlertFamilyMember: false,
            family_members_form_data: {},
            family_member_errors: {},
          }
        }
        return {
          ...prevState,
        }
      })      
    }
  }




  editFamilyMember = (item) => {
    console.log("Edit", item)
  }


  // deleteFamilyMember = (name, surname) => {
  //   console.log("Delete", name, surname)
  // }


  deleteFamilyMember(firstName, lastName){
    console.log(firstName, lastName);


    const { setActObservationValue } = this.props

    let newList = this.props.act_observation.act_observation_data.fmembers.filter(li => li.fm_first_name !== firstName && li.fm_last_name !== lastName)
    
    
    this.setState((prevState) => {
      // const currentFamilyMembers = prevState.act_observation_data?.fmembers ?? []
      // console.log("NEW_LIST", [...currentFamilyMembers, newList])


      
      if ([newList].length > 0) {
        setActObservationValue('fmembers',newList)
        return {
            ...prevState,
            act_observation_data: {
                ...prevState.act_observation_data,
                fmembers: newList,
            },
            showAlertFamilyMember: false
        }
      }
    },()=>{
        // console.log("deleteShareholder",index)
        // console.log("delete current shareholder1",currentShareholders);
        // console.log("delete current this.state.registration_data.shareholders",this.state.registration_data.shareholders);
    });
}



  render() {
    const {
      act_observation,
      setActObservationValue,
      setFamilyMemberValue,
      setErrorCodeFalse,
      uploadMedia,
      uploadNewUpdate,
      // setNewUpdateValue,
      years,
    } = this.props


    const {
      errors,
      education_list,
      employeed_list,
      kohzgjatja_e_perfitimit_ne_skemen_e_ndihmes_ekonomike_values,
      defaultEducation,
      agricultural_land_list,
      selected_employeed_value,
      selected_agriculture_land_value,
      selected_kohzgjatja_e_perfitimit_value,
      disability_pension_values,
      old_age_pension_values,
      possible_values,
      social_economic_possible_values,
      motor_vehicles_owned_by_family_values,
      major_members_found_in_the_apartment,
      type_of_housing_values,
      apartment_condition_values,
      need_family_other_services_values,
      location,
      latitude,
      longitude,
      members_who_sign,
      surname_equal_to_head_family_memeber,

      max_file_upload_limit,
      uploadMultipleFiles,
      handleClearWorkingGroupSign,
      handleSaveWorkingGroupSign,
      handle_save_family_members_sign,
      handle_clear_family_members_sign,


      livestock_list,
      selected_livestock_value,
      selected_disability_pension_value,

      year,
      showAlertFamilyMember,
      show_save_act_observation_data_alert,
      family_members_form_data,
      family_member_errors,
      default_district_value,
      default_municipality_value,
      default_administrative_unit_value,
      default_village_value,
      default_origin_value,
      default_country_value,
      default_legal_status_value,
      default_object_value,
      default_education_value,
      default_role_value,
    } = this.state
    const error_map = this.props.act_observation.error_map
    const code_error = this.props.code_error
    this.years = Array.from(new Array(60), (val, index) => year - index)
    const upload_loading = this.props.act_observation.upload_loading

    const errorOnForm = Object.values(errors).every((value) => {
      if (value === true) {
        return true
      }
      return false
    })


    return (
      <React.Fragment>
        <section className="bg-auth-home">
          <div className="container" id="top">
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12 m-auto">
                <div className="card shadow rounded border-0">
                  <div className="card-body">
                    <h4 className="card-title text-center text-uppercase" style={{ color: '#124057', fontWeight: '600' }}>{' '}Akt Konstatim{' '}</h4>
                    <p className="border-bottom pb-2" />
                    <form className="login-form mt-4">
                      <div className="row">
                        <section>
                          <div className="row mt-2" style={{ padding: '20px' }}>

                            {/* EMRI */}
                            <div className="col-md-6">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.name
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Emër <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-user"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="text"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        act_observation_data: {
                                          ...prevState.act_observation_data,
                                          name: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          name: false,
                                        },
                                      }))
                                      setActObservationValue('name', value)
                                    }}
                                    value={
                                      this.props.act_observation
                                        .act_observation_data.name
                                    }
                                    className={
                                      errors.name
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Emër"
                                    id="name"
                                    name="Emër"
                                    required=""
                                  />
                                </div>
                              </div>
                            </div>

                            {/* MBIEMRI */}
                            <div className="col-md-6">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.surname
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Miemër <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-user"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="text"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        act_observation_data: {
                                          ...prevState.act_observation_data,
                                          surname: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          surname: false,
                                        },
                                      }))
                                      setActObservationValue('surname', value)
                                    }}
                                    value={
                                      this.props.act_observation
                                        .act_observation_data.surname
                                    }
                                    className={
                                      errors.surname
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Mbiemër"
                                    id="surname"
                                    name="Mbiemër"
                                    required={true}
                                  />
                                </div>
                              </div>
                            </div>

                            {/* ATËSIA */}
                            <div className="col-md-6">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.fatherName
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Atësia <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-user"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="text"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        act_observation_data: {
                                          ...prevState.act_observation_data,
                                          fatherName: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          fatherName: false,
                                        },
                                      }))
                                      setActObservationValue(
                                        'fatherName',
                                        value
                                      )
                                    }}
                                    value={
                                      this.props.act_observation
                                        .act_observation_data.fatherName
                                    }
                                    className={
                                      errors.fatherName
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="Atësia"
                                    id="fatherName"
                                    name="Atësia"
                                    required={true}
                                  />
                                </div>
                              </div>
                            </div>

                            {/* ID CARD */}
                            <div className="col-md-6">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.cardID
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  ID
                                  {/* <span className="text-danger">*</span> */}
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-building-house"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <input
                                    type="text"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        act_observation_data: {
                                          ...prevState.act_observation_data,
                                          cardID: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          cardID: false,
                                        },
                                      }))
                                      setActObservationValue('cardID', value)
                                    }}
                                    value={
                                      this.props.act_observation
                                        .act_observation_data.cardID
                                    }
                                    className={
                                      errors.cardID
                                        ? 'form-control ps-5 input_error'
                                        : 'form-control ps-5'
                                    }
                                    placeholder="ID"
                                    id="cardID"
                                    name="ID"
                                  />
                                </div>
                              </div>
                            </div>

                            {/* QARKU */}
                            <div className="col-md-6">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.district
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Qarku
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-building"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <select
                                    className={
                                      errors.district
                                        ? 'form-select form-control ps-5 input_error'
                                        : 'form-select form-control ps-5'
                                    }
                                    placeholder="Zhgjidh Qarkun"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        act_observation_data: {
                                          ...prevState.act_observation_data,
                                          district: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          district: false,
                                        },
                                      }))
                                      setActObservationValue('district', value)
                                    }}
                                    id="district"
                                    value={
                                      this.props.act_observation
                                        .act_observation_data.district !== ''
                                        ? this.props.act_observation
                                            .act_observation_data.district
                                        : undefined
                                    }
                                    aria-label="Default select example"
                                    defaultValue={'DEFAULT_DISTRICT'}
                                  >
                                    <option value="DEFAULT_DISTRICT" disabled>
                                      {default_district_value}
                                    </option>

                                    {act_observation.district_list.list.data
                                      .filter((item) => item.id == 1)
                                      .map((district, index) => {
                                        return (
                                          <option
                                            key={`district${index}.id`}
                                            value={district.id}
                                          >
                                            {district.name}
                                          </option>
                                        )
                                      })}
                                  </select>
                                </div>
                              </div>
                            </div>

                            {/* BASHKIA */}
                            <div className="col-md-6">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.municipality
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Bashkia
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-building"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <select
                                    className={
                                      errors.municipality
                                        ? 'form-select form-control ps-5 input_error'
                                        : 'form-select form-control ps-5'
                                    }
                                    placeholder="Zhgjidh Bashkinë"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        act_observation_data: {
                                          ...prevState.act_observation_data,
                                          municipality: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          municipality: false,
                                        },
                                      }))
                                      setActObservationValue(
                                        'municipality',
                                        value
                                      )
                                    }}
                                    id="municipality"
                                    value={
                                      this.props.act_observation
                                        .act_observation_data.municipality !==
                                      ''
                                        ? this.props.act_observation
                                            .act_observation_data.municipality
                                        : undefined
                                    }
                                    // aria-label="Default select example"
                                    defaultValue={'DEFAULT_MUNICIPALITY'}
                                  >
                                    <option
                                      value="DEFAULT_MUNICIPALITY"
                                      disabled
                                    >
                                      {default_municipality_value}
                                    </option>

                                    {act_observation.municipality_list.list.data
                                      .filter(
                                        (item) =>
                                          item.fk_district_id ==
                                          act_observation.act_observation_data
                                            .district
                                      )
                                      .map((municipality, index) => {
                                        return (
                                          <option
                                            key={`municipality${index}.id`}
                                            value={municipality.id}
                                          >
                                            {municipality.name}
                                          </option>
                                        )
                                      })}
                                  </select>
                                </div>
                              </div>
                            </div>

                            {/* NJËSIA ADMINISTRATIVE */}
                            <div className="col-md-6">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.administrative_unit
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Njësia Administrative
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-building"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <select
                                    className={
                                      errors.administrative_unit
                                        ? 'form-select form-control ps-5 input_error'
                                        : 'form-select form-control ps-5'
                                    }
                                    placeholder="Zhgjidh njësine administrative"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        act_observation_data: {
                                          ...prevState.act_observation_data,
                                          administrative_unit: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          administrative_unit: false,
                                        },
                                      }))
                                      setActObservationValue(
                                        'administrative_unit',
                                        value
                                      )
                                    }}
                                    id="administrative_unit"
                                    value={
                                      this.props.act_observation
                                        .act_observation_data
                                        .administrative_unit !== ''
                                        ? this.props.act_observation
                                            .act_observation_data
                                            .administrative_unit
                                        : undefined
                                    }
                                    aria-label="Default select example"
                                    defaultValue={'DEFAULT_ADMINISTRATIVE_UNIT'}
                                  >
                                    <option
                                      value="DEFAULT_ADMINISTRATIVE_UNIT"
                                      disabled
                                    >
                                      {default_administrative_unit_value}
                                    </option>

                                    {act_observation.administrative_unit_list.list.data
                                      .filter(
                                        (item) =>
                                          item.fk_municipality_id ==
                                          act_observation.act_observation_data
                                            .municipality
                                      )
                                      .map((administrative_unit, index) => {
                                        return (
                                          <option
                                            key={`administrative_unit${index}.id`}
                                            value={administrative_unit.id}
                                          >
                                            {administrative_unit.name}
                                          </option>
                                        )
                                      })}
                                  </select>
                                </div>
                              </div>
                            </div>

                            {/* FSHATI */}
                            <div className="col-md-6">
                              <div className="mb-4">
                                <label
                                  className={
                                    errors.village
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Fshati
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="form-icon position-relative">
                                  <Icon
                                    icon="bx:bx-building"
                                    width="17"
                                    height="17"
                                    className="custom_icon"
                                  />
                                  <select
                                    className={
                                      errors.village
                                        ? 'form-select form-control ps-5 input_error'
                                        : 'form-select form-control ps-5'
                                    }
                                    placeholder="Zhgjidh Fshatin"
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        act_observation_data: {
                                          ...prevState.act_observation_data,
                                          village: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          village: false,
                                        },
                                      }))
                                      setActObservationValue('village', value)
                                    }}
                                    id="village"
                                    value={
                                      this.props.act_observation
                                        .act_observation_data.village !== ''
                                        ? this.props.act_observation
                                            .act_observation_data.village
                                        : undefined
                                    }
                                    aria-label="Default select example"
                                    defaultValue={'DEFAULT_VILLAGE'}
                                  >
                                    <option value="DEFAULT_VILLAGE" disabled>
                                      {default_village_value}
                                    </option>

                                    {act_observation.village_list.list.data
                                      .filter(
                                        (item) =>
                                          item.fk_administrative_unit_id ==
                                          act_observation.act_observation_data
                                            .administrative_unit
                                      )
                                      .map((village, index) => {
                                        return (
                                          <option
                                            key={`village${index}.id`}
                                            value={village.id}
                                          >
                                            {village.name}
                                          </option>
                                        )
                                      })}
                                  </select>
                                </div>
                              </div>
                            </div>

                            {/* FAMILJA NE BANESE */}
                            <div className="section_three">
                              <div className="form-label mb-4 text-start">
                                <label
                                  className={
                                    errors.family_present_in_the_apartment
                                      ? 'input_error'
                                      : ''
                                  }
                                >
                                  A u gjend familja ne banese?{' '}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>

                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'start',
                                }}
                              >
                                {possible_values.map(([text, value], i) => (
                                  <div
                                    key={i}
                                    style={
                                      i === 1 ? { paddingLeft: '110px' } : {}
                                    }
                                  >
                                    <RadioButton
                                      name="arsimi"
                                      label={text}
                                      checked={
                                        act_observation.act_observation_data
                                          .family_present_in_the_apartment ===
                                        value
                                      }
                                      onChange={() => {
                                        this.setState((prevState) => ({
                                          ...prevState,
                                          act_observation_data: {
                                            ...prevState.act_observation_data,
                                            family_present_in_the_apartment:
                                              value,
                                          },
                                          errors: {
                                            ...prevState.errors,
                                            family_present_in_the_apartment: false,
                                          },
                                        }))
                                        setActObservationValue(
                                          'family_present_in_the_apartment',
                                          value
                                        )
                                      }}
                                      required
                                      value={value}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>

                            {act_observation.act_observation_data.family_present_in_the_apartment === 'po' && (
                              <>
                                {/* PERBERJA E FAMILJLES */}
                                <div className="row" style={{ paddingTop: '60px' }}>
                                  <div className="col-md-8">
                                    <div>
                                      <label className={'form-label'}>PËRBËRJA E FAMILJES{' '}</label>
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="mb-4">
                                      <div className="d-grid">
                                        <a className="btn btn-primary border-button" onClick={() => {this.openModal()}}>{' '}Shto</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="col-md-12 mb-10"
                                  style={{
                                    marginTop: '3rem',
                                    marginBottom: '3rem',
                                  }}
                                >
                                  {/*<h5 className="card-title text-center text-uppercase" style={{ color: '#124057' }}>{' '}{family_members_form_data.shareholder_type}{' '}</h5>*/}

                                  <div className="table-responsive bg-white shadow rounded">
                                    <table className="table mb-0 table-center invoice-tb">
                                      <thead className="bg-light">
                                        <tr>
                                          <th
                                            scope="col"
                                            className="border-bottom text-start"
                                          >
                                            Id
                                          </th>
                                          <th
                                            scope="col"
                                            className="border-bottom text-start"
                                          >
                                            Emër
                                          </th>
                                          <th
                                            scope="col"
                                            className="border-bottom text-start"
                                          >
                                            Mbiemër
                                          </th>
                                          <th
                                            scope="col"
                                            className="border-bottom text-start"
                                          >
                                            Arsimi
                                          </th>
                                          <th
                                            scope="col"
                                            className="border-bottom text-start"
                                          >
                                            Punësimi
                                          </th>
                                          <th
                                            scope="col"
                                            className="border-bottom text-start"
                                          >
                                            Të ardhurat
                                          </th>
                                          <th
                                            scope="col"
                                            className="border-bottom text-start"
                                          >
                                            Fshi
                                          </th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        {act_observation.act_observation_data?.fmembers?.map(
                                          (item, key) => {
                                            return (
                                              <tr key={key}>
                                                <td scope="row" className="text-start">{key + 1}</td>
                                                <td scope="row" className="text-start">{capitalize(item.fm_first_name)}</td>
                                                <td className="text-start">{capitalize(item.fm_last_name)}</td>
                                                <td className="text-start">{capitalize(item.fm_education)}</td>
                                                <td className="text-start">{item.fm_job_position.label.toString()}</td>
                                                <td className="text-start">{item.fm_salary_from_employment}</td>
                                                <td className="text-center"style={{display: 'flex', justifyContent:'flex-start'}}>
                                                  {/* <i onClick={() => this.editFamilyMember(item)} className="uil uil-edit user_profile_text_green cursor_pointer"/> */}
                                                  <i
                                                    onClick={() =>
                                                      this.deleteFamilyMember(
                                                        item.fm_first_name,
                                                        item.fm_last_name
                                                      )
                                                    }
                                                    className="uil uil-trash cursor_pointer"
                                                  />
                                                </td>
                                              </tr>
                                            )
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>

                                {/* Section Threee */}
                                <div className="form-label mb-2 text-start">
                                  <label>
                                    A ka tokë bujqësore/blektori?{' '}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>

                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                  }}
                                >
                                  {agricultural_land_list.map(
                                    ([text, value], i) => (
                                      <div
                                        key={i}
                                        style={
                                          i === 1
                                            ? { paddingLeft: '110px' }
                                            : {}
                                        }
                                      >
                                        {/* <RadioButton
                                          name="toke_bujqesore"
                                          label={text}
                                          onChange={
                                            this.onAgricultureLandChange
                                          }
                                          value={value}
                                        /> */}
                                        <RadioButton
                                          name="toke_bujqesore"
                                          label={text}
                                          checked={
                                            act_observation.act_observation_data
                                              .agricultural_land_blectori ===
                                            value
                                          }
                                          onChange={() => {
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              act_observation_data: {
                                                ...prevState.act_observation_data,
                                                agricultural_land_blectori:
                                                  value,
                                              },
                                              errors: {
                                                ...prevState.errors,
                                                agricultural_land_blectori: false,
                                              },
                                            }))
                                            setActObservationValue(
                                              'agricultural_land_blectori',
                                              value
                                            )
                                          }}
                                          required
                                          value={value}
                                        />
                                      </div>
                                    )
                                  )}
                                </div>

                                <br />

                                {act_observation.act_observation_data.agricultural_land_blectori === 'po' && (
                                  <>
                                    {/* Sip e tokes ne m2 */}
                                    {/* <div>
                                        <div className="form-label mb-2 text-start">
                                          <label>
                                            5.1. Sipërfaqja e tokës bujqësore në meter katror?{' '}
                                            <span className="text-danger">*</span>
                                          </label>
                                        </div>

                                        <div>
                                            <input
                                            type={'text'}
                                            className={
                                              family_member_errors.agriculture_land_surface
                                                ? 'form-control input_error'
                                                : 'form-control'
                                            }
                                            value={
                                              family_members_form_data.agriculture_land_surface !==
                                              ''
                                                ? family_members_form_data.agriculture_land_surface
                                                : undefined
                                            }
                                            // onKeyDown={
                                            //   renderProps.onEnterKeyDownConfirm
                                            // }
                                            onChange={(event) => {
                                              const value = event.target.value
                                              this.setState((prevState) => ({
                                                ...prevState,
                                                family_members_form_data: {
                                                  ...prevState.family_members_form_data,
                                                  agriculture_land_surface: value,
                                                },
                                                family_member_errors: {
                                                  ...prevState.family_member_errors,
                                                  agriculture_land_surface: false,
                                                },
                                              }))
                                              setShareholderValue(
                                                'agriculture_land_surface',
                                                value
                                              )
                                            }}
                                            placeholder={'Vendos m2 të tokës'}
                                          />
                                        </div>
                                    </div> */}

                                    <br />

                                    {/* të ardhurat nga toka */}
                                    <div>
                                      <div className="form-label mb-2 text-start">
                                        <label>Sa janë të ardhurat nga toka/blektoria?{' '}<span className="text-danger">*</span></label>
                                      </div>
                                      <div>
                                        <input
                                          type={'number'}
                                          className={
                                            family_member_errors.agricultural_land_blectori_income
                                              ? 'form-control input_error'
                                              : 'form-control'
                                          }
                                          value={
                                            family_members_form_data.agricultural_land_blectori_income !==
                                            ''
                                              ? family_members_form_data.agricultural_land_blectori_income
                                              : undefined
                                          }
                                          onChange={(event) => {
                                            const value = event.target.value
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              family_members_form_data: {
                                                ...prevState.family_members_form_data,
                                                agricultural_land_blectori_income:
                                                  value,
                                              },
                                              family_member_errors: {
                                                ...prevState.family_member_errors,
                                                agricultural_land_blectori_income: false,
                                              },
                                            }))
                                            setActObservationValue(
                                              'agricultural_land_blectori_income',
                                              value
                                            )
                                          }}
                                          placeholder={'Vendos shumën'}
                                        />
                                      </div>
                                    </div>
                                  </>
                                )}

                                <br />

                                {/* <div className="form-label mb-2 text-start">
                                    <label>
                                      6. A ka blektori?{' '}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>

                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'start',
                                    }}
                                  >
                                    {livestock_list.map(([text, value], i) => (
                                      <div
                                        key={i}
                                        style={
                                          i === 1 ? { paddingLeft: '110px' } : {}
                                        }
                                      >
                                        <RadioButton
                                          name="blektori"
                                          label={text}
                                          onChange={this.onLivestockChange}
                                          value={value}
                                        />
                                      </div>
                                    ))}
                                  </div>

                                  <br />

                                  {selected_livestock_value === 'po' && (
                                    <>
                                      <div>
                                        <div className="form-label mb-2 text-start">
                                          <label>
                                            <span className="text-danger">*</span>
                                          </label>
                                          6.1. Sa janë të ardhurat nga blektoria?{' '}
                                        </div>
                                        <textarea
                                          type={'text'}
                                          className={
                                            family_member_errors.income_from_land
                                              ? 'form-control input_error'
                                              : 'form-control'
                                          }
                                          value={
                                            family_members_form_data.income_from_land !==
                                            ''
                                              ? family_members_form_data.income_from_land
                                              : undefined
                                          }
                                          onKeyDown={
                                            renderProps.onEnterKeyDownConfirm
                                          }
                                          onChange={(event) => {
                                            const value = event.target.value
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              family_members_form_data: {
                                                ...prevState.family_members_form_data,
                                                income_from_land: value,
                                              },
                                              family_member_errors: {
                                                ...prevState.family_member_errors,
                                                income_from_land: false,
                                              },
                                            }))
                                            setShareholderValue(
                                              'income_from_land',
                                              value
                                            )
                                          }}
                                          placeholder={'Vendos shumen'}
                                        />
                                      </div>
                                    </>
                                  )} */}

                                {/* Section Four */}
                                <div className="section_three">
                                  <div className="form-label mb-2 text-start">
                                    <label className={errors.economic_assistance ? 'input_error' : ''}>
                                      Kohëzgjatja e përfitimit në skemën e ndihmës ekonomike?{' '}<span className="text-danger">*</span>
                                    </label>
                                  </div>

                                  <div
                                  // style={{
                                  //   display: 'flex',
                                  //   justifyContent: 'space-between',
                                  // }}
                                  >
                                    {kohzgjatja_e_perfitimit_ne_skemen_e_ndihmes_ekonomike_values.map(
                                      ([text, value], i) => (
                                        <div key={i}>
                                          <RadioButton
                                            name="assistence_ekonomike"
                                            label={text}
                                            checked={
                                              act_observation
                                                .act_observation_data
                                                .economic_assistance === value
                                            }
                                            onChange={() => {
                                              this.setState((prevState) => ({
                                                ...prevState,
                                                act_observation_data: {
                                                  ...prevState.act_observation_data,
                                                  economic_assistance: value,
                                                },
                                                errors: {
                                                  ...prevState.errors,
                                                  economic_assistance: false,
                                                },
                                              }))
                                              setActObservationValue(
                                                'economic_assistance',
                                                value
                                              )
                                            }}
                                            required
                                            value={value}
                                          />
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>

                                {/* Section Four */}
                                <div className="section_three">
                                  <div className="form-label mb-2 text-start">
                                    <label className={errors.disability_pension ? 'input_error' : ''}>
                                      A përfiton pagesë të aftësisë së kufizuar/pension invaliditeti ndonjë nga antarët e familjes?{' '}<span className="text-danger">*</span>
                                    </label>
                                  </div>

                                  <div style={{display: 'flex', justifyContent: 'start'}}>
                                    {possible_values.map(([text, value], i) => (
                                      <div
                                        key={i}
                                        style={
                                          i === 1
                                            ? { paddingLeft: '110px' }
                                            : {}
                                        }
                                      >
                                        <RadioButton
                                          name="disability_pension"
                                          label={text}
                                          checked={
                                            act_observation.act_observation_data
                                              .disability_pension === value
                                          }
                                          onChange={() => {
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              act_observation_data: {
                                                ...prevState.act_observation_data,
                                                disability_pension: value,
                                              },
                                              errors: {
                                                ...prevState.errors,
                                                disability_pension: false,
                                              },
                                            }))
                                            setActObservationValue(
                                              'disability_pension',
                                              value
                                            )
                                          }}
                                          required
                                          value={value}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                </div>

                                {/* Section Five */}
                                <div className="section_three">
                                  <div className="form-label mb-2 text-start">
                                    <label
                                      className={
                                        errors.old_age_pension
                                          ? 'input_error'
                                          : ''
                                      }
                                    >
                                      A përfiton pension pleqërie ndonjë nga
                                      anëtarët e famlijes?{' '}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>

                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'start',
                                    }}
                                  >
                                    {possible_values.map(([text, value], i) => (
                                      <div
                                        key={i}
                                        style={
                                          i === 1
                                            ? { paddingLeft: '110px' }
                                            : {}
                                        }
                                      >
                                        <RadioButton
                                          name="pension_pleqerie"
                                          label={text}
                                          checked={
                                            act_observation.act_observation_data
                                              .old_age_pension === value
                                          }
                                          onChange={() => {
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              act_observation_data: {
                                                ...prevState.act_observation_data,
                                                old_age_pension: value,
                                              },
                                              errors: {
                                                ...prevState.errors,
                                                old_age_pension: false,
                                              },
                                            }))
                                            setActObservationValue(
                                              'old_age_pension',
                                              value
                                            )
                                          }}
                                          required
                                          value={value}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                </div>

                                {/* Section Six */}
                                <div className="section_three">
                                  <div className="form-label mb-2 text-start">
                                    <label
                                      className={
                                        errors.employeed_in_akpda
                                          ? 'input_error'
                                          : ''
                                      }
                                    >
                                      A ka kërkuar punë ndonjëri pre anëtarëve
                                      të familjes pran Zyrave të punës(AKPA)?{' '}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>

                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'start',
                                    }}
                                  >
                                    {possible_values.map(([text, value], i) => (
                                      <div
                                        key={i}
                                        style={
                                          i === 1
                                            ? { paddingLeft: '110px' }
                                            : {}
                                        }
                                      >
                                        <RadioButton
                                          name="employeed_in_akpda"
                                          label={text}
                                          checked={
                                            act_observation.act_observation_data
                                              .employeed_in_akpda === value
                                          }
                                          onChange={() => {
                                            this.setState((prevState) => ({
                                              ...prevState,
                                              act_observation_data: {
                                                ...prevState.act_observation_data,
                                                employeed_in_akpda: value,
                                              },
                                              errors: {
                                                ...prevState.errors,
                                                employeed_in_akpda: false,
                                              },
                                            }))
                                            setActObservationValue(
                                              'employeed_in_akpda',
                                              value
                                            )
                                          }}
                                          required
                                          value={value}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                </div>

                                {/* Section Seven */}
                                <div className="section_three">
                                  <div className="form-label mb-2 text-start">
                                    <label className={errors.employee_type ? 'form-label label_error' : 'form-label'}>
                                      Nëse keni punuar më përpara/jeni në punë, çfarë pune keni kryer/kryeni?{' '}<span className="text-danger">*</span>
                                    </label>

                                    <div className="mt-2">
                                      <textarea
                                        type={'text'}
                                        className={
                                          errors.employee_type
                                            ? 'form-control input_error'
                                            : 'form-control'
                                        }
                                        value={
                                          act_observation.act_observation_data
                                            .employee_type !== ''
                                            ? act_observation
                                                .act_observation_data
                                                .employee_type
                                            : undefined
                                        }
                                        // onKeyDown={
                                        //   renderProps.onEnterKeyDownConfirm
                                        // }
                                        onChange={(event) => {
                                          const value = event.target.value
                                          this.setState((prevState) => ({
                                            ...prevState,
                                            act_observation_data: {
                                              ...prevState.act_observation_data,
                                              employee_type: value,
                                            },
                                            errors: {
                                              ...prevState.errors,
                                              employee_type: false,
                                            },
                                          }))
                                          setActObservationValue(
                                            'employee_type',
                                            value
                                          )
                                        }}
                                        placeholder={'Përshkruaje'}
                                      />
                                    </div>
                                  </div>
                                </div>

                                {/* Section Eight */}
                                <div className="section_three">
                                  <div className="form-label mb-2 text-start">
                                    <label
                                      className={
                                        errors.motor_vehicle_owned
                                          ? 'input_error'
                                          : ''
                                      }
                                    >
                                      Mjeti motorrik që posedojnë anëtarët e familjes?{' '}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>

                                  <div>
                                    {motor_vehicles_owned_by_family_values.map(
                                      ([text, value], i) => (
                                        <div key={i}>
                                          <RadioButton
                                            name="motor_vehicle_owned"
                                            label={text}
                                            checked={
                                              act_observation
                                                .act_observation_data
                                                .motor_vehicle_owned === value
                                            }
                                            onChange={() => {
                                              this.setState((prevState) => ({
                                                ...prevState,
                                                act_observation_data: {
                                                  ...prevState.act_observation_data,
                                                  motor_vehicle_owned: value,
                                                },
                                                errors: {
                                                  ...prevState.errors,
                                                  motor_vehicle_owned: false,
                                                },
                                              }))
                                              setActObservationValue(
                                                'motor_vehicle_owned',
                                                value
                                              )
                                            }}
                                            required
                                            value={value}
                                          />
                                        </div>
                                      )
                                    )}

                                    {act_observation.act_observation_data.motor_vehicle_owned === 'Tjetër' && (
                                      <input
                                        type="text"
                                        onChange={(event) => {
                                          const value = event.target.value
                                          this.setState((prevState) => ({
                                            ...prevState,
                                            act_observation_data: {
                                              ...prevState.act_observation_data,
                                              motor_vehicle_owned_other: value,
                                            },
                                            errors: {
                                              ...prevState.errors,
                                              motor_vehicle_owned_other: false,
                                            },
                                          }))
                                          setActObservationValue(
                                            'motor_vehicle_owned_other',
                                            value
                                          )
                                        }}
                                        value={
                                          act_observation.act_observation_data
                                            .motor_vehicle_owned_other
                                        }
                                        className={
                                          errors.motor_vehicle_owned_other
                                            ? 'form-control ps-5 input_error mt-2'
                                            : 'form-control ps-5 mt-2'
                                        }
                                        placeholder="Përshkruaj"
                                        id="motor_vehicle_owned_other"
                                        name="motor_vehicle_owned_other"
                                        required={true}
                                        style={{ width: '50%' }}
                                      />
                                    )}
                                  </div>
                                </div>

                                {/* Section Nine */}
                                <div className="section_three">
                                  <div className="form-label mb-2 text-start">
                                    <label className={errors.type_of_housing || errors.other_type_of_housing ? 'input_error' : ''}>Tipi i banesës?{' '}<span className="text-danger">*</span></label>
                                  </div>

                                  <div>
                                    {type_of_housing_values.map(
                                      ([text, value], i) => (
                                        <div key={i}>
                                          <RadioButton
                                            name="type_of_housing"
                                            label={text}
                                            checked={
                                              act_observation
                                                .act_observation_data
                                                .type_of_housing === value
                                            }
                                            onChange={() => {
                                              this.setState((prevState) => ({
                                                ...prevState,
                                                act_observation_data: {
                                                  ...prevState.act_observation_data,
                                                  type_of_housing: value,
                                                },
                                                errors: {
                                                  ...prevState.errors,
                                                  type_of_housing: false,
                                                },
                                              }))
                                              setActObservationValue(
                                                'type_of_housing',
                                                value
                                              )
                                            }}
                                            required
                                            value={value}
                                          />
                                        </div>
                                      )
                                    )}

                                    {act_observation.act_observation_data.type_of_housing === 'Tjetër' && (
                                      <input
                                        type="text"
                                        onChange={(event) => {
                                          const value = event.target.value
                                          this.setState((prevState) => ({
                                            ...prevState,
                                            act_observation_data: {
                                              ...prevState.act_observation_data,
                                              type_of_housing_other: value,
                                            },
                                            errors: {
                                              ...prevState.errors,
                                              type_of_housing_other: false,
                                            },
                                          }))
                                          setActObservationValue(
                                            'type_of_housing_other',
                                            value
                                          )
                                        }}
                                        value={
                                          act_observation.act_observation_data
                                            .type_of_housing_other
                                        }
                                        className={
                                          errors.type_of_housing_other
                                            ? 'form-control ps-5 input_error mt-2'
                                            : 'form-control ps-5 mt-2'
                                        }
                                        placeholder="Përshkruaj"
                                        id="type_of_housing_other"
                                        name="type_of_housing_other"
                                        required={true}
                                        style={{ width: '50%' }}
                                      />
                                    )}
                                  </div>
                                </div>

                                {/* Section ten */}
                                <div className="section_three">
                                  <div className="form-label mb-2 text-start">
                                    <label className={errors.apartment_condition ? 'input_error' : ''}>Gjendja e banesës?{' '}<span className="text-danger">*</span></label>
                                  </div>

                                  <div>
                                    {apartment_condition_values.map(
                                      ([text, value], i) => (
                                        <div key={i}>
                                          <RadioButton
                                            name="apartment_condition"
                                            label={text}
                                            checked={
                                              act_observation
                                                .act_observation_data
                                                .apartment_condition === value
                                            }
                                            onChange={() => {
                                              this.setState((prevState) => ({
                                                ...prevState,
                                                act_observation_data: {
                                                  ...prevState.act_observation_data,
                                                  apartment_condition: value,
                                                },
                                                errors: {
                                                  ...prevState.errors,
                                                  apartment_condition: false,
                                                },
                                              }))
                                              setActObservationValue(
                                                'apartment_condition',
                                                value
                                              )
                                            }}
                                            required
                                            value={value}
                                          />
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>

                                {/* Section Eleven */}
                                <div className="section_three">
                                  <div className="form-label mb-2 text-start">
                                    <label
                                      className={
                                        errors.located_family_condition
                                          ? 'label_error'
                                          : ''
                                      }
                                    >
                                      Përshkrimi i kushteve në të cilën ndodhet familja?{' '}
                                      <span className="text-danger">*</span>
                                    </label>

                                    <div className="mt-2">
                                      <textarea
                                        type={'text'}
                                        className={
                                          errors.located_family_condition
                                            ? 'form-control input_error'
                                            : 'form-control'
                                        }
                                        value={
                                          act_observation.act_observation_data
                                            .located_family_condition !== ''
                                            ? act_observation
                                                .act_observation_data
                                                .located_family_condition
                                            : undefined
                                        }
                                        onChange={(event) => {
                                          const value = event.target.value
                                          this.setState((prevState) => ({
                                            ...prevState,
                                            act_observation_data: {
                                              ...prevState.act_observation_data,
                                              located_family_condition: value,
                                            },
                                            errors: {
                                              ...prevState.errors,
                                              located_family_condition: false,
                                            },
                                          }))
                                          setActObservationValue(
                                            'located_family_condition',
                                            value
                                          )
                                        }}
                                        placeholder={'Përshkruaje'}
                                      />
                                    </div>
                                  </div>
                                </div>

                                {/*Ngarko Foton*/}
                                <div className="col-md-12 mb-2 mt-4">
                                  <div>
                                    <label
                                      className={
                                        errors.file
                                          ? 'form-label label_error'
                                          : 'form-label'
                                      }
                                      htmlFor="customFile"
                                    >
                                      Ngarko Dokumentat (Max: 5 file):
                                      {/* <span className="text-danger">*</span> */}
                                    </label>
                                    <input
                                      type="file"
                                      accept="image/*, application/pdf"
                                      className={
                                        errors.file
                                          ? 'form-control input_error'
                                          : 'form-control'
                                      }
                                      onChange={this.uploadMultipleFiles}
                                      multiple
                                      id="customFile"
                                    />
                                  </div>
                                  <span
                                    // className="text-muted"
                                    className="text-danger"
                                    style={{ fontSize: '0.8rem' }}
                                  >
                                    {' '}
                                    Pranohen vetem imazhe .jpg, .jpeg, .png dhe
                                    file pdf.
                                  </span>
                                </div>

                                <div className="form-label text-start" style={{ paddingTop: '40px' }}>
                                  <label>
                                    A kan firmosur të gjithë antarët e familjes?{' '}
                                    {/* <span className="text-danger">*</span> */}
                                  </label>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'start'}}>
                                  {possible_values.map(([text, value], i) => (
                                    <div
                                      key={i}
                                      style={
                                        i === 1 ? { paddingLeft: '110px' } : {}
                                      }
                                    >
                                      <RadioButton
                                        name="family_members_signed"
                                        label={text}
                                        checked={
                                          act_observation.act_observation_data
                                            .family_members_signed_all === value
                                        }
                                        onChange={() => {
                                          this.setState((prevState) => ({
                                            ...prevState,
                                            act_observation_data: {
                                              ...prevState.act_observation_data,
                                              family_members_signed_all: value,
                                            },
                                            errors: {
                                              ...prevState.errors,
                                              family_members_signed_all: false,
                                            },
                                          }))
                                          setActObservationValue(
                                            'family_members_signed_all',
                                            value
                                          )
                                        }}
                                        required
                                        value={value}
                                      />
                                    </div>
                                  ))}
                                </div>

                                {/* Nenshkrimi i antareve te familjes */}
                                <div className="section_three">
                                  <div className="form-label mb-2 text-start">
                                    <label
                                      className={
                                        errors.family_members_signature
                                          ? 'form-label label_error'
                                          : 'form-label'
                                      }
                                    >
                                      Nënshkrimi i antarëve të familjes të gjetur në banesë?{' '}
                                      {/* <span className="text-danger">*</span> */}
                                    </label>

                                    {/* <div className="mt-2">
                                      <div
                                        style={{ height: '210px' }}
                                        className={'form-control'}
                                      >
                                        <SignatureCanvas
                                          canvasProps={{
                                            width: '1200',
                                            height: 200,
                                            className: 'sigCanvas',
                                            clearOnResize: false,
                                          }}
                                          penColor="black"
                                        />
                                      </div>
                                    </div> */}
                                  </div>
                                </div>

                                <div style={{display: 'flex',flexDirection: 'row'}}>
                                  {/* <div style={{backgroundColor: '#fff', height: '400', display: 'flex', flexDirection: 'column', padding: '70px', borderRadius: '20px', }}> */}
                                  <div>
                                    <ul>
                                      {act_observation.act_observation_data?.fmembers?.map(
                                        (item, key) => {
                                          // return (
                                          //     <li scope="row" className="text-start">
                                          //         {capitalize(item.fm_first_name)} {" "} {capitalize(item.fm_last_name)}
                                          //       </li>
                                          // )

                                          return (
                                            // <li key={key}>
                                            <div
                                              key={key}
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                paddingBottom: '1.2rem',
                                              }}
                                            >
                                              <>
                                                <input
                                                  style={{
                                                    height: '30px',
                                                    width: '30px',
                                                  }}
                                                  type="checkbox"
                                                  id={`custom-checkbox-${key}`}
                                                  name={
                                                    capitalize(
                                                      item.fm_first_name
                                                    ) +
                                                    ' ' +
                                                    capitalize(
                                                      item.fm_last_name
                                                    )
                                                  }
                                                  value={
                                                    capitalize(
                                                      item.fm_first_name
                                                    ) +
                                                    ' ' +
                                                    capitalize(
                                                      item.fm_last_name
                                                    )
                                                  }
                                                  onChange={this.toggleHandler(
                                                    item
                                                  )}
                                                  checked={
                                                    members_who_sign[key]
                                                  }

                                                  // onChange={(event) => {
                                                  //   const value = event.target.value
                                                  //   this.setState((prevState) => ({
                                                  //     ...prevState,
                                                  //     act_observation_data: {
                                                  //       ...prevState.act_observation_data,
                                                  //       members_who_sign:value,
                                                  //     },
                                                  //     errors: {
                                                  //       ...prevState.errors,
                                                  //       members_who_sign: false,
                                                  //     },
                                                  //   }))
                                                  //   setActObservationValue('social_economic_assessment', value)

                                                  // }}
                                                />
                                                <span
                                                  style={{
                                                    fontSize: '1.4rem',
                                                    paddingLeft: '1.3rem',
                                                  }}
                                                >
                                                  {capitalize(
                                                    item.fm_first_name
                                                  )}{' '}
                                                  {capitalize(
                                                    item.fm_last_name
                                                  )}
                                                </span>
                                              </>
                                            </div>
                                          )
                                        }
                                      )}
                                    </ul>
                                    {/* </div> */}
                                  </div>
                                </div>

                                <div style={{backgroundColor: '#fefefe',height: '400px',borderRadius: '20px'}}>
                                  <SignatureCanvas
                                    canvasProps={{
                                      width: 1200,
                                      height: 400,
                                      className: 'sigCanvas',
                                      // clearOnResize: false,
                                      backgroundColor: '#fff',
                                    }}
                                    ref={(ref) => {
                                      this.family_members_who_sign = ref
                                    }}
                                    penColor="black"
                                  />
                                </div>

                                <div
                                  style={{
                                    paddingTop: '20px',
                                    width: '100%',
                                    // height: '30px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    // backgroundColor: '#4a5c8a'
                                  }}
                                >
                                  {/* <Icon
                                      icon="material-symbols:delete-outline"
                                      width="20%"
                                      height="30%"
                                      className="custom_icon"
                                    /> */}
                                  <button
                                    className="btn btn-primary border-button mb-4"
                                    onClick={
                                      this.handle_clear_family_members_sign
                                    }
                                  >
                                    {' '}
                                    Fshi{' '}
                                  </button>
                                  <button
                                    className="btn btn-primary border-button mb-4"
                                    onClick={
                                      this.handle_save_family_members_sign
                                    }
                                  >
                                    {' '}
                                    Ruaj{' '}
                                  </button>
                                </div>

                                {/* Section 15 */}
                                <div className="section_three">
                                  <div className="form-label mb-2 text-start">
                                    <label
                                      className={
                                        errors.social_economic_assessment
                                          ? 'label_error'
                                          : ''
                                      }
                                    >
                                      {/* A duhet të përfitojë ndihmë ekonomike familja bazuar në vlerësimin social ekonomik?{' '} */}
                                      Mendoni se është në kushtet e përfitimit të ndihmës ekonomike bazuar në vlersimin social ekonomik?{' '}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>

                                  <div
                                  // style={{
                                  //   display: 'flex',
                                  //   justifyContent: 'start',
                                  // }}
                                  >
                                    {social_economic_possible_values.map(
                                      ([text, value], i) => (
                                        <div
                                          key={i}
                                          // style={
                                          //   i === 1
                                          //     ? { paddingLeft: '110px' }
                                          //     : {}
                                          // }
                                        >
                                          <RadioButton
                                            name="social_economic_assessment"
                                            label={text}
                                            checked={act_observation.act_observation_data.social_economic_assessment === value}
                                            onChange={(event) => {
                                              const value = event.target.value
                                              this.setState((prevState) => ({
                                                ...prevState,
                                                act_observation_data: {
                                                  ...prevState.act_observation_data,
                                                  social_economic_assessment:
                                                    value,
                                                },
                                                errors: {
                                                  ...prevState.errors,
                                                  social_economic_assessment: false,
                                                },
                                              }))
                                              setActObservationValue('social_economic_assessment',value)
                                              {
                                                value !== 'po'
                                                  ? (
                                                      setActObservationValue('family_economic_bad_condition',''),
                                                      setActObservationValue('need_family_other_services','')
                                                    )
                                                  : null
                                              }
                                            }}
                                            required
                                            value={value}
                                          />
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>

                                {/* Section 15.1 */}
                                {act_observation.act_observation_data.social_economic_assessment === 'po' && (
                                  <>
                                    <div className="section_three">
                                      <div className="form-label mb-2 text-start">
                                        <label
                                          className={
                                            errors.family_economic_bad_condition
                                              ? 'label_error'
                                              : ''
                                          }
                                        >
                                          A është kjo familje në gjëndje shumë të keqe ekonomike/ekstremisht e varfër?{' '}
                                          <span className="text-danger">*</span>
                                        </label>
                                      </div>

                                      <div style={{display: 'flex',justifyContent: 'start'}}>
                                        {possible_values.map(
                                          ([text, value], i) => (
                                            <div key={i}
                                              style={
                                                i === 1
                                                  ? { paddingLeft: '110px' }
                                                  : {}
                                              }
                                            >
                                              <RadioButton
                                                name="family_economic_bad_condition"
                                                label={text}
                                                checked={act_observation.act_observation_data.family_economic_bad_condition === value}
                                                onChange={(event) => {
                                                  const value = event.target.value
                                                  this.setState(
                                                    (prevState) => ({
                                                      ...prevState,
                                                      act_observation_data: {
                                                        ...prevState.act_observation_data,
                                                        family_economic_bad_condition: value,
                                                      },
                                                      errors: {
                                                        ...prevState.errors,
                                                        family_economic_bad_condition: false,
                                                      },
                                                    })
                                                  )
                                                  setActObservationValue('family_economic_bad_condition', value)
                                                }}
                                                required
                                                value={value}
                                              />
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>

                                    {
                                      act_observation.act_observation_data.family_economic_bad_condition === 'po' && (
                                            <div className="section_three">
                                              <div className="form-label mb-2 text-start">
                                                <label className={ errors.need_family_other_services ? 'label_error' : ''}>A ka nevojë për nevoja të tjera?{' '}</label>
                                              </div>

                                              <div>
                                                {need_family_other_services_values.map(
                                                    ([text, value], i) => (
                                                        <div
                                                            key={i}
                                                            // style={
                                                            //   i === 1
                                                            //     ? { paddingLeft: '175px' }
                                                            //     : {}
                                                            // }
                                                        >
                                                          <RadioButton
                                                              name="need_family_other_services"
                                                              label={text}
                                                              checked={act_observation.act_observation_data.need_family_other_services === value}
                                                              onChange={(event) => {
                                                                const value =
                                                                    event.target.value
                                                                this.setState(
                                                                    (prevState) => ({
                                                                      ...prevState,
                                                                      act_observation_data: {
                                                                        ...prevState.act_observation_data,
                                                                        need_family_other_services:value,
                                                                      },
                                                                      errors: {
                                                                        ...prevState.errors,
                                                                        need_family_other_services: false,
                                                                      },
                                                                    })
                                                                )
                                                                setActObservationValue('need_family_other_services',value)
                                                              }}
                                                              required
                                                              value={value}
                                                          />
                                                        </div>
                                                    )
                                                )}
                                              </div>

                                              {act_observation.act_observation_data.need_family_other_services === 'Tjetër' && (
                                                  <>
                                                    <input
                                                        type="text"
                                                        onChange={(event) => {
                                                          const value = event.target.value
                                                          this.setState((prevState) => ({
                                                            ...prevState,
                                                            act_observation_data: {
                                                              ...prevState.act_observation_data,
                                                              need_family_other_services_extra:
                                                              value,
                                                            },
                                                            errors: {
                                                              ...prevState.errors,
                                                              need_family_other_services_extra: false,
                                                            },
                                                          }))
                                                          setActObservationValue('need_family_other_services_extra',value)
                                                        }}
                                                        value={act_observation.act_observation_data.need_family_other_services_extra}
                                                        className={
                                                          errors.need_family_other_services_extra
                                                              ? 'form-control ps-5 input_error mt-2'
                                                              : 'form-control ps-5 mt-2'
                                                        }
                                                        placeholder="Përshkruaj"
                                                        id="need_family_other_services_extra"
                                                        name="need_family_other_services_extra"
                                                        required={true}
                                                        style={{ width: '50%' }}
                                                    />
                                                  </>
                                              )}
                                            </div>
                                        )
                                    }
                                  </>
                                )}
                              </>
                            )}

                            <div className="form-label text-start" style={{ paddingTop: '40px' }}>
                              <label
                              // className={
                              //   errors.form_was_filled_at_home
                              //     ? 'input_error'
                              //     : ''
                              // }
                              >
                                Formulari u plotesua ne banese?{' '}
                                {/* <span className="text-danger">*</span> */}
                              </label>
                            </div>

                            <div style={{display: 'flex',justifyContent: 'start',}}>
                              {possible_values.map(([text, value], i) => (
                                <div
                                  key={i}
                                  style={
                                    i === 1 ? { paddingLeft: '110px' } : {}
                                  }
                                >
                                  <RadioButton
                                    name="form_was_filled_at_home"
                                    label={text}
                                    checked={
                                      act_observation.act_observation_data
                                        .form_was_filled_at_home === value
                                    }
                                    onChange={(event) => {
                                      const value = event.target.value
                                      this.setState((prevState) => ({
                                        ...prevState,
                                        act_observation_data: {
                                          ...prevState.act_observation_data,
                                          form_was_filled_at_home: value,
                                        },
                                        errors: {
                                          ...prevState.errors,
                                          form_was_filled_at_home: false,
                                        },
                                      }))
                                      setActObservationValue(
                                        'form_was_filled_at_home',
                                        value
                                      )
                                      {
                                        value === 'jo'
                                          ? (this.clearCurrentLocation(),
                                            setActObservationValue(
                                              'currentLocation',
                                              {
                                                location: false,
                                                latitude: '',
                                                longitude: '',
                                              }
                                            ))
                                          : null
                                      }
                                    }}
                                    value={value}
                                  />
                                </div>
                              ))}
                            </div>

                            {/* Percakto Vendndodhjen */}
                            {act_observation.act_observation_data
                              .form_was_filled_at_home === 'po' && (
                              <>
                                <div className="col-md-12 mb-2 mt-4">
                                  <div className="mb-3">
                                    <label
                                      className={
                                        errors.currentLocation
                                          ? 'form-label label_error'
                                          : 'form-label'
                                      }
                                    >
                                      Përcakto vendndodhjen?
                                      {/* <span className="text-danger">*</span> */}
                                    </label>
                                  </div>

                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'start',
                                    }}
                                  >
                                    <div>
                                      <div
                                        className="btn btn-primary border-button"
                                        onClick={this.getUserLocation}
                                      >
                                        Merr vendndodhjen
                                      </div>
                                    </div>
                                    {location && (
                                      <span
                                        style={{
                                          paddingLeft: '30px',
                                          fontSize: '16px',
                                          paddingTop: '5px',
                                        }}
                                      >
                                        {' '}
                                        {latitude} {' : '} {longitude}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}


                            {act_observation.act_observation_data.family_present_in_the_apartment === 'jo' && (
                                <>
                                  {/* EVL */}
                                  <div className="section_three">
                                    <div className="form-label mb-2 text-start">
                                      <label
                                          className={
                                            errors.located_family_condition
                                                ? 'label_error'
                                                : ''
                                          }
                                      >
                                        Përshkrimi i kushteve në të cilën ndodhet familja?{' '}
                                        <span className="text-danger">*</span>
                                      </label>

                                      <div className="mt-2">
                                  <textarea
                                      type={'text'}
                                      className={
                                        errors.located_family_condition
                                            ? 'form-control input_error'
                                            : 'form-control'
                                      }
                                      value={
                                        act_observation.act_observation_data
                                            .located_family_condition !== ''
                                            ? act_observation.act_observation_data
                                                .located_family_condition
                                            : undefined
                                      }
                                      onChange={(event) => {
                                        const value = event.target.value
                                        this.setState((prevState) => ({
                                          ...prevState,
                                          act_observation_data: {
                                            ...prevState.act_observation_data,
                                            located_family_condition: value,
                                          },
                                          errors: {
                                            ...prevState.errors,
                                            located_family_condition: false,
                                          },
                                        }))
                                        setActObservationValue(
                                            'located_family_condition',
                                            value
                                        )
                                      }}
                                      placeholder={'Përshkruaje'}
                                  />
                                      </div>
                                    </div>
                                  </div>

                                  {/*NGARKO FOTON*/}
                                  <div className="col-md-12 mb-2 mt-4">
                                    <div>
                                      <label
                                          className={
                                            errors.file
                                                ? 'form-label label_error'
                                                : 'form-label'
                                          }
                                          htmlFor="customFile"
                                      >
                                        Ngarko Dokumentat (Max: 5 file):
                                        {/* <span className="text-danger">*</span> */}
                                      </label>
                                      <input
                                          type="file"
                                          accept="image/*, application/pdf"
                                          className={
                                            errors.file
                                                ? 'form-control input_error'
                                                : 'form-control'
                                          }
                                          onChange={this.uploadMultipleFiles}
                                          multiple
                                          id="customFile"
                                      />
                                    </div>
                                    <span
                                        // className="text-muted"
                                        className="text-danger"
                                        style={{ fontSize: '0.8rem' }}
                                    >
                                {' '}
                                      Pranohen vetem imazhe .jpg, .jpeg, .png dhe file
                                pdf.
                              </span>
                                  </div>

                                  {/* NDIHMA EKONOMIKE */}
                                  <div className="section_three">
                                    <div className="form-label mb-2 text-start">
                                      <label
                                          className={
                                            errors.social_economic_assessment
                                                ? 'label_error'
                                                : ''
                                          }
                                      >
                                        {/* A duhet të përfitojë ndihmë ekonomike familja bazuar në vlerësimin social ekonomik?{' '} */}
                                        Mendoni se është në kushtet e përfitimit
                                        të ndihmës ekonomike bazuar në vlersimin
                                        social ekonomik?{' '}
                                        <span className="text-danger">*</span>
                                      </label>
                                    </div>

                                    <div
                                        // style={{
                                        //   display: 'flex',
                                        //   justifyContent: 'start',
                                        // }}
                                    >
                                      {social_economic_possible_values.map(
                                          ([text, value], i) => (
                                              <div
                                                  key={i}
                                                  // style={
                                                  //   i === 1
                                                  //     ? { paddingLeft: '110px' }
                                                  //     : {}
                                                  // }
                                              >
                                                <RadioButton
                                                    name="social_economic_assessment"
                                                    label={text}
                                                    checked={
                                                        act_observation
                                                            .act_observation_data
                                                            .social_economic_assessment ===
                                                        value
                                                    }
                                                    onChange={(event) => {
                                                      const value = event.target.value
                                                      this.setState((prevState) => ({
                                                        ...prevState,
                                                        act_observation_data: {
                                                          ...prevState.act_observation_data,
                                                          social_economic_assessment:
                                                          value,
                                                        },
                                                        errors: {
                                                          ...prevState.errors,
                                                          social_economic_assessment: false,
                                                        },
                                                      }))
                                                      setActObservationValue(
                                                          'social_economic_assessment',
                                                          value
                                                      )
                                                      {
                                                        value !== 'po'
                                                            ? (
                                                                setActObservationValue('family_economic_bad_condition',''),
                                                                setActObservationValue('need_family_other_services','')
                                                              )
                                                            : null
                                                      }
                                                    }}
                                                    required
                                                    value={value}
                                                />
                                              </div>
                                          )
                                      )}
                                    </div>
                                  </div>

                                  {/* Section 15.1 */}
                                  {act_observation.act_observation_data.social_economic_assessment === 'po' && (
                                      <>
                                        <div className="section_three">
                                          <div className="form-label mb-2 text-start">
                                            <label
                                                className={
                                                  errors.family_economic_bad_condition
                                                      ? 'label_error'
                                                      : ''
                                                }
                                            >
                                              A është kjo familje në gjëndje shumë
                                              të keqe ekonomike/ekstremisht e
                                              varfër?{' '}
                                              <span className="text-danger">*</span>
                                            </label>
                                          </div>

                                          <div
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'start',
                                              }}
                                          >
                                            {possible_values.map(
                                                ([text, value], i) => (
                                                    <div
                                                        key={i}
                                                        style={
                                                          i === 1
                                                              ? { paddingLeft: '110px' }
                                                              : {}
                                                        }
                                                    >
                                                      <RadioButton
                                                          name="family_economic_bad_condition"
                                                          label={text}
                                                          checked={
                                                              act_observation.act_observation_data.family_economic_bad_condition === value
                                                          }
                                                          onChange={(event) => {
                                                            const value =
                                                                event.target.value
                                                            this.setState(
                                                                (prevState) => ({
                                                                  ...prevState,
                                                                  act_observation_data: {
                                                                    ...prevState.act_observation_data,
                                                                    family_economic_bad_condition:
                                                                    value,
                                                                  },
                                                                  errors: {
                                                                    ...prevState.errors,
                                                                    family_economic_bad_condition: false,
                                                                  },
                                                                })
                                                            )
                                                            setActObservationValue(
                                                                'family_economic_bad_condition',
                                                                value
                                                            )
                                                          }}
                                                          required
                                                          value={value}
                                                      />
                                                    </div>
                                                )
                                            )}
                                          </div>
                                        </div>


                                        {
                                            act_observation.act_observation_data.family_economic_bad_condition === 'po' && (
                                                <div className="section_three">
                                                  <div className="form-label mb-2 text-start">
                                                    <label className={ errors.need_family_other_services ? 'label_error' : ''}>A ka nevojë për nevoja të tjera?{' '}</label>
                                                  </div>

                                                  <div>
                                                    {need_family_other_services_values.map(
                                                        ([text, value], i) => (
                                                            <div
                                                                key={i}
                                                                // style={
                                                                //   i === 1
                                                                //     ? { paddingLeft: '175px' }
                                                                //     : {}
                                                                // }
                                                            >
                                                              <RadioButton
                                                                  name="need_family_other_services_second_choise"
                                                                  label={text}
                                                                  checked={act_observation.act_observation_data.need_family_other_services === value}
                                                                  onChange={(event) => {
                                                                    const value =
                                                                        event.target.value
                                                                    this.setState(
                                                                        (prevState) => ({
                                                                          ...prevState,
                                                                          act_observation_data: {
                                                                            ...prevState.act_observation_data,
                                                                            need_family_other_services:
                                                                            value,
                                                                          },
                                                                          errors: {
                                                                            ...prevState.errors,
                                                                            need_family_other_services: false,
                                                                          },
                                                                        })
                                                                    )
                                                                    setActObservationValue('need_family_other_services',value)
                                                                  }}
                                                                  required
                                                                  value={value}
                                                              />
                                                            </div>
                                                        )
                                                    )}
                                                  </div>

                                                  {act_observation.act_observation_data.need_family_other_services === 'Tjetër' && (
                                                      <>
                                                        <input
                                                            type="text"
                                                            onChange={(event) => {
                                                              const value = event.target.value
                                                              this.setState((prevState) => ({
                                                                ...prevState,
                                                                act_observation_data: {
                                                                  ...prevState.act_observation_data,
                                                                  need_family_other_services_extra:
                                                                  value,
                                                                },
                                                                errors: {
                                                                  ...prevState.errors,
                                                                  need_family_other_services_extra: false,
                                                                },
                                                              }))
                                                              setActObservationValue(
                                                                  'need_family_other_services_extra',
                                                                  value
                                                              )
                                                            }}
                                                            value={
                                                              act_observation
                                                                  .act_observation_data
                                                                  .need_family_other_services_extra
                                                            }
                                                            className={
                                                              errors.need_family_other_services_extra
                                                                  ? 'form-control ps-5 input_error mt-2'
                                                                  : 'form-control ps-5 mt-2'
                                                            }
                                                            placeholder="Përshkruaj"
                                                            id="need_family_other_services_extra"
                                                            name="need_family_other_services_extra"
                                                            required={true}
                                                            style={{ width: '50%' }}
                                                        />
                                                      </>
                                                  )}
                                                </div>
                                            )
                                        }


                                      </>
                                  )}
                                </>
                            )}


                            {/*Nenshkrimi i antareve te grupit te punes */}
                            <div className="section_three">
                              <div className="form-label mb-2 text-start">
                                <label
                                  className={
                                    errors.working_group_members_signature
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Nënshkrimi i antarëve të grupit të punës?{' '}
                                  {/* <span className="text-danger">*</span> */}
                                </label>

                                <div className="mt-2">
                                  {/* <div style={{ height: '310px' }} className={'form-control'} > */}
                                  <div
                                    style={{
                                      backgroundColor: '#fefefe',
                                      height: '400px',
                                      borderRadius: '20px',
                                    }}
                                  >
                                    <SignatureCanvas
                                      canvasProps={{
                                        width: '1200',
                                        height: 400,
                                        className: 'sigCanvas',
                                        // clearOnResize: false,
                                        backgroundColor: '#fff',
                                      }}
                                      ref={(ref) => {
                                        this.working_group_sign = ref
                                      }}
                                      penColor="black"
                                    />
                                  </div>

                                  <div
                                    style={{
                                      paddingTop: '20px',
                                      width: '100%',
                                      // height: '30px',
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      // backgroundColor: '#4a5c8a'
                                    }}
                                  >
                                    {/* <Icon
                                      icon="material-symbols:delete-outline"
                                      width="20%"
                                      height="30%"
                                      className="custom_icon"
                                    /> */}
                                    <button
                                      className="btn btn-primary border-button mb-4"
                                      onClick={this.handleClearWorkingGroupSign}
                                    >
                                      {' '}
                                      Fshi{' '}
                                    </button>
                                    <button
                                      className="btn btn-primary border-button mb-4"
                                      onClick={this.handleSaveWorkingGroupSign}
                                    >
                                      {' '}
                                      Ruaj{' '}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>




                        <div className="col-md-12 mt-4">
                          <div className="d-grid">
                            <button className="btn btn-primary border-button"
                              // disabled={!errorOnForm}
                              onClick={(e) =>this.initActObservationRegistration(e)}>{' '}RUAJ{' '}
                            </button>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="d-grid">
                            <p className="text-danger mt-3">
                              {' '}
                              * Fusha të detyrueshme{' '}
                            </p>
                          </div>
                        </div>
                      </div>
                    </form>





                    {showAlertFamilyMember ? (
                      <Col xl={12} lg={12} sm={12} className="mb-2">
                        <SweetAlert
                          title={''}
                          showCancel
                          style={{ width: '40em !important' }}
                          cancelBtnText="Ktheu në Krye"
                          confirmBtnText="Ruaj të dhënat"
                          cancelBtnBsStyle="light"
                          confirmBtnBsStyle="primary"
                          onConfirm={() => {
                            this.addFamilyMember()
                          }}
                          onCancel={() => {
                            this.closeModal()
                          }}
                          type={'controlled'}
                          dependencies={[]}
                        >
                          {(renderProps) => (
                            <div>
                              <h4 style={{color: '#124057',textTransform: 'uppercase'}}>Antar i ri i familjes</h4>
                              {/*<h5 style={{color: '#124057'}}>{this.props.act_observation.act_observation_data.persona_qe_lidhen_ne_shoqeri_type}</h5>*/}
                              <hr />

                              {/* EMER */}
                              <div>
                                <div className="form-label mb-2 text-start">
                                  <label>
                                    Emër <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <input
                                  type={'text'}
                                  className={
                                    family_member_errors.fm_first_name
                                      ? 'form-control input_error'
                                      : 'form-control'
                                  }
                                  value={
                                    family_members_form_data.fm_first_name !==
                                    ''
                                      ? family_members_form_data.fm_first_name
                                      : undefined
                                  }
                                  onKeyDown={renderProps.onEnterKeyDownConfirm}
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      family_members_form_data: {
                                        ...prevState.family_members_form_data,
                                        fm_first_name: value,
                                      },
                                      family_member_errors: {
                                        ...prevState.family_member_errors,
                                        fm_first_name: false,
                                      },
                                    }))
                                    setFamilyMemberValue('fm_first_name', value)
                                  }}
                                  placeholder={'Emër'}
                                />
                              </div>

                              <br />

                              {/* Mbiemer */}
                              {/* <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',}}>
                                      <div className="form-label mb-4 text-start ">
                                        <label>
                                          Mbiemri i njëjte me atë të kryefamiljarit?{' '}
                                          <span className="text-danger">*</span>
                                        </label>
                                      </div>                                      
                                      
                                      <input
                                        type={'checkbox'}
                                        className="form-check-input"
                                        style={{width: '30px', height: '30px'}}
                                        value={this.state.surname_equal_to_head_family_memeber}
                                        // value={
                                        //   family_members_form_data.fm_last_name !== ''
                                        //     ? family_members_form_data.fm_last_name
                                        //     : undefined
                                        // }
                                        
                                        onChange={(event) => {
                                          const value = event.target.value
                                          this.setState((prevState) => ({
                                            ...prevState,
                                            family_members_form_data: {
                                              ...prevState.family_members_form_data,
                                              fm_last_name: value,
                                            },
                                            family_member_errors: {
                                              ...prevState.family_member_errors,
                                              fm_last_name: false,
                                            },
                                          }))
                                          setFamilyMemberValue('fm_last_name', value)
                                        }}
                                      />
                                    </div> */}

                              {/* MBIEMER */}
                              <div>
                                <div className="form-label mb-2 text-start">
                                  <label>
                                    Mbiemër{' '}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <input
                                  type={'text'}
                                  className={
                                    family_member_errors.fm_last_name
                                      ? 'form-control input_error'
                                      : 'form-control'
                                  }
                                  value={
                                    family_members_form_data.fm_last_name !== ''
                                      ? family_members_form_data.fm_last_name
                                      : undefined
                                  }
                                  onKeyDown={renderProps.onEnterKeyDownConfirm}
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      family_members_form_data: {
                                        ...prevState.family_members_form_data,
                                        fm_last_name: value,
                                      },
                                      family_member_errors: {
                                        ...prevState.family_member_errors,
                                        fm_last_name: false,
                                      },
                                    }))
                                    setFamilyMemberValue('fm_last_name', value)
                                  }}
                                  placeholder={'Mbiemër'}
                                />
                              </div>

                              <br />

                              {/* EDUCATION */}
                              <div>
                                <div className="form-label mb-2 text-start">
                                  <label
                                    className={
                                      family_member_errors.fm_education
                                        ? 'form-label label_error'
                                        : 'form-label'
                                    }
                                  >
                                    Cfare arsimi ka?{' '}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  {education_list.map(([text, value], i) => (
                                    <div key={i}>
                                      <RadioButton
                                        name="arsimi"
                                        label={text}
                                        onChange={(event) => {
                                          const value = event.target.value
                                          this.setState((prevState) => ({
                                            ...prevState,
                                            family_members_form_data: {
                                              ...prevState.family_members_form_data,
                                              fm_education: value,
                                            },
                                            family_member_errors: {
                                              ...prevState.family_member_errors,
                                              fm_education: false,
                                            },
                                          }))
                                          setFamilyMemberValue(
                                            'fm_education',
                                            value
                                          )
                                        }}
                                        value={value}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <br />

                              {/* STATUSI PUNESIMIT */}
                              <div>
                                <div className="form-label mb-2 text-start mt-2">
                                  <label
                                    className={
                                      family_member_errors.fm_job_position
                                        ? 'form-label label_error'
                                        : 'form-label'
                                    }
                                  >
                                    Punësimi?{' '}
                                    <span className="text-danger">*</span>
                                  </label>
                                </div>

                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                  }}
                                >
                                  {employeed_list.map(([text, value], i) => (
                                    <div
                                      key={i}
                                      // style={i === 1 ? { paddingLeft: '110px' } : {}}
                                    >
                                      <RadioButton
                                        name="punesimi"
                                        label={text}
                                        onChange={(event) => {
                                          const value = event.target.value
                                          this.setState((prevState) => ({
                                            ...prevState,
                                            family_members_form_data: {
                                              ...prevState.family_members_form_data,
                                              fm_job_position: value,
                                            },
                                            family_member_errors: {
                                              ...prevState.family_member_errors,
                                              fm_job_position: false,
                                            },
                                          }))
                                          setFamilyMemberValue(
                                            'fm_job_position',
                                            { label: text, value: value }
                                          )

                                          {
                                            value === 'papune'
                                              ? setFamilyMemberValue(
                                                  'fm_salary_from_employment',
                                                  0
                                                )
                                              : null
                                          }
                                        }}
                                        value={value}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <br />

                              {/* {(act_observation.family_members_data?.fm_job_position?.value === 'punesuar' || act_observation.family_members_data?.fm_job_position?.value === 'vetpunesuar') && 

                                    (
                                        <> */}
                              <div className="form-label mb-2 text-start">
                                <label
                                  className={
                                    family_member_errors.fm_salary_from_employment
                                      ? 'form-label label_error'
                                      : 'form-label'
                                  }
                                >
                                  Sa jan të ardhurat mujore nga punësimi?{' '}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>

                              <input
                                type={'number'}
                                disabled={act_observation.family_members_data?.fm_job_position?.value === 'papune' || act_observation.family_members_data?.fm_job_position?.value === 'minoren'}
                                className={
                                  family_member_errors.fm_salary_from_employment
                                    ? 'form-control input_error'
                                    : 'form-control'
                                }
                                value={
                                  // family_members_form_data.fm_salary_from_employment !== ''
                                  //   ? family_members_form_data.fm_salary_from_employment
                                  //   : undefined

                                  act_observation.family_members_data?.fm_job_position?.value === 'papune' || act_observation.family_members_data?.fm_job_position?.value === 'minoren' ? parseFloat(0) : family_members_form_data.fm_salary_from_employment
                                }
                                onKeyDown={renderProps.onEnterKeyDownConfirm}
                                onChange={(event) => {
                                  const value = event.target.value
                                  this.setState((prevState) => ({
                                    ...prevState,
                                    family_members_form_data: {
                                      ...prevState.family_members_form_data,
                                      fm_salary_from_employment: value,
                                    },
                                    family_member_errors: {
                                      ...prevState.family_member_errors,
                                      fm_salary_from_employment: false,
                                    },
                                  }))
                                  setFamilyMemberValue(
                                    'fm_salary_from_employment',
                                    value
                                  )
                                }}
                                placeholder={'Vendos shumën'}
                              />
                              {/* </>
                                      )
                                    } */}

                              <br />
                            </div>
                          )}
                        </SweetAlert>
                      </Col>
                    ) : null}

                    {/* {
                      show_save_act_observation_data_alert && (
                          Swal.fire({
                            // title: "Dëshironi të ruani akt konstatimin?",
                            text: "Dëshironi të ruani akt konstatimin?",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#124057",
                            cancelButtonColor: "#d4d4d4",
                            confirmButtonText: "Ruaj!",
                            cancelButtonText: "Anullo"
                          }).then((result) => {
                            console.log("ERNEST", result);

                            if (result.isConfirmed) {
                              Swal.fire({
                                position: "top-top",
                                icon: "success",
                                title: "Akt konstatimi u ruajt me sukses",
                                showConfirmButton: true,
                                timer: 1500
                              });
                            }else{
                             this.closeModal()
                            }
                          })
                      )
                    } */}

                    {act_observation.upload_loading && (
                      <>
                        {/* <SweetAlert success title="Perfekt!" onConfirm={this.onConfirm} onCancel={this.onCancel}>
                            Akt konstatimi u ruajt me sukses!
                          </SweetAlert> */}
                        {/* 
                          <SweetAlert 
                            error 
                            title="Oops!" 
                            onConfirm={this.onConfirm} 
                            onCancel={this.onCancel}>
                            Ruajtja e akt konstatimit deshtoi! Ju lutem provoni perseri!
                          </SweetAlert> */}

                        <SweetAlert
                          text="Ju lutem prisni..."
                          custom
                          showConfirm={false}
                          style={{
                            height: '250px',
                            width: '250px',
                            borderRadius: '5px',
                            boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.3)',
                            // boxShadow: "0px 10px 20px 2px rgba(0, 0, 0, 0.25)"
                            // boxShadow: "inset 0px 5px 10px 0px rgba(0, 0, 0, 0.5)"
                          }}
                        >
                          <SyncLoader
                            color="#124057"
                            // act_observation.upload_loading
                            loading={act_observation.upload_loading}
                          />
                        </SweetAlert>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    act_observation: state.act_observation,
    code_error: state.act_observation.code_error,
  }
}

const mapDispatchToProps = {
  setActObservationValue,
  setFamilyMemberValue,
  setErrorMapTrue,
  setErrorMapFalse,
  setErrorCodeFalse,
  getDistrictList,
  getMunicipalityList,
  getAdministrativeList,
  getVillageList,
  uploadMedia,
  uploadNewUpdate,
  uploadWorkingGroupSignature,
  uploadFamilyMembersSignature,
  registerAktObservation,
  // setNewUpdateValue,
}

export default connect(mapStateToProps, mapDispatchToProps)(ActObservation)
