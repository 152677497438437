import React, { Component } from 'react'
import { Icon } from '@iconify/react'

import { connect } from 'react-redux'
import {
  setRegistrationValue,
  registerNewUser,
  setErrorMapTrue,
  setErrorMapFalse,
  setErrorCodeFalse,
  getUserRoles,
} from '../store/user/actions'

import Map from '../components/vector_map/vector_map'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import SimpleBar from 'simplebar-react'
import { sorting } from '../assets/helpers/helpers'
import DotLoader from 'react-spinners/DotLoader'

import 'simplebar/dist/simplebar.min.css'
import '../assets/css/materialdesignicons.min.css'
import '../assets/css/custom.css'

import {
  district_list,
  // origin_list,
  //   roles_list,
  // area_list,
  // legal_status_list,
  // education_list,
} from '../utils/constants.js'

import SweetAlert from 'react-bootstrap-sweetalert'

import { Button, Col } from 'reactstrap'

import {
  capitalize,
  clearApiBaseUrl,
  formatUpdateCreationDate,
} from '../assets/helpers/helpers'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      registration_data: props.user.registration_data,
      errors: {
        bussines_logo_error_show: false,
        product_image_error_show: false,
      },
      bussines_logo_error_show: false,
      product_image_error_show: false,
      bussines_product_img_error_show: false,
      shareholders_errors: {},
      new_update: props.user.new_update,
      year: new Date().getFullYear(),
      showAlertProfilBiznesi: false,
      shareholders_alert_form_data: {},
      default_district_value: 'Zgjidh qarkun ...',
      default_role_value: 'Zgjidh rolin ...',
      default_origin_value: 'Zgjidh origjinën ...',
      default_legal_status_value: 'Zhgjidh statusin ligjor ...',
      default_object_value: 'Zhgjidh objektin ...',
      default_education_value: 'Zhgjidh arsimin ...',
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const { setErrorMapFalse, getUserRoles, user } = this.props

    if (user.error_map) {
      setErrorMapFalse()
    }
    console.log('USER_ROLES', user.user_roles.list.data)
    if (
      user.user_roles.list.data.length === 0 &&
      user.user_roles.list.loading === false
    ) {
      getUserRoles()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.user.redirect_to_home !== this.props.user.redirect_to_home) {
      this.props.history.push('/')
    }
  }

  handleFormValidation = () => {
    const {
      errors,
      shareholders_errors,
      bussines_logo_error_show,
      product_image_error_show,
    } = this.state
    const form_data = this.props.user.registration_data
    const { setErrorMapTrue } = this.props
    let form_is_valid = true
    let mail_regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let phone_regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g

    if (!form_data?.name?.trim()) {
      errors.name = true
      form_is_valid = false
    }

    if (!form_data?.surname?.trim()) {
      errors.surname = true
      form_is_valid = false
    }

    if (!form_data?.district?.trim()) {
      errors.district = true
      form_is_valid = false
    }

    // if (!form_data?.telefono?.trim() ||!phone_regex?.test(form_data?.telefono)) {
    //     errors.telefono = true
    //     form_is_valid = false
    // }


    if (!form_data?.role?.trim()) {
      errors.role = true
      form_is_valid = false
    }

    if (!form_data?.email?.trim() || !mail_regex.test(form_data.email)) {
      errors.email = true
      form_is_valid = false
    }

    if (!form_data?.password?.trim()) {
      errors.password = true
      form_is_valid = false
    }

    console.log('FORM_DATA_VALUES', form_data)
    console.log('FORM_DATA_VALUES_VALIDATION', errors)

    this.scrollToFirstError(errors)

    this.setState((prevState) => ({
      ...prevState,
      errors: errors,
    }))
    return form_is_valid
  }

  convertImageToBase64 = (file, callback) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = function () {
      const image = new Image()
      image.crossOrigin = 'anonymous'
      image.onload = () => {
        callback(reader.result, image.naturalWidth, image.naturalHeight)
      }
      image.src = reader.result
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  initUserRegistration = (event) => {
    const self = this
    event.preventDefault()
    const { registerNewUser, user } = self.props
    const registration_data = user.registration_data
    const data = this.props.user.new_update

    if (self.handleFormValidation()) {
      console.log('USER_DATA', data)
      console.log('registration_data', registration_data)
      registerNewUser(registration_data)
    }
  }

  scrollToFirstError = (errors) => {
    const all_errors = Object.entries(errors).filter(
      (error) => error[1] === true
    )

    const sorted_errors = all_errors.sort(function (a, b) {
      return sorting.indexOf(a[0]) - sorting.indexOf(b[0])
    })

    if (sorted_errors.length) {
      const input = document.getElementById(sorted_errors[0][0])
      input.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      })
    }
  }

  scrollToModalFirstError = (errors) => {
    const all_errors = Object.entries(errors).filter(
      (error) => error[1] === true
    )

    const sorted_errors = all_errors.sort(function (a, b) {
      return sorting.indexOf(a[0]) - sorting.indexOf(b[0])
    })

    if (sorted_errors.length) {
      const input = document.getElementById(sorted_errors[0][0])
      input.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      })
    }
  }

  openModal() {
    const self = this
    self.setState({ showAlertProfilBiznesi: true })
  }

  closeModal() {
    const self = this
    self.setState({
      showAlertProfilBiznesi: false,
      shareholders_errors: {},
      shareholders_alert_form_data: {},
    })
  }

  render() {
    const { setRegistrationValue, setErrorCodeFalse, uploadMedia, years } = this.props

    const {
      errors,
      year,
      showAlertProfilBiznesi,
      shareholders_alert_form_data,
      shareholders_errors,
      default_district_value,
      default_origin_value,
      default_country_value,
      default_legal_status_value,
      default_object_value,
      default_education_value,
      default_role_value,
    } = this.state
    const error_map = this.props.user.error_map
    const code_error = this.props.code_error
    this.years = Array.from(new Array(60), (val, index) => year - index)
    const upload_loading = this.props.user.upload_loading

    const roles = this.props.user.user_roles.list.data
    console.log('USER_ROLES', roles)

    const errorOnForm = Object.values(errors).every((value) => {
      if (value === true) {
        return true
      }

      return false
    })

    console.log('valid_form_data', errorOnForm)

    return (
      <React.Fragment>
        <section className="bg-auth-home">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12 m-auto">
                <div className="card shadow rounded border-0">
                  <div className="card-body">
                    <h4
                      className="card-title text-center text-uppercase"
                      style={{ color: '#124057' }}
                    >
                      {' '}
                      Rregjistro User te ri{' '}
                    </h4>
                    <p className="border-bottom pb-2" />

                    <form className="login-form mt-4">
                      <div className="row">
                        <section>
                          {/* Emri */}
                          <div className="col-md-12">
                            <div className="mb-4">
                              <label
                                className={
                                  errors.name
                                    ? 'form-label label_error'
                                    : 'form-label'
                                }
                              >
                                Emër <span className="text-danger">*</span>
                              </label>
                              <div className="form-icon position-relative">
                                <Icon
                                  icon="bx:bx-user"
                                  width="17"
                                  height="17"
                                  className="custom_icon"
                                />
                                <input
                                  type="text"
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        name: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        name: false,
                                      },
                                    }))
                                    setRegistrationValue('name', value)
                                  }}
                                  value={this.props.user.registration_data.name}
                                  className={
                                    errors.name
                                      ? 'form-control ps-5 input_error'
                                      : 'form-control ps-5'
                                  }
                                  placeholder="Emër"
                                  id="name"
                                  name="Emër"
                                  required=""
                                />
                              </div>
                            </div>
                          </div>

                          {/* Mbiemri */}
                          <div className="col-md-12">
                            <div className="mb-4">
                              <label
                                className={
                                  errors.surname
                                    ? 'form-label label_error'
                                    : 'form-label'
                                }
                              >
                                Miemër <span className="text-danger">*</span>
                              </label>
                              <div className="form-icon position-relative">
                                <Icon
                                  icon="bx:bx-user"                                  
                                  width="17"
                                  height="17"
                                  className="custom_icon"
                                />
                                <input
                                  type="text"
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        surname: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        surname: false,
                                      },
                                    }))
                                    setRegistrationValue('surname', value)
                                  }}
                                  value={
                                    this.props.user.registration_data.surname
                                  }
                                  className={
                                    errors.surname
                                      ? 'form-control ps-5 input_error'
                                      : 'form-control ps-5'
                                  }
                                  placeholder="Mbiemër"
                                  id="surname"
                                  name="Mbiemër"
                                  required={true}
                                />
                              </div>
                            </div>
                          </div>

                          {/* Qarku */}
                          <div className="col-md-12">
                            <div className="mb-4">
                              <label
                                className={
                                  errors.district
                                    ? 'form-label label_error'
                                    : 'form-label'
                                }
                              >
                                Qarku
                                <span className="text-danger">*</span>
                              </label>
                              <div className="form-icon position-relative">
                                <Icon
                                  icon="bx:bx-building-house"
                                  width="17"
                                  height="17"
                                  className="custom_icon"
                                />
                                <select
                                  className={
                                    errors.district
                                      ? 'form-select form-control ps-5 input_error'
                                      : 'form-select form-control ps-5'
                                  }
                                  placeholder="Zhgjidh Qarkun"
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        district: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        district: false,
                                      },
                                    }))
                                    setRegistrationValue('district', value)
                                  }}
                                  id="district"
                                  value={
                                    this.props.user.registration_data
                                      .district !== ''
                                      ? this.props.user.registration_data
                                          .district
                                      : undefined
                                  }
                                  aria-label="Default select example"
                                  defaultValue={'DEFAULT_DISTRICT'}
                                >
                                  <option value="DEFAULT_DISTRICT" disabled>
                                    {default_district_value}
                                  </option>

                                  {district_list.map((district, index) => {
                                    return (
                                      <option
                                        key={`district${index}.id`}
                                        value={district.name}
                                      >
                                        {district.name}
                                      </option>
                                    )
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                          
                          {/* Pozicioni Punes*/}
                          <div className="col-md-12">
                            <div className="mb-4">
                              <label
                                className={
                                  errors.job_position
                                    ? 'form-label label_error'
                                    : 'form-label'
                                }
                              >
                                Pozicioni i Punës
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <div className="form-icon position-relative">
                                <Icon
                                  icon="carbon:user-role"
                                  width="17"
                                  height="17"
                                  className="custom_icon"
                                />
                                <input
                                  type="text"
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        job_position: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        job_position: false,
                                      },
                                    }))
                                    setRegistrationValue('job_position', value)
                                  }}
                                  value={
                                    this.props.user.registration_data.job_position
                                  }
                                  className={
                                    errors.job_position
                                      ? 'form-control ps-5 input_error'
                                      : 'form-control ps-5'
                                  }
                                  placeholder="Pozicioni i Punës"
                                  id="job_position"
                                  name="Pozicioni i Punës"
                                  required={false}
                                />
                              </div>
                            </div>
                          </div>

                          {/* Telefoni */}
                          <div className="col-md-12">
                            <div className="mb-4">
                              <label
                                className={
                                  errors.telefono
                                    ? 'form-label label_error'
                                    : 'form-label'
                                }
                              >
                                Telefoni
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <div className="form-icon position-relative">
                                <Icon
                                  icon="ci:phone-outline"
                                  width="17"
                                  height="17"
                                  className="custom_icon"
                                />
                                <input
                                  type="number"
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        telefono: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        telefono: false,
                                      },
                                    }))
                                    setRegistrationValue('telefono', value)
                                  }}
                                  value={
                                    this.props.user.registration_data.telefono
                                  }
                                  className={
                                    errors.telefono
                                      ? 'form-control ps-5 input_error'
                                      : 'form-control ps-5'
                                  }
                                  placeholder="Telefoni"
                                  name="telefono"
                                  id="telefono"
                                  required={false}
                                />
                              </div>
                            </div>
                          </div>

                          {/* Roli */}
                          <div className="col-md-12">
                            <div className="mb-4">
                              <label
                                className={
                                  errors.role
                                    ? 'form-label label_error'
                                    : 'form-label'
                                }
                              >
                                Roli
                                <span className="text-danger">*</span>
                              </label>
                              <div className="form-icon position-relative">
                                <Icon
                                  icon="clarity:employee-line"
                                  width="17"
                                  height="17"
                                  className="custom_icon"
                                />
                                <select
                                  className={
                                    errors.role
                                      ? 'form-select form-control ps-5 input_error'
                                      : 'form-select form-control ps-5'
                                  }
                                  placeholder="Zhgjidh Rolin"
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        role: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        role: false,
                                      },
                                    }))
                                    setRegistrationValue('role', value)
                                  }}
                                  id="role"
                                  value={
                                    this.props.user.registration_data.role !==
                                    ''
                                      ? this.props.user.registration_data.role
                                      : undefined
                                  }
                                  aria-label="Default select example"
                                  defaultValue={'DEFAULT_ROLE'}
                                >
                                  <option value="DEFAULT_ROLE" disabled>
                                    {default_role_value}
                                  </option>

                                  {roles.map((role, index) => {
                                    return (
                                      <option
                                        key={`role${index}.id`}
                                        value={role.id}
                                      >
                                        {role.name}
                                      </option>
                                    )
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>

                          {/* Emaili */}
                          <div className="col-md-12">
                            <div className="mb-4">
                              <label
                                className={
                                  errors.email
                                    ? 'form-label label_error'
                                    : 'form-label'
                                }
                              >
                                E-mail<span className="text-danger">*</span>
                              </label>
                              <div className="form-icon position-relative">
                                {/* <PermIdentityIcon className="fea icon-sm custom_icon" /> */}
                                <Icon
                                  icon="clarity:email-line"
                                  width="17"
                                  height="17"
                                  className="custom_icon"
                                />
                                <input
                                  type="email"
                                  autoComplete="off"
                                  className={
                                    errors.email
                                      ? 'form-control ps-5 input_error'
                                      : 'form-control ps-5'
                                  }
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        email: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        email: false,
                                      },
                                    }))
                                    setRegistrationValue('email', value)
                                  }}
                                  placeholder="Email"
                                  name="email"
                                  required=""
                                />
                              </div>
                            </div>
                          </div>

                          {/*Paswordi*/}
                          <div className="col-md-12">
                            <div className="mb-4">
                              <label
                                className={
                                  errors.password
                                    ? 'form-label label_error'
                                    : 'form-label'
                                }
                              >
                                Password
                                <span className="text-danger">*</span>
                              </label>
                              <div className="form-icon position-relative">
                                <Icon
                                  icon="feather:key"
                                  width="17"
                                  height="17"
                                  className="custom_icon"
                                />
                                <input
                                  type="password"
                                  autoComplete="new-password"
                                  onChange={(event) => {
                                    const value = event.target.value
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      registration_data: {
                                        ...prevState.registration_data,
                                        password: value,
                                      },
                                      errors: {
                                        ...prevState.errors,
                                        password: false,
                                      },
                                    }))
                                    setRegistrationValue('password', value)
                                  }}
                                  value={
                                    this.props.user.registration_data.password
                                  }
                                  className={
                                    errors.password
                                      ? 'form-control ps-5 input_error'
                                      : 'form-control ps-5'
                                  }
                                  placeholder="Password"
                                  id="password"
                                  required=""
                                />
                              </div>
                            </div>
                          </div>
                        </section>

                        <div className="col-md-12">
                          <div className="d-grid">
                            <button
                              className="btn btn-primary border-button"
                              // disabled={!errorOnForm}
                              onClick={(e) => this.initUserRegistration(e)}
                            >
                              {' '}
                              RUAJ{' '}
                            </button>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="d-grid">
                            <p className="text-danger mt-3">
                              {' '}
                              * Fusha të detyrueshme{' '}
                            </p>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    code_error: state.user.code_error,
  }
}

const mapDispatchToProps = {
  setRegistrationValue,
  registerNewUser,
  setErrorMapTrue,
  setErrorMapFalse,
  setErrorCodeFalse,
  getUserRoles,
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)
