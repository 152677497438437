import {
    LOGIN,
    LOGOUT,
    INIT_RECOVER_PASSWORD_PROCEDURE,
    CONFIRM_NEW_PASSWORD,
    GET_CURRENT_USER,
    SET_REDIRECT_TO_LOGIN_FALSE,

} from "./actionTypes";

export const doLogin = (user) => {
    return {
        type: LOGIN,
        api: true,
        payload: user
    }
};

export const doLogout = () => {
    return {
        type: LOGOUT
    }
};


export const getCurrentUser = () => {
    return {
        type: GET_CURRENT_USER,
        api: true
    }
};


export const initRecoverPasswordProcedure = (email) => {
    return {
        type: INIT_RECOVER_PASSWORD_PROCEDURE,
        payload: {
            email
        },
        api: true
    }
}


export const confirmNewPassword = (data) => {
    return {
        type: CONFIRM_NEW_PASSWORD,
        payload: {
            data
        },
        api: true
    }
}

export const setRedirectToLoginFalse = () => {
    return {
        type: SET_REDIRECT_TO_LOGIN_FALSE
    }
}
