import {
    REGISTER_NEW_USER,
    SET_ERROR_CODE_FALSE,
    SET_ERROR_MAP_FALSE,
    SET_ERROR_MAP_TRUE,
    SET_MAP_CODE_SELECTED,
    // SET_NEW_UPDATE_VALUE,
    SET_REDIRECT_TO_HOME_FALSE,
    SET_REGISTRATION_VALUE,
    SET_SHAREHOLDER_VALUE,
    // UPLOAD_MEDIA,
    // UPLOAD_NEW_UPDATE,
    CLEAR_FORM,
    GET_USER_ROLES,
} from "./actionTypes";


// export const setNewUpdateValue = (field, value) => {
//     return {
//         type: SET_NEW_UPDATE_VALUE,
//         payload: {
//             field,
//             value
//         }
//     }
// }

export const setRegistrationValue = (field, value) => {
    return {
        type: SET_REGISTRATION_VALUE,
        payload: {
            field,
            value
        }
    }
}

export const setErrorMapFalse = () => {
    return {
        type: SET_ERROR_MAP_FALSE
    }
}

export const setErrorMapTrue = () => {
    return {
        type: SET_ERROR_MAP_TRUE
    }
}

export const registerNewUser = (data) => {
    return {
        type: REGISTER_NEW_USER,
        payload: data,
        api: true
    }
}

// export const uploadNewUpdate = (data) => {
//     const formData = new FormData();
//     formData.append('email', data.email);
//     formData.append('fileToUpload', data.file);

//     return {
//         type: UPLOAD_NEW_UPDATE,
//         payload: formData,
//         api: true
//     }
// }

export const setMapCodeSelected = (code) => {
    return {
        type: SET_MAP_CODE_SELECTED,
        payload: code
    }
}

export const setRedirectToHomeFalse = () => {
    return {
        type: SET_REDIRECT_TO_HOME_FALSE
    }
}

export const setErrorCodeFalse = () => {
    return {
        type: SET_ERROR_CODE_FALSE
    }
}

// export const uploadMedia = (file) => {
//     console.log("uploading file", file);
//     return {
//         type: UPLOAD_MEDIA,
//         payload: file
//     }
// }


export const clearForm = () => {
    return {
        type: CLEAR_FORM
    }
}

export const getUserRoles = () => {
    console.log(1)
    return {
        type: GET_USER_ROLES,
        api: true
    }
}