import {
    REGISTER_NEW_USER,
    GET_USER_ROLES,
} from "../store/user/actionTypes";

import {
    CONFIRM_NEW_PASSWORD,
    GET_CURRENT_USER,
    INIT_RECOVER_PASSWORD_PROCEDURE,
    LOGIN
} from "../store/auth/actionTypes";

import { GET_USER_DATA} from "../store/common/actionTypes";

// Akt Konstatimi
import {
    GET_DISTRICT_LIST,
    GET_MUNICIPALITY_LIST,
    GET_ADMINISTRATIVE_UNIT_LIST,
    GET_VILLAGE_LIST,
    UPLOAD_NEW_UPDATE,
    UPLOAD_WORKING_GROUP_SIGNATURE,
    UPLOAD_FAMILY_MEMBERS_SIGNATURE,
    REGISTER_ACT_OBSERVATION,
} from "../store/act_observation/actionTypes";


import {
    GET_ALL_USERS,
    GET_USER_ACT_OBSERVATIONS,
    GET_USER_ACT_OBSERVATION_DETAILS,
    GET_DOCUMENTS_URL,
} from "../store/basf/actionTypes";


const endpoints = {
    [REGISTER_NEW_USER]: {
        endpoint: state => `/user/signup`,
        method: 'POST',
        payload: (state, action) => action.payload
    },
    // [SET_ACT_OBSERVATION_VALUE]: {
    //     endpoint: state => `/user/addActAscertainment`,
    //     method: 'POST',
    //     payload: (state, action) => action.payload
    // },
    [REGISTER_ACT_OBSERVATION]: {
        endpoint: state => `/user/registerActObservation`,
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [LOGIN]: {
        endpoint: state => `/user/login`,
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [GET_CURRENT_USER]: {
        endpoint: (state, action) => `/user/getUserDataByToken`,
        method: 'GET',
    },
    [GET_USER_ROLES]: {
        endpoint: (state, action) => `/user/getUserRoles`,
        method: 'GET',
    },
    [GET_DISTRICT_LIST]: {
        endpoint: (state, action) => `/user/getDistricts`,
        method: 'GET',
    },    
    [GET_MUNICIPALITY_LIST]: {
        endpoint: (state, action) => `/user/getMunicipalities`,
        method: 'GET',
    },
    [GET_ADMINISTRATIVE_UNIT_LIST]: {
        endpoint: (state, action) => `/user/getAdministrativeUnits`,
        method: 'GET',
    },
    [GET_VILLAGE_LIST]: {
        endpoint: (state, action) => `/user/getVillages`,
        method: 'GET',
    },
    [GET_ALL_USERS]:  {
        endpoint: (state, action) => `/user/getUsers`,
        method: 'GET',
    },
    [GET_DOCUMENTS_URL]: {
        endpoint: (state, action) => `/user/getDocumentsUrl/${state.basf.params.operator_id}/${state.basf.params.act_observation_id}`,
        method: 'GET',
    },
    [INIT_RECOVER_PASSWORD_PROCEDURE]: {
        endpoint: state => `/user/recoveryPassword`,
        method: 'POST',
        payload: (state, action) => action.payload,
    },
    [CONFIRM_NEW_PASSWORD]: {
        endpoint: state => `/user/confirmPassword`,
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [GET_USER_DATA]: {
        endpoint: (state, action) => `user/getUserData`,
        mdethod: 'GET',
    },
    [UPLOAD_NEW_UPDATE]: {
        endpoint: state => `/user/upload_documents`,
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [UPLOAD_WORKING_GROUP_SIGNATURE]: {
        endpoint: state => `/user/upload_working_group_sign_document`,
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [UPLOAD_FAMILY_MEMBERS_SIGNATURE]: {
        endpoint: state => `/user/upload_family_members_sign_document`,
        method: 'POST',
        payload: (state, action) => action.payload
    },    
    [GET_USER_ACT_OBSERVATIONS]: {
        endpoint: (state, action) => `/user/get-act-observations/${state.auth.user.userID}`,
        method: 'GET'
    },
    [GET_USER_ACT_OBSERVATION_DETAILS]: {
        endpoint: state => `/user/get-act-observation-details/${JSON.stringify(state.basf.filter)}`,
        method: 'GET',
    },


    // [GET_PHOTOS_AND_VIDEOS_UPDATES]: {
    //     endpoint: state => `/user/get-updates-list/${state.media.filter.benchmark}`,
    //     method: 'GET'
    // },
    // [DISABLE_CLIENT]: {
    //     endpoint: state => `/client/updateStatus`,
    //     method: 'POST',
    //     payload: (state, action) => action.payload
    // },

};

export default endpoints;
