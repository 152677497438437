export const district_list = [
        { id: 1, name: 'Qarku i Elbasanit' },
        // { id: 2, name: 'Qarku i Vlorës' },
        // { id: 3, name: 'Qarku i Shkodrës' },
        // { id: 4, name: 'Qarku i Lezhës' },
        // { id: 5, name: 'Qarku i Kukësit' },
        // { id: 6, name: 'Qarku i Korçës' },
        // { id: 7, name: 'Qarku i Gjirokastrës' },
        // { id: 8, name: 'Qarku i Fierit' },
        // { id: 9, name: 'Qarku i Tiranës' },
        // { id: 10, name: 'Qarku i Durrësit' },
        // { id: 11, name: 'Qarku i Dibrës' },
        // { id: 12, name: 'Qarku i Beratit' },
];


export const administrative_unit_list = [
    { id: 1, name: 'Qarku i Elbasanit' },
    { id: 2, name: 'Qarku i Vlorës' },
    // { id: 3, name: 'Qarku i Shkodrës' },
    // { id: 4, name: 'Qarku i Lezhës' },
    // { id: 5, name: 'Qarku i Kukësit' },
    // { id: 6, name: 'Qarku i Korçës' },
    // { id: 7, name: 'Qarku i Gjirokastrës' },
    // { id: 8, name: 'Qarku i Fierit' },
    // { id: 9, name: 'Qarku i Tiranës' },
    // { id: 10, name: 'Qarku i Durrësit' },
    // { id: 11, name: 'Qarku i Dibrës' },
    // { id: 12, name: 'Qarku i Beratit' },
];


export const origin_list = [
    {id: 1, name: 'Shqiptare', code: 'AL'},
    {id: 2, name: 'Kosove', code: 'KS'},
    {id: 3, name: 'Maqedonia e Veriut', code: 'MQV'},
    {id: 4, name: 'Lugina e Presheves', code: 'LP'},
    {id: 5, name: 'Arberesh', code: 'ARB'},
];



export const area_list = [
    {id: 1, name: 'Prodhim'},
    {id: 2, name: 'Industria përpunuese'},
    {id: 3, name: 'Industria automobile'},
    {id: 4, name: 'Inteligjenca artificiale'},
    {id: 5, name: 'Shërbime edukimi'},
    {id: 6, name: 'Shërbime për biznesin'},
    {id: 7, name: 'Ndërtim'},
    {id: 8, name: 'Tregti produkte ushqimore'},
    {id: 9, name: 'Shërbime financiare'},
    {id: 10, name: 'Aktivitete të arkitekturës dhe inxhinierisë'},
    {id: 11, name: 'Pasuritë e patundshme'},
    // {id: 12, name: 'Tjetër (specifiko)'},
];

export const legal_status_list = [
    {id: 1, name: 'SH.P.K., Shoqëri me përgjegjësi të kufizuara'},
    {id: 2, name: 'Sh.a. Shoqeri Aksionare'},
    {id: 3, name: 'Person Fizik'},
    {id: 4, name: 'Inteligjenca artificiale'},
    // {id: 5, name: 'Tjeter plotëso'},
];


export const education_list = [
    {id: 1, name: 'I ulët'},
    {id: 2, name: 'I mesëm'},
    {id: 3, name: 'I lartë'},
    {id: 4, name: 'Phd'},
];

export const apartment_condition_values = [
    {id:1, name: 'Të gjitha', value: 'all'},
    {id:2, name: 'Gjendje shumë e mirë', value: 'Gjendje shumë e mirë'},
    {id:3, name: 'Gjendje e mirë', value: 'Gjendje e mirë'},
    {id:4, name: 'E përshtatshme për jetesë', value: 'E përshtatshme për jetesë'},
    {id:5, name: 'E papërshtatshme për jetesë', value: 'E papërshtatshme për jetesë'},
    {id:6, name: 'Në ndërtim', value: 'Në ndërtim'}
  ];