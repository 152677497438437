

import { combineReducers  } from 'redux';



import auth from './auth/reducer';
import user from './user/reducer';
import act_observation from './act_observation/reducer';

// import distributor from './distributor/reducer';
import media from "./common/reducer";
import basf from './basf/reducer';


const rootReducer = combineReducers({
    auth,
    user,
    act_observation,
    // distributor,
    media,
    basf
});

export default rootReducer;
