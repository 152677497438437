import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

import './footer.css'

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ position: 'relative' }}>
          <footer className="footer footer-bar">
            <div className="container text-center">
              <div className="row align-items-center">
                <div className="col-sm-12">
                  <div className="text-sm-center">
                    <p className="mb-0">
                      Të drejtat e rezervuara © {new Date().getFullYear()}
                    </p>
                  </div>
                </div>

                
                {/* <div className="col-sm-6">
                  <div className="text-sm-start">
                    <p className="mb-0">
                      Për çdo pyetje mos hezitoni të na kontaktoni në{' '}
                      <a
                        href="mailto:info@addw.al"
                        className="text_green"
                        target="_blank"
                        title="disiscriverti"
                      >
                        info@addw.al
                      </a>
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </footer>
        </div>
        <a onClick={() => this.scrollToTop()} id="back-to-top" className="btn btn-icon border-button back-to-top">
          <ArrowUpwardIcon className="arrow" />
        </a>
      </React.Fragment>
    )
  }
}

export default withRouter(Footer)
