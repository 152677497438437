import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import ReactPlayer from "react-player";
import {Icon} from '@iconify/react';
import Cookies from "js-cookie";
import DotLoader from "react-spinners/DotLoader";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { Button, Col, Row } from 'reactstrap'
import SweetAlert from 'react-bootstrap-sweetalert'
import * as XLSX from 'xlsx'
import {FaCalendarAlt} from "react-icons/fa";

import {getCurrentUser} from "../store/auth/actions";
import {
  getAllBenchmarks, 
  setBenchmarkFilterValue,
  getPhotosAndVideosUpdates, 
  getUserData
} from "../store/common/actions";
import {capitalize, clearApiBaseUrl, formatUpdateCreationDate} from "../assets/helpers/helpers";
import {
    getUserActObservations,
    getAllUsers,
    getUserActObservationDetails,
    setActObservationFilterValue,
    clearActObservationDetails,
    getClientsForBasfByDistributor,
    getBasfDistributors,
    getDistributorsCompetenceAreas,
    setBasfSelectValue,
    getClientDetailsForBasfPreview,
    clearClientDetails,
    clearClients,
    clearDistributors,
    getDistributorName,
    getDocumentsUrl,
    setActObservationValues
} from "../store/basf/actions";

import '../assets/css/custom.css';


import {
  apartment_condition_values
} from '../utils/constants'


class BasfSalesProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dashboard_open: true,
      profile_open: false,
      akt_konstatimi_open: false,
      report_open: false,
      users_open: false,
      settings_open: false,
      register_new_user_open: false,

      // distributors_open: false,
      // updates_open: false,
      // distributors_clients_table: false,
      params: props.basf.params,
      errors: {
        area: false,
        distributor: false,
      },

      default_user_value: 'Zgjidh operatorin ...',
      selected_operator: 'Zgjidh operatorin ...',
      default_apartment_condtion: 'Gjëndjen e banesës',
      select_apartment_condition: 'Gjëndjen e banesës',
      filter: props.media.filter,
      show_eye_icon: true,
      show_total_act_observation_details: false,
      edit_act_observation_modal: false,

      start_date: '',
      end_date: '',
      loading: false,
      show_alert: false,
      act_observation_details: [],
      isActive: false,

      selected_act_observation: null,
    }
  }


  componentDidMount() {
    const self = this
    const {
      getAllBenchmarks,
      media,
      getUserData,
      getUserActObservations,
      basf,
      getAllUsers,
    } = self.props

    // if (self.props.basf.user_act_observations.list.data.length === 0 && self.props.basf.user_act_observations.list.loading === false) {
    //   const userID = Cookies.get("id");
    //   // console.log("USER_ID", userID);
    //   getUserActObservations(userID);
    // }
    const userID = Cookies.get('id')
    getUserActObservations(userID)

    // getUserData()

    // if (media.benchmarks.list.data.length === 0 && media.benchmarks.list.loading === false) {
    //     getAllBenchmarks();
    // }

    if (basf.users.list.data.length === 0 &&basf.users.list.loading === false) {
      getAllUsers()
    }
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.updates_open !== this.state.updates_open) {
      const {
        setBenchmarkFilterValue,
        getPhotosAndVideosUpdates,
        media,
        getUserData,
      } = this.props
      if (media.filter.benchmark === '') {
        setBenchmarkFilterValue(
          'benchmark',
          media.benchmarks.list.data[0]?.campo_benchmark
        )
        getPhotosAndVideosUpdates()
        getUserData()
      }
    }
  }



  handleToggle = (type) => {
    const { clearClientDetails } = this.props
    const client_detail = this.props.basf.client_detail

    if (client_detail !== null) {
      clearClientDetails()
    }

    if (type === 'dashboard') {
      this.setState({
        dashboard_open: true,
        profile_open: false,
        akt_konstatim_open: false,
        report_open: false,
        users_open: false,
        settings_open: false,
      })
    }

    if (type === 'profile') {
      this.setState({
        dashboard_open: false,
        profile_open: true,
        akt_konstatim_open: false,
        report_open: false,
        users_open: false,
        settings_open: false,
      })
    }

    if (type === 'akt_konstatim') {
      // this.setState({
      //     dashboard_open: false,
      //     profile_open: false,
      //     akt_konstatim_open: true,
      //     report_open: false,
      //     users_open: false,
      //     settings_open: false,
      // })

      this.props.history.push('akt-konstatim')
    }

    if (type === 'report') {
      this.setState({
        dashboard_open: false,
        profile_open: false,
        akt_konstatim_open: false,
        report_open: true,
        users_open: false,
        settings_open: false,
      })
    }

    if (type === 'users') {
      this.setState({
        dashboard_open: false,
        profile_open: false,
        akt_konstatim_open: false,
        report_open: false,
        users_open: true,
        settings_open: false,
      })
    }

    if (type === 'settings') {
      this.setState({
        dashboard_open: false,
        profile_open: false,
        akt_konstatim_open: false,
        report_open: false,
        users_open: false,
        settings_open: true,
      })
    }

    if (type === 'create_new_user') {
      this.setState({
        dashboard_open: false,
        profile_open: false,
        akt_konstatim_open: false,
        report_open: false,
        users_open: false,
        settings_open: false,
        register_new_user_open: true,
      })

      this.props.history.push('/krijo-perdorues')
    }
  }

  
  showDistributorDetail = () => {
    this.setState({
      single_client_detail: true,
      distributors_clients_table: false,
      profile_open: false,
      report_open: false,
      distributors_open: true,
      updates_open: false,
    })
  }



  initGetAreas = () => {
    // const { getDistributorsCompetenceAreas, clearClients, clearClientDetails } = this.props
    // this.handleToggle('distributors')
    // clearClientDetails()
    // clearClients()
    // if (
    //   this.props.basf.areas.list.data.length === 0 &&
    //   this.props.basf.areas.list.loading === false
    // ) {
    //   getDistributorsCompetenceAreas()
    // }
  }



  handleResearch = (e) => {
    e.preventDefault()
    if (this.handleResearchValidation()) {
      const { getClientsForBasfByDistributor, getDistributorName } = this.props
      this.setState({
        distributors_clients_table: true,
      })
      getDistributorName(this.props.basf.distributors.list.data)
      getClientsForBasfByDistributor()
    }
  }


  getClientDetailForBasf = (client) => {
    const { getClientDetailsForBasfPreview } = this.props
    this.showDistributorDetail()
    getClientDetailsForBasfPreview(client)
  }


  handleResearchValidation = () => {
    const { params, errors } = this.state

    let form_is_valid = true
    if (!params.area_id.trim()) {
      form_is_valid = false
      errors.area = true
    }

    if (!params.distributor_id.trim()) {
      form_is_valid = false
      errors.distributor = true
    }

    this.setState((prevState) => ({
      ...prevState,
      errors: errors,
    }))

    return form_is_valid
  }


  handleChangeOperator = (event) => {
    const self = this;
    const {setActObservationFilterValue} = self.props;
    self.setState({
      selected_operator: event.target.value
    })
    setActObservationFilterValue('user_id', event.target.value);
  }

  handleChangeApartmentCondition = (event) => {
    const self = this;
    const {setActObservationFilterValue} = self.props;
    self.setState({
      select_apartment_condition: event.target.value
    })
    setActObservationFilterValue('apartament_condition', event.target.value);
  }

  


  hide_act_obs_alert = () => {
    const self = this;
    self.clear_act_observation_details();
  }


  clear_act_observation_details = () => {
    const self = this;
    const {clearActObservationDetails} = self.props;
    clearActObservationDetails();
  }

  // hide_act_observation_details = () => {
  //   const self = this
  //   self.setState({
  //     show_total_act_observation_details: false,
  //     show_eye_icon: true,
  //   })
  // }



  // show_act_observation_details_backup = () => {
  //   const self = this
  //   self.setState({
  //     show_total_act_observation_details: true,
  //     show_eye_icon: false,
  //   })
  //   const {
  //     getDistributorsCompetenceAreas,
  //     clearClients,
  //     clearClientDetails,
  //     getUserActObservationDetails,
  //   } = self.props
  //   // this.handleToggle('distributors');
  //   // clearClientDetails();
  //   // clearClients();
  //   // if(this.props.basf.areas.list.data.length === 0 && this.props.basf.areas.list.loading === false ) {
  //   //   getUserActObservationDetails();
  //   // }
  //   const userID = Cookies.get('id')
  //   const data = {
  //     userID: userID,
  //     start_date: self.state.start_date,
  //     end_date: self.state.end_date,
  //     type: 'total',
  //   }
  //   console.log('DATA', data)

  //   getUserActObservationDetails(data)
  // }


  showActObservationDetails = () => {
    const self = this;
    self.setState({
      show_total_act_observation_details: true,
      show_eye_icon: false,
    })
    const {
      getUserActObservationDetails,
      setActObservationFilterValue, 
      clearActObservationDetails,
           
    } = self.props

    const userID = Cookies.get('id')
    if(self.props.auth.user.role.toLowerCase() === 'o'){
      setActObservationFilterValue('user_id', userID);
    }

    clearActObservationDetails();
    getUserActObservationDetails();

    if(self.props.basf.user_act_observation_details.list.data.length > 0) self.setState({act_observation_details: self.props.basf.user_act_observation_details.list.data})

  }


  setStartDate = (date) => {
    console.log("START_TIME", date);
    const self = this;
    const {setActObservationFilterValue} = this.props;
    self.setState({
      start_date: date
    });

    const start_date_formated = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    setActObservationFilterValue('start_time', start_date_formated);
    self.setState({show_alert: true})
  }


  setEndDate = (date) => {
    console.log("END_TIME", date);
    const self = this;
    const {setActObservationFilterValue} = this.props;
    self.setState({
      end_date: date
    })

    const end_date_formated = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    setActObservationFilterValue('end_time', end_date_formated);
  }
  

  // closeModal = () => {
  //   const self = this
  //   self.setState({
  //     show_act_obs_dettails_alert: false,
  //   })
  // }

  exportToExcel = (data) => {
    const self = this;
    const {basf} = self.props;
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    XLSX.writeFile(workbook, `akt_konstatime.xlsx`);
    self.setState({show_alert: false})

    self.clear_act_observation_details();
  }


  setIsActive = () => {
    const self = this;
    self.setState({
      isActive: !self.state.isActive,
    });
  }

  handleAktObservationEdit = (ak) => {
    console.log("Selected AK", ak)
    console.log("AK_LIST", this.props.basf.user_act_observation_details.list.data[ak])

    const self = this;
    // console.log("AK", ak);
    // window.open('http://localhost:3003/public/uploads/test.zip', '_blank');
    self.setState({edit_act_observation_modal: true, selected_act_observation: this.props.basf.user_act_observation_details.list.data[ak]});
  }


  downloadDocuments = (item) => {
    console.log("Download_Documents", item)
    const self = this;
    const {getDocumentsUrl, setBasfSelectValue} = self.props;
    setBasfSelectValue('operator_id', item.Id);
    setBasfSelectValue('act_observation_id', item.act_observation_id);
    getDocumentsUrl();

    // getDocumentsUrl().then((response) => {
    //   console.log("Download Documents", response);
    //   // window.open('http://localhost:3003/public/uploads/test.zip', '_blank');
    //   window.open('https://appshssh.onisystems.net/uploads/test.zip')
    // })

  }


  closeEditActObservationModal = () => {
    const self = this;
    self.setState({
      edit_act_observation_modal: false,
    });
  }


  render() {
    const {
      dashboard_open,
      profile_open,
      akt_konstatim_open,
      report_open,
      users_open,
      settings_open,
      register_new_user_open,
      show_total_act_observation_details,
      show_eye_icon,
      default_user_value,
      selected_operator,
      default_apartment_condtion,
      select_apartment_condition,
      distributors_open,
      updates_open,
      distributors_clients_table,
      single_client_detail,
      errors,
      start_date, 
      end_date,
      show_alert,
      act_observation_details,
      isActive,
      edit_act_observation_modal,
      selected_act_observation
    } = this.state

    const {
      setActObservationValues,

      setBasfSelectValue,
      currentUser,
      getUserActObservations,
      getAllUsers,
      auth,
      getBasfDistributors,
      clearDistributors,
      setBenchmarkFilterValue,
      getDistributorName,
      getPhotosAndVideosUpdates,
    } = this.props

    const user_act_observations = this.props.basf.user_act_observations.list.data
    const users = this.props.basf.users.list.data
    const areas = this.props.basf.areas.list.data
    const distributors = this.props.basf.distributors.list.data
    const clients = this.props.basf.clients.list.data
    const client_detail = this.props.basf.client_detail
    const benchmarks = this.props.media.benchmarks.list.data
    const distributor_name = this.props.basf.distributor_name
    const medias = this.props.media.content.data
    const imgBaseUrl = process.env.REACT_APP_API_HOST
    const upload_loading = this.props.basf.upload_loading

    // const loading = this.props.basf.users.list.loading
    const loading = this.props.basf.user_act_observation_details.list.loading

    const user_role = auth.user.role

    // console.log("USER_ROLE", user_role);

    // if (auth.token === null) {
    //     return <Redirect to='/login'/>
    // }

    return (
      <React.Fragment>
        <section>
          <div className="container">
            <nav aria-label="breadcrumb" className="d-inline-block">
              <ul className="breadcrumb mb-0">
                <li className="breadcrumb-item" style={{ marginRight: '5px' }}>
                  <a onClick={() => this.props.history.push('/')}>Kreu</a>
                </li>

                {dashboard_open && (
                  <li
                    className={
                      dashboard_open
                        ? 'breadcrumb-item active'
                        : 'breadcrumb-item'
                    }
                  >
                    <a onClick={() => this.handleToggle('dashboard')}>
                      Raporte
                    </a>
                  </li>
                )}

                {akt_konstatim_open && (
                  <li
                    className={
                      akt_konstatim_open
                        ? 'breadcrumb-item active'
                        : 'breadcrumb-item'
                    }
                  >
                    <a onClick={() => this.handleToggle('akt_konstatimi')}>
                      Shto Akt Konstatim
                    </a>
                  </li>
                )}

                {profile_open && (
                  <li
                    className={
                      profile_open
                        ? 'breadcrumb-item active'
                        : 'breadcrumb-item'
                    }
                  >
                    <a onClick={() => this.handleToggle('profile')}>
                      Profili i perdoruesit
                    </a>
                  </li>
                )}
{/* 
                {report_open && (
                  <li
                    className={
                      report_open ? 'breadcrumb-item active' : 'breadcrumb-item'
                    }
                  >
                    <a onClick={() => this.initGetAreas()}>Lista Akt Konstatimeve</a>  
                  </li>
                )} */}

                {users_open && (
                  <li
                    className={
                      users_open ? 'breadcrumb-item active' : 'breadcrumb-item'
                    }
                  >
                    <a onClick={() => this.initGetAreas()}>Userat</a>
                  </li>
                )}
{/* 
                {settings_open && (
                  <li
                    className={
                      settings_open
                        ? 'breadcrumb-item active'
                        : 'breadcrumb-item'
                    }
                  >
                    <a onClick={() => this.initGetAreas()}>Settings</a>
                  </li>
                )} */}

                {register_new_user_open && (
                  <li
                    className={
                      register_new_user_open
                        ? 'breadcrumb-item active'
                        : 'breadcrumb-item'
                    }
                  >
                    <a onClick={() => this.handleToggle('create_new_user')}>
                      Krijo user të ri{' '}
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </section>

        <section className="d-table w-100 mt-3">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mt-3 mb-3">
                <div className="card public-profile border-0 rounded shadow">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-lg-2 text-center profile-icon">
                        <i
                          className="uil uil-user-circle"
                          style={{ color: '#124057' }}
                        />
                      </div>

                      <div className="col-lg-10 col-md-9">
                        <div className="row align-items-end">
                          <div className="col-md-7 text-md-start text-center mt-4 mt-sm-0">
                            <h3 className="title mb-0">
                              {currentUser.name} {currentUser.surname}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-3">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 d-lg-block">
                <div className="sidebar sticky-bar p-4 rounded shadow">
                  <div className="widget">
                    <ul
                      className="list-unstyled sidebar-nav mb-0"
                      id="navmenu-nav"
                    >
                      <li
                        className={
                          dashboard_open
                            ? 'navbar-item account-menu px-0 active'
                            : 'navbar-item account-menu px-0'
                        }
                      >
                        <a
                          onClick={() => this.handleToggle('dashboard')}
                          className="navbar-link d-flex rounded shadow align-items-center py-2 px-4 cursor_pointer"
                        >
                          <span className="h4 mb-0">
                            <i className="uil uil-create-dashboard" />
                          </span>
                          <h6 className="mb-0 ms-2 custom_font">Raporte</h6>
                        </a>
                      </li>

                      <li
                        className={
                          akt_konstatim_open
                            ? 'navbar-item account-menu px-0 active'
                            : 'navbar-item account-menu px-0'
                        }
                      >
                        <a
                          onClick={() => this.handleToggle('akt_konstatim')}
                          className="navbar-link d-flex rounded shadow align-items-center py-2 px-4 cursor_pointer"
                        >
                          <span className="h4 mb-0">
                            <i className="uil uil-database" />
                          </span>
                          <h6 className="mb-0 ms-2 custom_font">
                            Shto Akt Konstatim
                          </h6>
                        </a>
                      </li>

                      <li
                        className={
                          profile_open
                            ? 'navbar-item account-menu px-0 active'
                            : 'navbar-item account-menu px-0'
                        }
                      >
                        <a
                          onClick={() => this.handleToggle('profile')}
                          className="navbar-link d-flex rounded shadow align-items-center py-2 px-4 cursor_pointer"
                        >
                          <span className="h4 mb-0">
                            <i className="uil uil-user-circle" />
                          </span>
                          <h6 className="mb-0 ms-2 custom_font">
                            Profili i perdoruesit
                          </h6>
                        </a>
                      </li>

                      {/* Settings */}
                      {/* <li
                            className={
                              settings_open
                                ? 'navbar-item account-menu px-0 active'
                                : 'navbar-item account-menu px-0'
                            }
                          >
                            <a
                              onClick={() => this.handleToggle('settings')}
                              className="navbar-link d-flex rounded shadow align-items-center py-2 px-4 cursor_pointer"
                            >
                              <span className="h4 mb-0">
                                <i className="uil uil-setting" />
                              </span>
                              <h6 className="mb-0 ms-2 custom_font">
                                Settings
                              </h6>
                            </a>
                          </li> */}

                      {user_role === 'A' && (
                        <>
                         
                          <li
                            className={
                              users_open
                                ? 'navbar-item account-menu px-0 active'
                                : 'navbar-item account-menu px-0'
                            }
                          >
                            <a
                              onClick={() => this.handleToggle('users')}
                              className="navbar-link d-flex rounded shadow align-items-center py-2 px-4 cursor_pointer"
                            >
                              <span className="h4 mb-0">
                                <i className="uil uil-users-alt" />
                              </span>
                              <h6 className="mb-0 ms-2 custom_font">Userat</h6>
                            </a>
                          </li>

                          {/* <li
                            className={
                              register_new_user_open
                                ? 'navbar-item account-menu px-0 active'
                                : 'navbar-item account-menu px-0'
                            }
                          >
                            <a
                              onClick={() =>
                                this.handleToggle('create_new_user')
                              }
                              className="navbar-link d-flex rounded shadow align-items-center py-2 px-4 cursor_pointer"
                            >
                              <span className="h4 mb-0">
                                <i className="uil uil-user" />
                              </span>
                              <h6 className="mb-0 ms-2 custom_font">
                                Shto user te ri
                              </h6>
                            </a>
                          </li> */}

                          {/* <li
                            className={
                              report_open
                                ? 'navbar-item account-menu px-0 active'
                                : 'navbar-item account-menu px-0'
                            }
                          >
                            <a
                              onClick={() => this.handleToggle('report')}
                              className="navbar-link d-flex rounded shadow align-items-center py-2 px-4 cursor_pointer"
                            >
                              <span className="h4 mb-0">
                                <i className="uil uil-analytics" />
                              </span>
                              <h6 className="mb-0 ms-2 custom_font">Lista Akt Konstatimeve</h6>
                            </a>
                          </li> */}

                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>

              {dashboard_open && (
                <>
                  <div className="col-lg-8 col-12">
                    <div className="pb-4">
                      <div className="row">
                        <div className="col-lg-6 col-sm-6">
                          <div className="card-stats card">
                            <div className="custom-card-body rounded shadow">
                              <div className="row">
                                {/* <div class="col-5">
                                        <div class="icon-big text-center icon-warning">
                                          <i class="nc-icon nc-chart text-warning"></i>
                                        </div>
                                      </div> */}
                                <div className="col-12">
                                  <div className="numbers">
                                    <p className="card-category">
                                      AKT KONSTATIME SOT
                                    </p>
                                    <h4 className="card-title">
                                      {
                                        this.props.basf.user_act_observations
                                          .list.data.Today
                                      }
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-sm-6">
                          <div className="card-stats card">
                            <div className="custom-card-body rounded shadow">
                              <div className="row">
                                <div className="col-12">
                                  <div className="numbers">
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <p className="card-category">
                                        AKT KONSTATIME TOTAL
                                      </p>

                                      {/* {show_eye_icon && (
                                        <Icon
                                          icon="mdi:eye"
                                          width="22"
                                          height="22"
                                          className="fea icon-ex-md  card-category mb-4 cursor_pointer"
                                          onClick={(total) => {
                                            this.show_act_observation_details(
                                              total
                                            )
                                          }}
                                        />
                                      )}

                                      {!show_eye_icon && (
                                        <Icon
                                          // icon="material-symbols:close"
                                          icon="carbon:close-filled"
                                          width="22"
                                          height="22"
                                          className="fea icon-ex-md  card-category mb-4 cursor_pointer"
                                          onClick={(total) => {
                                            this.hide_act_observation_details(
                                              total
                                            )
                                          }}
                                        />
                                      )} */}
                                    </div>

                                    <h4 className="card-title">
                                      {
                                        this.props.basf.user_act_observations.list.data.Total
                                      }
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="accordion rounded shadow"
                        onClick={() => this.setIsActive(!isActive)}
                        style={{ cursor: 'pointer' }}
                      >
                        <label className="custom_font align-items-center py-2 px-4">
                          FILTRO AKT KONSTATIME{' '}
                        </label>

                        {!isActive && (
                          <Icon
                            icon="mdi:eye"
                            width="22"
                            height="22"
                            className="fea icon-ex-md  card-category cursor_pointer"
                          />
                        )}

                        {isActive && (
                          <Icon
                            icon="mdi:hide"
                            width="22"
                            height="22"
                            className="fea icon-ex-md  card-category cursor_pointer"
                          />
                        )}
                      </div>

                      {isActive && (
                        <>
                          <div className="mt-4">
                            <div className="row">
                              {this.props.auth.user.role.toLowerCase() ===
                                'a' && (
                                <div className="col-md-4 mt-3">
                                  <div className="mb-4">
                                    <label
                                      className={
                                        errors.operator
                                          ? 'form-label label_error'
                                          : 'form-label'
                                      }
                                    >
                                      Zgjidh Operatorin
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="form-icon position-relative">
                                      <Icon
                                        icon="bx:bx-user"
                                        width="17"
                                        height="17"
                                        className="custom_icon"
                                      />
                                      <select
                                        className={
                                          errors.operator
                                            ? 'form-select form-control ps-5 input_error'
                                            : 'form-select form-control ps-5'
                                        }
                                        placeholder="Zhgjidh Operatorin"
                                        selected={selected_operator}
                                        onChange={this.handleChangeOperator}
                                        id="operators"
                                        value={selected_operator}
                                        aria-label="DEFAULT_USERr"
                                        defaultValue={'DEFAULT_USER'}
                                      >
                                        <option value="DEFAULT_USER">
                                          {default_user_value}
                                        </option>

                                        {this.props.basf.users.list.data
                                          .length > 0 &&
                                          this.props.basf.users.list.data.map(
                                            (user, index) => {
                                              return (
                                                <option
                                                  key={`user${index}.id`}
                                                  value={user.id}
                                                >
                                                  {user.name} {user.surname}
                                                </option>
                                              )
                                            }
                                          )}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="col-md-4 mt-3">
                                <div className="mb-4">
                                  <label
                                    className={
                                      errors.start_date
                                        ? 'form-label label_error'
                                        : 'form-label'
                                    }
                                  >
                                    Date Start{' '}
                                    <span className="text-danger">*</span>
                                  </label>

                                  <div>
                                    <label
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                      }}
                                    >
                                      <DatePicker
                                        placeholderText="Date start"
                                        className={'form-control ps-4'}
                                        selected={start_date}
                                        // onSelect={(date) => this.handleStartDateSelect(date)}
                                        onChange={(date) =>
                                          this.setStartDate(date)
                                        }
                                        // customInput={<CustomInput />}
                                        dateFormat="dd/MM/yyyy"
                                        todayButton="SOT"
                                      />
                                      {/* <span className="form-icon position-relative">
                                      <Icon
                                        icon="bx:bx-calendar"
                                        width="17"
                                        height="17"
                                        className="custom_icon"
                                      />
                                    </span> */}
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-4 mt-3 ml-4">
                                <div className="mb-4">
                                  <label
                                    className={
                                      errors.date_end
                                        ? 'form-label label_error'
                                        : 'form-label'
                                    }
                                  >
                                    Date End{' '}
                                    <span className="text-danger">*</span>
                                  </label>

                                  <div>
                                    <label
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                      }}
                                    >
                                      <DatePicker
                                        placeholderText="Date end"
                                        className={'form-control ps-4'}
                                        selected={end_date}
                                        onChange={(date) =>
                                          this.setEndDate(date)
                                        }
                                        dateFormat="dd/MM/yyyy"
                                        todayButton="SOT"
                                      />
                                      {/* <span className="form-icon position-relative">
                                      <Icon
                                        icon="bx:bx-calendar"
                                        width="17"
                                        height="17"
                                        className="custom_icon"
                                      />
                                    </span> */}
                                    </label>
                                  </div>
                                </div>
                              </div>


                              <div className="col-md-4 mt-3">
                                  <div className="mb-4">
                                    <label
                                      className={
                                        errors.apartment_condition
                                          ? 'form-label label_error'
                                          : 'form-label'
                                      }
                                    >
                                      Zgjidh gjëndjen e banesës
                                      <span className="text-danger">*</span>
                                    </label>
                                    <div className="form-icon position-relative">
                                      <Icon
                                        icon="bx:bx-user"
                                        width="17"
                                        height="17"
                                        className="custom_icon"
                                      />
                                      <select
                                        className={
                                          errors.apartment_condition
                                            ? 'form-select form-control ps-5 input_error'
                                            : 'form-select form-control ps-5'
                                        }
                                        placeholder="Zgjidh gjëndjen e banesës"
                                        selected={selected_operator}
                                        onChange={this.handleChangeApartmentCondition}
                                        id="apartment_condition"
                                        value={select_apartment_condition}
                                        aria-label="DEFAULT_APART_COND"
                                        defaultValue={'DEFAULT_APART_COND'}
                                      >
                                        <option value="DEFAULT_APART_COND">
                                          {default_apartment_condtion}
                                        </option>

                                        {apartment_condition_values.map(
                                            (item, index) => {
                                              return (
                                                <option
                                                  key={item.id}
                                                  value={item.value}
                                                >
                                                  {item.name}
                                                </option>
                                              )
                                            }
                                          )}
                                      </select>
                                    </div>
                                  </div>
                                </div>

                              

                              

                              {
                                this.props.basf.user_act_observation_details.list.data.length === 0 
                                ? (
                                    <div className="text-center custom_font">
                                      Nuk ka të dhëna
                                    </div>
                                  ) 
                                : (
                                    <Col xl={12} lg={12} sm={12} className="mb-2">
                                      <SweetAlert
                                        title={`Akt konstatime - ${this.props.basf.user_act_observation_details.list.data[0].Operatori}`}
                                        showCancel
                                        style={{ width: '40em !important' }}
                                        cancelBtnText="Mbyll"
                                        confirmBtnText="Shkarko ne Excel "
                                        cancelBtnBsStyle="light"
                                        confirmBtnBsStyle="primary"
                                        onConfirm={() => {
                                          this.exportToExcel(
                                            this.props.basf
                                              .user_act_observation_details.list
                                              .data
                                          )
                                        }}
                                        onCancel={() => this.hide_act_obs_alert()}
                                        type={'controlled'}
                                        dependencies={[]}
                                      >
                                        {(renderProps) => (
                                          <>
                                            <div className="col-lg-12 col-sm-12">
                                              <div className="table-responsive bg-white shadow rounded">
                                                <table className="table mb-0 table-center invoice-tb">
                                                  <thead className="bg-light"title="Akt Konstatime Totale">
                                                    <tr>
                                                      <th scope="col"className="border-bottom text-start">Num</th>
                                                      <th scope="col" className="border-bottom text-start">Operatori</th>
                                                      <th scope="col" className="border-bottom text-start">Emri</th>
                                                      <th scope="col" className="border-bottom text-start">Mbiemri</th>
                                                      <th scope="col" className="border-bottom text-start">Atësia</th>
                                                      <th scope="col" className="border-bottom text-start">Karta ID</th>
                                                      <th scope="col" className="border-bottom text-start">Qarku</th>
                                                      <th scope="col" className="border-bottom text-start">Bashkia</th>
                                                      <th scope="col" className="border-bottom text-start">Njësia Administrative</th>
                                                      <th scope="col" className="border-bottom text-start">Fshati</th>
                                                      {/* <th scope="col" className="border-bottom text-start">FamiljaPrezenteNeBanese</th>
                                                      <th scope="col" className="border-bottom text-start">KaTokëBujqësore</th>
                                                      <th scope="col" className="border-bottom text-start">TëArdhuratNgaTokaApoBlektoria</th>
                                                      <th scope="col" className="border-bottom text-start">NdihmëEkonomike</th>
                                                      <th scope="col" className="border-bottom text-start">PensionInvaliditeti</th>
                                                      <th scope="col" className="border-bottom text-start">PensionPleqërie</th>
                                                      <th scope="col" className="border-bottom text-start">Akpa</th>
                                                      <th scope="col" className="border-bottom text-start">LlojiPunësimit</th>
                                                      <th scope="col" className="border-bottom text-start">MjetiMotorrik</th>
                                                      <th scope="col" className="border-bottom text-start">TipiBanesës</th>
                                                      <th scope="col" className="border-bottom text-start">GjëndjaBanesës</th>
                                                      <th scope="col" className="border-bottom text-start">Kushtet_e_familjes</th>
                                                      <th scope="col" className="border-bottom text-start">FirmosurNgaTëGjithëAnëtarët</th>
                                                      <th scope="col" className="border-bottom text-start">PërfitonNdihmëEkonomike</th>
                                                      <th scope="col" className="border-bottom text-start">FamiljeEkstremishteVarfër</th>
                                                      <th scope="col" className="border-bottom text-start">NevojëPërShërbimeTëTjera</th>
                                                      <th scope="col" className="border-bottom text-start">FormulariPlotësuarNëBanesë</th>
                                                      <th scope="col" className="border-bottom text-start">Latitude</th>
                                                      <th scope="col" className="border-bottom text-start">Longitude</th> */}
                                                      <th scope="col" className="border-bottom text-start">Data Rregjistrimit</th>
                                                      <th scope="col" className="border-bottom text-start">Dokumentat</th>
                                                      <th scope="col" className="border-bottom text-start">Modifiko</th>
                                                    </tr>
                                                  </thead>

                                                  <tbody>
                                                    {this.props.basf.user_act_observation_details.list?.data.map(
                                                      (item, key) => {
                                                        return (
                                                          <tr key={key}>
                                                            <td scope="row" className="text-start">{key + 1}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.Operatori)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.Emri)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.Mbiemri)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.Atesia)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.ID)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.Qarku)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.Bashkia)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.NjesiaAdministrative)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.Fshati)}</td>
                                                            
                                                            {/* <td scope="row" className="text-start">{capitalize(item.FamiljaPrezenteNeBanese)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.KaTokëBujqësore)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.TëArdhuratNgaTokaApoBlektoria)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.NdihmëEkonomike)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.PensionInvaliditeti)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.PensionPleqërie)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.Akpa)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.LlojiPunësimit)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.MjetiMotorrik)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.TipiBanesës)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.GjëndjaBanesës)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.Kushtet_e_familjes)}</td>   
                                                            <td scope="row" className="text-start">{capitalize(item.FirmosurNgaTëGjithëAnëtarët)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.PërfitonNdihmëEkonomike)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.FamiljeEkstremishteVarfër)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.NevojëPërShërbimeTëTjera)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.FormulariPlotësuarNëBanesë)}</td>                                                            
                                                            <td scope="row" className="text-start">{capitalize(item.Latitude)}</td>
                                                            <td scope="row" className="text-start">{capitalize(item.Longitude)}</td> */}

                                                            <td scope="row" className="text-start">{item.DataRregjistrimit}</td>

                                                            <td scope="row" className="text-center">
                                                              <i className="uil uil-download-alt" style={{cursor: "pointer"}} onClick={() => this.downloadDocuments(item)}/>
                                                            </td>

                                                            <td scope="row" className="text-center">
                                                              <i
                                                                className="uil uil-edit-alt"
                                                                style={{cursor: "pointer"}}
                                                                // onClick={() => this.handleAktObservationEdit(key)}
                                                                />
                                                            </td>
                                                          </tr>
                                                        )
                                                      }
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </SweetAlert>
                                    </Col>                                    
                                  )
                              }

                              {/* EVL */}
                              {
                                edit_act_observation_modal && 
                                <>
                                  <SweetAlert
                                    showCancel
                                    cancelBtnBsStyle="light"
                                    title=""
                                    confirmBtnText="Modifiko"
                                    cancelBtnText="Anullo"
                                    placeHolder=""
                                    onConfirm={(response) => this.onReceiveInput(response)}
                                    onCancel={this.closeEditActObservationModal}
                                  >
                                      <div className="form-label text-center">
                                          <h4>Modifiko akt konstatimin</h4>
                                      </div>

                                      <div className="form-label mt-4 mb-4 text-start">
                                      <label className="mb-2">Emri :</label>
                                      <input
                                          type={'text'}
                                          className={
                                            'form-control'
                                          }
                                          value={selected_act_observation.Emri}
                                          onChange={(event) => {

                                          }}
                                          placeholder={'Emri'}
                                      />
                                    </div>

                                    <div className="form-label mt-4 mb-4 text-start">
                                         <label className="mb-2">Mbiemri :</label>
                                        <input
                                            type={'text'}
                                            className={
                                              'form-control'
                                            }
                                            value={selected_act_observation.Mbiemri}
                                            onChange={(event) => {

                                            }}
                                            placeholder={'Mbiemri'}
                                          />
                                    </div>

                                    <div className="form-label mt-4 mb-4 text-start">
                                         <label className="mb-2">Atësia :</label>
                                        <input type={'text'} className={'form-control'} value={selected_act_observation.Mbiemri}
                                            onChange={(event) => {

                                            }}
                                            placeholder={'Atësia'}
                                          />
                                    </div>

                                    <div className="form-label mt-4 mb-4 text-start">
                                    <label className="mb-2">Karta ID :</label>
                                    <input type={'text'} className={'form-control'} value={selected_act_observation.ID}
                                           onChange={(event) => {

                                           }}
                                           placeholder={'Karta ID'}
                                    />
                                  </div>

                                    <div className="form-label mt-4 mb-4 text-start">
                                      <label className="mb-2">Qarku :</label>
                                      <input type={'text'} className={'form-control'} value={selected_act_observation.Qarku}
                                             onChange={(event) => {

                                             }}
                                             placeholder={'Qarku'}
                                      />
                                  </div>

                                    <div className="form-label mt-4 mb-4 text-start">
                                      <label className="mb-2">Bashkia :</label>
                                      <input type={'text'} className={'form-control'} value={selected_act_observation.Bashkia}
                                             onChange={(event) => {

                                             }}
                                             placeholder={'Bashkia'}
                                      />
                                    </div>

                                    <div className="form-label mt-4 mb-4 text-start">
                                      <label className="mb-2">NjesiaAdministrative :</label>
                                      <input type={'text'} className={'form-control'} value={selected_act_observation.NjesiaAdministrative}
                                             onChange={(event) => {

                                             }}
                                             placeholder={'NjesiaAdministrative'}
                                      />
                                    </div>

                                    <div className="form-label mt-4 mb-4 text-start">
                                      <label className="mb-2">Fshati :</label>
                                      <input type={'text'} className={'form-control'} value={selected_act_observation.Fshati}
                                             onChange={(event) => {

                                             }}
                                             placeholder={'Fshati'}
                                      />
                                    </div>

                                    <div className="form-label mt-4 mb-4 text-start">
                                      <label className="mb-2">DataRregjistrimit :</label>
                                      <input type={'text'} className={'form-control'} value={selected_act_observation.DataRregjistrimit}
                                             onChange={(event) => {

                                             }}
                                             placeholder={'DataRregjistrimit'}
                                      />
                                    </div>

                                  </SweetAlert>
                                </>
                                 
                              }

                              {/* {show_total_act_observation_details && (
                                  <div className="col-lg-12 col-sm-12">
                                    <div className="table-responsive bg-white shadow rounded">
                                      <table className="table mb-0 table-center invoice-tb">
                                        <thead
                                          className="bg-light"
                                          title="Akt Konstatime Totale"
                                        >
                                          <tr>
                                            <th
                                              scope="col"
                                              className="border-bottom text-start"
                                            >
                                              Operatori
                                            </th>
                                            <th
                                              scope="col"
                                              className="border-bottom text-start"
                                            >
                                              Emër
                                            </th>
                                            <th
                                              scope="col"
                                              className="border-bottom text-start"
                                            >
                                              Mbiemër
                                            </th>
                                            <th
                                              scope="col"
                                              className="border-bottom text-start"
                                            >
                                              Atësia
                                            </th>
                                            <th
                                              scope="col"
                                              className="border-bottom text-start"
                                            >
                                              Id
                                            </th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          {act_observation.act_observation_data?.fmembers?.map(
                                            (item, key) => {
                                              return (
                                                <tr key={key}>
                                                  <td
                                                    scope="row"
                                                    className="text-start"
                                                  >
                                                    {key + 1}
                                                  </td>
                                                  <td
                                                    scope="row"
                                                    className="text-start"
                                                  >
                                                    {capitalize(item.operatori)}
                                                  </td>
                                                  <td className="text-start">
                                                    {capitalize(item.emri)}
                                                  </td>
                                                  <td className="text-start">
                                                    {capitalize(item.mbiemri)}
                                                  </td>
                                                  <td className="text-start">
                                                    {capitalize(item.atesia)}
                                                  </td>
                                                  <td className="text-start">
                                                    {capitalize(item.id)}
                                                  </td>
                                                </tr>
                                              )
                                            }
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                            )} */}
                            </div>

                            <div className="row">
                              <div className="loader-wrapper">
                                <DotLoader color="#4a5c8a" loading={loading} />
                              </div>

                              <div className="d-grid">
                                <button
                                  className="btn btn-primary border-button"
                                  disabled={loading}
                                  onClick={this.showActObservationDetails}
                                >
                                  Kerko
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}


              {profile_open && (
                <>
                  <div className="col-lg-8 col-12">
                    <div className="pb-4">
                      <div className="row">
                        <div className="col-md-12 mt-4">
                          <h5>Të dhënat e përdoruesit:</h5>
                          <div className="mt-4">
                            <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                              <i
                                data-feather="user"
                                className="fea icon-ex-md me-3"
                              />
                              <div className="flex-1">
                                <h6 className="text_green custom_font mb-0">
                                  Emër:
                                </h6>
                                <p className="text-muted mb-0">
                                  {currentUser?.name}
                                </p>
                              </div>
                            </div>

                            <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                              <i
                                data-feather="user"
                                className="fea icon-ex-md me-3"
                              />
                              <div className="flex-1">
                                <h6 className="text_green custom_font mb-0">
                                  Mbiemër:
                                </h6>
                                <p className="text-muted mb-0">
                                  {currentUser?.surname}
                                </p>
                              </div>
                            </div>

                            <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                              <i
                                data-feather="user"
                                className="fea icon-ex-md me-3"
                              />
                              <div className="flex-1">
                                <h6 className="text_green custom_font mb-0">
                                  Qarku:
                                </h6>
                                <p className="text-muted mb-0">
                                  {currentUser?.district}
                                </p>
                              </div>
                            </div>

                            <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                              <i
                                data-feather="user"
                                className="fea icon-ex-md me-3"
                              />
                              <div className="flex-1">
                                <h6 className="text_green custom_font mb-0">
                                  Pozicioni i Punës:
                                </h6>
                                <p className="text-muted mb-0">
                                  {currentUser?.job_position}
                                </p>
                              </div>
                            </div>

                            <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                              <i
                                data-feather="user"
                                className="fea icon-ex-md me-3"
                              />
                              <div className="flex-1">
                                <h6 className="text_green custom_font mb-0">
                                  Numri i Telefonit:
                                </h6>
                                <p className="text-muted mb-0">
                                  {currentUser?.telefono}
                                </p>
                              </div>
                            </div>

                            <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                              <i
                                data-feather="user"
                                className="fea icon-ex-md me-3"
                              />
                              <div className="flex-1">
                                <h6 className="text_green custom_font mb-0">
                                  Roli:
                                </h6>
                                <p className="text-muted mb-0">
                                  {currentUser?.roleName}
                                </p>
                              </div>
                            </div>

                            <div className="d-flex align-items-center border-bottom mb-2 pb-2">
                              <i
                                data-feather="user"
                                className="fea icon-ex-md me-3"
                              />
                              <div className="flex-1">
                                <h6 className="text_green custom_font mb-0">
                                  Email:
                                </h6>
                                <p className="text-muted mb-0">
                                  {currentUser?.email}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}


              {akt_konstatim_open && (
                <>
                  <div className="col-lg-8 col-12">
                    <div className="pb-4">
                      <div className="row">
                        <div className="col-md-12 mt-4">
                          {/* <h2 style={{textAlign: 'center', fontWeight: 600}}>Akt konstatimi</h2> */}

                          <h4
                            className="card-title text-center text-uppercase"
                            style={{ color: '#ff5722' }}
                          >
                            {' '}
                            Akt konstatimi{' '}
                          </h4>
                          <p className="border-bottom pb-2" />
                          <form className="login-form mt-4">
                            <div className="col-md-12 mt-3">
                              {/* Emer */}
                              <div className="col-md-6">
                                <div className="mb-4">
                                  <label
                                    className={
                                      errors.name
                                        ? 'form-label label_error'
                                        : 'form-label'
                                    }
                                  >
                                    Emër <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <Icon
                                      icon="bx:bx-building-house"
                                      width="17"
                                      height="17"
                                      className="custom_icon"
                                    />
                                    <input
                                      type="text"
                                      onChange={(event) => {
                                        const value = event.target.value
                                        this.setState((prevState) => ({
                                          ...prevState,
                                          registration_data: {
                                            ...prevState.registration_data,
                                            name: value,
                                          },
                                          errors: {
                                            ...prevState.errors,
                                            name: false,
                                          },
                                        }))
                                        setActObservationValues('name', value)
                                      }}
                                      value={
                                        this.props.basf.registration_data.name
                                      }
                                      className={
                                        errors.name
                                          ? 'form-control ps-5 input_error'
                                          : 'form-control ps-5'
                                      }
                                      placeholder="Emër"
                                      id="name"
                                      name="Emër"
                                      required=""
                                    />
                                  </div>
                                </div>
                              </div>

                              {/* Mbiemer */}
                              <div className="col-md-6">
                                <div className="mb-4">
                                  <label
                                    className={
                                      errors.surname
                                        ? 'form-label label_error'
                                        : 'form-label'
                                    }
                                  >
                                    Mbiemër{' '}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="form-icon position-relative">
                                    <Icon
                                      icon="bx:bx-building-house"
                                      width="17"
                                      height="17"
                                      className="custom_icon"
                                    />
                                    <input
                                      type="text"
                                      onChange={(event) => {
                                        const value = event.target.value
                                        this.setState((prevState) => ({
                                          ...prevState,
                                          registration_data: {
                                            ...prevState.registration_data,
                                            surname: value,
                                          },
                                          errors: {
                                            ...prevState.errors,
                                            surname: false,
                                          },
                                        }))
                                        setActObservationValues(
                                          'surname',
                                          value
                                        )
                                      }}
                                      value={
                                        this.props.basf.registration_data
                                          .surname
                                      }
                                      className={
                                        errors.name
                                          ? 'form-control ps-5 input_error'
                                          : 'form-control ps-5'
                                      }
                                      placeholder="Mbiemër"
                                      id="surname"
                                      name="Mbiemër"
                                      required=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* {report_open && (
                <>
                 <div className="col-lg-8 col-12">
                    <div className="pb-4">
                      <div className="row">
                      </div>
                        <h1>Lista Akt konstatimeve - Në proçes implementimi</h1>
                    </div>
                  </div>
                  
                </>
              )} */}

              {users_open && (
                <>
                  <div className="col-lg-8 col-12">
                    <div className="pb-4">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-4" style={{float: 'right'}}>
                            <div className="d-grid">
                              <a
                                className="btn btn-primary border-button"
                                onClick={() => {
                                  this.handleToggle('create_new_user')
                                }}
                              >
                                {' '}
                                Shto user te ri
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="table-responsive bg-white shadow rounded">
                          <table className="table mb-0 table-center invoice-tb">
                            <thead
                              className="bg-light"
                              title="Lista e përdoruesve"
                            >
                              <tr>
                                <th
                                  scope="col"
                                  className="border-bottom text-start"
                                >
                                  Id
                                </th>
                                <th
                                  scope="col"
                                  className="border-bottom text-start"
                                >
                                  Emër
                                </th>
                                <th
                                  scope="col"
                                  className="border-bottom text-start"
                                >
                                  Mbiemër
                                </th>
                                <th
                                  scope="col"
                                  className="border-bottom text-start"
                                >
                                  Telefoni
                                </th>
                                <th
                                  scope="col"
                                  className="border-bottom text-start"
                                >
                                  Pozicioni
                                </th>
                                <th
                                  scope="col"
                                  className="border-bottom text-start"
                                >
                                  Roli
                                </th>
                                <th
                                  scope="col"
                                  className="border-bottom text-start"
                                >
                                  E-maili
                                </th>
                                <th
                                  scope="col"
                                  className="border-bottom text-start"
                                >
                                  Aktiv
                                </th>
                                {/* <th
                                  scope="col"
                                  className="border-bottom text-center"
                                >
                                  Aksione
                                </th> */}
                              </tr>
                            </thead>

                            <tbody>
                              {this.props.basf.users.list?.data?.map(
                                (item, key) => {
                                  return (
                                    <tr key={key}>
                                      <td scope="row" className="text-start">
                                        {key + 1}
                                      </td>
                                      <td scope="row" className="text-start">
                                        {capitalize(item.name)}
                                      </td>
                                      <td className="text-start">
                                        {capitalize(item.surname)}
                                      </td>
                                      <td className="text-start">{item.telefono}</td>
                                      <td className="text-start">{capitalize(item.job_position)}</td>
                                      <td className="text-start">{item.roleName}</td>
                                      <td className="text-start">{item.email}</td>
                                      <td className="text-start">{item.active === 1 ? 'Po' : 'Jo'}</td>
                                      {/* <td
                                        className="text-center"
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-evenly',
                                        }}
                                      >
                                        <i
                                          onClick={() => this.deleteUser(item)}
                                          className="uil uil-edit cursor_pointer"
                                        />
                                        <i
                                          onClick={() => this.editUser(item.id)}
                                          className="uil uil-trash cursor_pointer"
                                        />
                                      </td> */}
                                    </tr>
                                  )
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {settings_open && (
                <>
                  <h1>Settings</h1>
                </>
              )}

            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.user,
        basf: state.basf,
        auth: state.auth,
        media: state.media,
        user_act_observations: state.user_act_observations,
        users: state.users,
    }
}


const mapDispatchToProps = {
    getAllUsers,
    getUserActObservations,
    getUserActObservationDetails,
    setActObservationFilterValue,
    clearActObservationDetails,
    getCurrentUser,
    getDistributorsCompetenceAreas,
    getClientsForBasfByDistributor,
    getBasfDistributors,
    setBasfSelectValue,
    getClientDetailsForBasfPreview,
    clearClientDetails,
    clearClients,
    clearDistributors,
    getAllBenchmarks,
    setBenchmarkFilterValue,
    getDistributorName,
    getPhotosAndVideosUpdates,
    getDocumentsUrl,
}


export default connect(mapStateToProps, mapDispatchToProps)(BasfSalesProfile);