// RadioButton.js
import React from 'react';
import styled from 'styled-components';

const RadioContainer = styled.label`
  display: inline-flex;
  align-items: center;
  margin: 5px;
  cursor: pointer;
`;

const RadioInput = styled.input.attrs({ type: 'radio' })`
  opacity: 0;
  position: absolute;
`;

const RadioStyled = styled.span`
  width: 20px;
  height: 20px;
  border: 2px solid #999;
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block;

  ${RadioInput}:checked + & {
    background-color: #124057;
  }
`;

const RadioButton = ({ label, ...props }) => {
  return (
    <RadioContainer>
      <RadioInput {...props} />
      <RadioStyled />
      {label}
    </RadioContainer>
  );
};

export default RadioButton;