export const GET_DISTRICT_LIST = 'GET_DISTRICT_LIST';
export const GET_MUNICIPALITY_LIST = 'GET_MUNICIPALITY_LIST';
export const GET_ADMINISTRATIVE_UNIT_LIST = 'GET_ADMINISTRATIVE_UNIT_LIST';
export const GET_VILLAGE_LIST = 'GET_VILLAGE_LIST';
export const REGISTER_ACT_OBSERVATION = 'REGISTER_ACT_OBSERVATION';

export const SET_ACT_OBSERVATION_VALUE = 'SET_ACT_OBSERVATION_VALUE';
export const SET_FAMILY_MEMBER_VALUE = 'SET_FAMILY_MEMBER_VALUE';

export const SET_ERROR_MAP_FALSE = 'SET_ERROR_MAP_FALSE';
export const SET_ERROR_MAP_TRUE = 'SET_ERROR_MAP_TRUE';

export const SET_MAP_CODE_SELECTED = 'SET_MAP_CODE_SELECTED';
export const SET_REDIRECT_TO_HOME_FALSE = 'SET_REDIRECT_TO_HOME_FALSE';
export const SET_ERROR_CODE_FALSE = 'SET_ERROR_CODE_FALSE';



export const SET_NEW_UPDATE_VALUE = 'SET_NEW_UPDATE_VALUE';
export const UPLOAD_MEDIA = ' UPLOAD_MEDIA';
export const UPLOAD_NEW_UPDATE = 'UPLOAD_NEW_UPDATE';
export const UPLOAD_WORKING_GROUP_SIGNATURE = 'UPLOAD_WORKING_GROUP_SIGNATURE';
export const UPLOAD_FAMILY_MEMBERS_SIGNATURE = 'UPLOAD_FAMILY_MEMBERS_SIGNATURE';

export const CLEAR_FORM = 'CLEAR_FORM';
