import {
    GET_CLIENTS_FOR_BASF_BY_DISTRIBUTOR,
    GET_BASF_DISTRIBUTORS,
    GET_DISTRIBUTORS_COMPETENCE_AREAS,
    GET_USER_ACT_OBSERVATIONS,
    GET_USER_ACT_OBSERVATION_DETAILS,



    SET_BASF_SELECT_VALUE,
    GET_CLIENT_DETAILS_FOR_BASF_PREVIEW,
    CLEAR_CLIENT_DETAILS,
    CLEAR_DISTRIBUTORS,
    CLEAR_CLIENTS,
    GET_ALL_BENCHMARKS,
    SET_NEW_UPDATE_VALUE,
    SET_ACT_OBSERVATION_VALUES,
    CLEAR_ACT_OBSERVATION_DETAILS,
    
    UPLOAD_NEW_UPDATE,
    UPLOAD_MEDIA,
    GET_DISTRIBUTOR_NAME,
    GET_DOCUMENTS_URL,
    
    CLEAR_FORM,
    SET_ACT_KONSTATIMI_VALUES,
    SET_ACT_OBSERVATION_FILTER_VALUE,
    GET_ALL_USERS,
} from "./actionTypes";


export const getDistributorsCompetenceAreas = () => {
    return {
        type: GET_DISTRIBUTORS_COMPETENCE_AREAS,
        api: true
    }
}

export const getUserActObservations = (userID) => {
    console.log("USER_ID", userID)
    return {
        type: GET_USER_ACT_OBSERVATIONS,
        // payload: act_observations,
        api: true
    }
}


export const getUserActObservationDetails = () => {
    return {
        type: GET_USER_ACT_OBSERVATION_DETAILS,
        api: true
    }
}


export const getBasfDistributors = () => {
    return {
        type: GET_BASF_DISTRIBUTORS,
        api: true
    }
}


export const getClientsForBasfByDistributor = () => {
    return {
        type: GET_CLIENTS_FOR_BASF_BY_DISTRIBUTOR,
        api: true
    }
}


export const setBasfSelectValue = (field, value) => {
    return {
        type: SET_BASF_SELECT_VALUE,
        payload: {
            field,
            value
        }
    }
}

export const getClientDetailsForBasfPreview = (client_detail) => {
    return {
        type: GET_CLIENT_DETAILS_FOR_BASF_PREVIEW,
        payload: {
            client_detail
        }
    }
}


export const clearClientDetails = () => {
    return {
        type: CLEAR_CLIENT_DETAILS
    }
}


export const clearDistributors = () => {
    return {
        type: CLEAR_DISTRIBUTORS
    }
}


export const clearClients = () => {
    return {
        type: CLEAR_CLIENTS
    }
}


export const setNewUpdateValue = (field, value) => {
    return {
        type: SET_NEW_UPDATE_VALUE,
        payload: {
            field,
            value
        }
    }
}



export const setActObservationValues = (field, value) => {
    return {
        type: SET_ACT_OBSERVATION_VALUES,
        payload: {
            field,
            value
        }
    }
}


export const clearActObservationDetails = () => {
    console.log("clear 1")
    return {
        type: CLEAR_ACT_OBSERVATION_DETAILS
    }
}



// export const uploadNewUpdate = (data) => {
//     const formData = new FormData();
//     formData.append('benchmark', data.benchmark);
//     formData.append('title', data.title);
//     formData.append('description', data.description);
//     formData.append('fileToUpload', data.file);
//     formData.append('id_user', data.id_user);

//     return {
//         type: UPLOAD_NEW_UPDATE,
//         payload: formData,
//         api: true
//     }
// }

export const uploadMedia = (file) => {
    return {
        type: UPLOAD_MEDIA,
        payload: file
    }
}


export const getDistributorName = (distributors) => {
    return {
        type: GET_DISTRIBUTOR_NAME,
        payload: distributors
    }
}

export const getDocumentsUrl = () => {
    return {
        type: GET_DOCUMENTS_URL,
        api: true,
    }
}


export const clearForm = () => {
    return {
        type: CLEAR_FORM
    }
}

export const setActObservationFilterValue = (field, value) => {
    return {
        type: SET_ACT_OBSERVATION_FILTER_VALUE,
        payload: {
            field,
            value
        }
    }
}

export const getAllUsers = () => {
    return {
        type: GET_ALL_USERS,
        api: true,
    }
}