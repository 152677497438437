import {
    GET_BASF_DISTRIBUTORS,
    GET_DISTRIBUTORS_COMPETENCE_AREAS,
    GET_USER_ACT_OBSERVATIONS,
    GET_USER_ACT_OBSERVATION_DETAILS,


    GET_CLIENTS_FOR_BASF_BY_DISTRIBUTOR,
    SET_BASF_SELECT_VALUE,
    GET_CLIENT_DETAILS_FOR_BASF_PREVIEW,
    CLEAR_CLIENT_DETAILS,
    CLEAR_CLIENTS,
    CLEAR_DISTRIBUTORS,
    SET_NEW_UPDATE_VALUE,
    // UPLOAD_NEW_UPDATE,
    // UPLOAD_MEDIA,
    GET_DISTRIBUTOR_NAME,   
    CLEAR_FORM,
    CLEAR_ACT_OBSERVATION_DETAILS,
    SET_ACT_OBSERVATION_FILTER_VALUE,
    GET_ALL_USERS,
    GET_DOCUMENTS_URL,
} from "./actionTypes";
import { toast } from "react-toastify";


const initialState = {
    users: {
        list: {
            data: [],
            loading: false
        }
    },
    documents:{
        url:{
            data: '',
            loading: false
        }
    },
    distributors: {
        list: {
            data: [],
            loading: false
        }
    },
    clients: {
        list: {
            data: [],
            loading: false
        }
    },
    areas: {
        list: {
            data: [],
            loading: false
        }
    },

    user_act_observations: {
        list: {
            data: [],
            loading: false
        }
    },
    
    user_act_observation_details: {
        list: {
            data: [],
            loading: false,
            // filterData: '',
        }
    },

    params: {
        area_id: '',
        distributor_id: '',
        operator_id: '',
    },
    new_update: {
        benchmark: '',
        title: '',
        description: '',
        file: null
    },
    client_detail: null,
    distributor_name: "",
    upload_loading: false,
    registration_data: {},
    filter: {},
};

export default (state = initialState, action) => {
    let users;
    let documents;
    let distributors;
    let clients;
    let areas;
    let user_act_observations;
    let user_act_observation_details;
    let params;
    let new_update;
    let client_detail;
    let distributor_name;
    let upload_loading;

    let shareholder_data;
    let registrationData;
    let filter;

    switch (action.type) {
        case GET_USER_ACT_OBSERVATIONS:
            user_act_observations = Object.assign({}, state.user_act_observations);
            user_act_observations.list.loading = true;
            state = {
                ...state,
                user_act_observations: user_act_observations
            }
            break;
        case GET_USER_ACT_OBSERVATIONS.concat("_SUCCESS"):
            user_act_observations = Object.assign({}, state.user_act_observations);
            user_act_observations.list.loading = false;
            user_act_observations.list.data = action.payload;
            console.log("USER_ACT_OBS", user_act_observations);
            state = {
                ...state,
                user_act_observations: user_act_observations
            }
            break;
        case GET_USER_ACT_OBSERVATIONS.concat("_FAILURE"):
            user_act_observations = Object.assign({}, state.user_act_observations);
            user_act_observations.list.loading = false;
            user_act_observations.list.data = [];
            state = {
                ...state,
                user_act_observations: user_act_observations
            }
            break;

        case GET_USER_ACT_OBSERVATION_DETAILS:
            user_act_observation_details = Object.assign({}, state.user_act_observation_details);
            user_act_observation_details.list.loading = true;
            state = {
                ...state,
                user_act_observation_details: user_act_observation_details
            }
            break;
        case GET_USER_ACT_OBSERVATION_DETAILS.concat("_SUCCESS"):
            user_act_observation_details = Object.assign({}, state.user_act_observation_details);
            user_act_observation_details.list.loading = false;
            user_act_observation_details.list.data = action.payload;
            console.log("USER_ACT_OBS_DETAILS", user_act_observation_details);
            state = {
                ...state,
                user_act_observation_details: user_act_observation_details
            }
            break;
        case GET_USER_ACT_OBSERVATION_DETAILS.concat("_FAILURE"):
            user_act_observation_details = Object.assign({}, state.user_act_observation_details);
            user_act_observation_details.list.loading = false;
            user_act_observation_details.list.data = [];
            state = {
                ...state,
                user_act_observation_details: user_act_observation_details
            }
            break;
        case GET_ALL_USERS:
            users = Object.assign({}, state.users);
            users.list.loading = false;
            users.list.data = [];
            state = {
                ...state,
                users: users
            }
            break;
        case GET_ALL_USERS.concat("_SUCCESS"):
            users = Object.assign({}, state.users);
            users.list.loading = false;
            users.list.data = action.payload;
            console.log("GET_ALL_USERS", users);
            state = {
                ...state,
                users: users
            }
            break;
        case GET_ALL_USERS.concat("_FAILURE"):
            users = Object.assign({}, state.users);
            users.list.loading = false;
            users.list.data = [];
            state = {
                ...state,
                users: users
            }
            break;
        case GET_DOCUMENTS_URL:
            documents = Object.assign({}, state.documents);
            documents.url.loading = false;
            documents.url.data = '';
            state = {
                ...state,
                documents: documents
            }
            break;
        case GET_DOCUMENTS_URL.concat("_SUCCESS"):
            documents = Object.assign({}, state.documents);
            documents.url.loading = false;
            documents.url.data = action.payload;
            console.log("GET_DOCUMENTS_URL", documents.url.data);

            window.open(`https://appshssh.onisystems.net/uploads/1_2147_106ee5e1-b1c9-4be4-be7c-18eefad701fa_dokumenta.zip`);

            // window.open(`https://appshssh.onisystems.net/uploads/${documents.url.data}`);
            // window.open(`${process.env.REACT_APP_URL}/uploads/test.zip`);

            // const zipBlob = new Blob(documents.url.data);
            // const url = window.URL.createObjectURL(zipBlob);
            // const zipDownload = document.createElement("a");
            // zipDownload.href = url;
            // zipDownload.download = `${state.basf.params.operator_id}_${state.basf.params.act_observation_id}_dokumenta.zip`;
            // document.body.appendChild(zipDownload);
            // zipDownload.click();


            state = {
                ...state,
                documents: documents
            }
            break;
        case GET_DOCUMENTS_URL.concat("_FAILURE"):
            documents = Object.assign({}, state.documents);
            documents.url.loading = false;
            documents.url.data = '';
            console.log("GET_DOCUMENTS_URL", documents);
            state = {
                ...state,
                documents: documents
            }
            break;
        case GET_DISTRIBUTORS_COMPETENCE_AREAS:
            areas = Object.assign({}, state.areas);
            areas.list.loading = true;
            state = {
                ...state,
                areas: areas
            }
            break;
        case GET_DISTRIBUTORS_COMPETENCE_AREAS.concat("_SUCCESS"):
            areas = Object.assign({}, state.areas);
            areas.list.loading = false;
            areas.list.data = action.payload;
            state = {
                ...state,
                areas: areas
            }
            break;
        case GET_DISTRIBUTORS_COMPETENCE_AREAS.concat("_FAILURE"):
            areas = Object.assign({}, state.areas);
            areas.list.loading = false;
            areas.list.data = [];
            state = {
                ...state,
                areas: areas
            }
            break;
        case GET_BASF_DISTRIBUTORS:
            distributors = Object.assign({}, state.distributors);
            distributors.list.loading = true;
            state = {
                ...state,
                distributors: distributors
            }
            break;
        case GET_BASF_DISTRIBUTORS.concat("_SUCCESS"):
            distributors = Object.assign({}, state.distributors);
            distributors.list.loading = false;
            distributors.list.data = action.payload;
            state = {
                ...state,
                distributors: distributors
            }
            break;
        case GET_BASF_DISTRIBUTORS.concat("_FAILURE"):
            distributors = Object.assign({}, state.distributors);
            distributors.list.loading = false;
            distributors.list.data = [];
            state = {
                ...state,
                distributors: distributors
            }
            break;
        case GET_CLIENTS_FOR_BASF_BY_DISTRIBUTOR:
            clients = Object.assign({}, state.clients);
            clients.list.loading = true;
            state = {
                ...state,
                clients: clients
            }
            break;
        case GET_CLIENTS_FOR_BASF_BY_DISTRIBUTOR.concat("_SUCCESS"):
            clients = Object.assign({}, state.clients);
            clients.list.loading = false;
            clients.list.data = action.payload;
            state = {
                ...state,
                clients: clients
            }
            break;
        case GET_CLIENTS_FOR_BASF_BY_DISTRIBUTOR.concat("_FAILURE"):
            clients = Object.assign({}, state.clients);
            clients.list.loading = false;
            clients.list.data = [];
            state = {
                ...state,
                clients: clients
            }
            break;
        case SET_BASF_SELECT_VALUE:
            params = Object.assign({}, state.params);
            params[action.payload.field] = action.payload.value;
            state = {
                ...state,
                params: params
            }
            break;
        case GET_CLIENT_DETAILS_FOR_BASF_PREVIEW:
            client_detail = state.client_detail;
            client_detail = action.payload.client_detail;
            state = {
                ...state,
                client_detail: client_detail
            }
            break;
        case CLEAR_CLIENT_DETAILS:
            client_detail = state.client_detail;
            client_detail = null;
            state = {
                ...state,
                client_detail: client_detail
            }
            break;
        case CLEAR_DISTRIBUTORS:
            state = {
                ...state,
                distributors: {
                    list: {
                        data: [],
                        loading: false
                    }
                },
                params: {
                    distributor_id: '',
                    area_id: state.params.area_id,
                    operator_id: state.params.operator_id,
                }
            }
            break;
        case CLEAR_CLIENTS:
            state = {
                ...state,
                clients: {
                    list: {
                        data: [],
                        loading: false
                    }
                }
            }
            break;

        case SET_NEW_UPDATE_VALUE:
            new_update = Object.assign({}, state.new_update);
            new_update[action.payload.field] = action.payload.value;
            state = {
                ...state,
                new_update: new_update
            }
            break;
        case SET_ACT_OBSERVATION_FILTER_VALUE:
            filter = Object.assign({}, state.filter);
            filter[action.payload.field] = action.payload.value;
            console.log("FILTER", filter)
            state = {
                ...state,
                filter: filter
            }
            break;  
        // case UPLOAD_NEW_UPDATE:
        //     upload_loading = state.upload_loading;
        //     upload_loading = true;
        //     state = {
        //         ...state,
        //         upload_loading: upload_loading
        //     }
        //     break;
        // case UPLOAD_NEW_UPDATE.concat('_SUCCESS'):
        //     upload_loading = state.upload_loading;
        //     upload_loading = false;
        //     state = {
        //         ...state,
        //         upload_loading: upload_loading
        //     }
        //     toast.success('Caricato con successo!')
        //     break;
        // case UPLOAD_NEW_UPDATE.concat('_FAILURE'):
        //     upload_loading = state.upload_loading;
        //     upload_loading = false;
        //     state = {
        //         ...state,
        //         upload_loading: upload_loading
        //     }
        //     //TODO
        //     toast.error('Caricamento fallito!', {position: "top-center"});
        //     break;
        // case UPLOAD_MEDIA:
        //     new_update = Object.assign({}, state.new_update);
        //     new_update.file = action.payload;
        //     console.log("LOGO UPLOAD", new_update.file)
        //     state = {
        //         ...state,
        //         new_update: new_update
        //     }
        //     break;
        case GET_DISTRIBUTOR_NAME:
            distributor_name = state.distributor_name;
            distributor_name = action.payload.filter(distributor => distributor.id === Number(state.params.distributor_id))[0]?.ragione_sociale;
            state = {
                ...state,
                distributor_name: distributor_name
            }
            break;
        case CLEAR_FORM:
            new_update = Object.assign({}, state.new_update);
            new_update.benchmark = '';
            new_update.title = '';
            new_update.description = '';
            new_update.file = null;
            state = {
                ...state,
                new_update: new_update
            }
            break;
        case CLEAR_ACT_OBSERVATION_DETAILS:
            user_act_observation_details = Object.assign({}, state.user_act_observation_details);
            user_act_observation_details.list.loading = false;
            user_act_observation_details.list.data = [];
            state = {
                ...state,
                user_act_observation_details: user_act_observation_details
            }
            break;            
        // case SET_NEW_UPDATE_VALUE:
        //     registrationData = Object.assign({}, state.registration_data);
        //     console.log(state, registrationData)
        //     registrationData[action.payload.field] = action.payload.value;
        //     state = {
        //         ...state,
        //         registration_data: registrationData
        //     }
        //     break;


        default:
            state = { ...state };
            break;
    }
    return state;
};