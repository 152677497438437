import {
    SET_ERROR_MAP_FALSE,
    SET_ERROR_MAP_TRUE,
    GET_DISTRICT_LIST,
    GET_MUNICIPALITY_LIST,
    GET_ADMINISTRATIVE_UNIT_LIST,
    GET_VILLAGE_LIST,
    SET_ACT_OBSERVATION_VALUE,
    SET_FAMILY_MEMBER_VALUE,
    REGISTER_ACT_OBSERVATION,
    UPLOAD_NEW_UPDATE,
    UPLOAD_WORKING_GROUP_SIGNATURE,
    UPLOAD_FAMILY_MEMBERS_SIGNATURE,
    SET_NEW_UPDATE_VALUE,

    SET_MAP_CODE_SELECTED,
    SET_REDIRECT_TO_HOME_FALSE,
    SET_ERROR_CODE_FALSE,
    UPLOAD_MEDIA,      CLEAR_FORM,
    
    
} from "./actionTypes";

import Swal from 'sweetalert2'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import cookie from "react-cookies";
// import { SET_NEW_UPDATE_VALUE, UPLOAD_NEW_UPDATE } from "../user/actionTypes";

function setTokenCookie(token) {
    cookie.save("token", token, {
        "path": "/"
    });
}


const initialState = {
    act_observation_data: {
        // currentDateTime: new Date(),
        family_present_in_the_apartment: 'po',        
        cardID: '',
        form_was_filled_at_home: 'jo',
        // max_file_upload_limit: 3,
        final_documents: [],   
        document_ids: [],     
    },
    error_map: false,
    map_code_selected: '',
    redirect_to_home: false,
    code_error: false,

    new_update: {
        file: null
    },

    uploaded_documents: [],

    upload_loading: false,
    
    district_list: {
        list: {
            data: [],
            loading: false
        }
    },

    municipality_list: {
        list: {
            data: [],
            loading: false
        }
    },
    
    administrative_unit_list: {
        list: {
            data: [],
            loading: false
        }
    },
    
    village_list: {
        list: {
            data: [],
            loading: false
        }
    },
};

export default (state = initialState, action) => {    
    let error_map;
    let map_code_selected;
    let redirect_to_home;
    let code_error;
    let family_members_data;
    let actObservationData;    
    let new_update;
    let uploaded_documents;
    let upload_loading;
    let district_list;
    let municipality_list;
    let administrative_unit_list;
    let village_list;
    switch (action.type) {
        case SET_ACT_OBSERVATION_VALUE:
            actObservationData = Object.assign({}, state.act_observation_data);
            actObservationData[action.payload.field] = action.payload.value;
            state = {
                ...state,
                act_observation_data: actObservationData
            }
            break;
        case SET_FAMILY_MEMBER_VALUE:
            family_members_data = Object.assign({}, state.family_members_data);
            console.log("family_members_data", family_members_data);

            family_members_data[action.payload.field] = action.payload.value;
            state = {
                ...state,
                family_members_data: family_members_data,
            }
            break;
        case REGISTER_ACT_OBSERVATION.concat("_SUCCESS"):
            // toast.success('Akt konstatimi u rregjistrua me sukses!', {position: "top-center"});

            // setTokenCookie(action.payload.token);
            redirect_to_home = state.redirect_to_home;
            redirect_to_home = true;

            state = {
                ...state,
                redirect_to_home: redirect_to_home,
                act_observation_data: {
                    // currentDateTime: new Date(),
                    family_present_in_the_apartment: 'po',        
                    cardID: '',
                    form_was_filled_at_home: 'jo',
                },
            }

            Swal.fire({
                position: "top-center",
                title: "",
                text: "Akt konstatimi u rregjistrua me sukses!",
                icon: "success",
                showCancelButton: false,
                showConfirmButton: false,
                timer: 1500
            });


            break;
        case REGISTER_ACT_OBSERVATION.concat("_FAILURE"):
            // toast.error(action.payload.data.message, {position: "top-center"});

              Swal.fire({
                position: "top-center",
                title: "Oops!",
                text: action.payload.data.message,
                icon: "error",
                showCancelButton: false,
                showConfirmButton: false,
                timer: 1500
            });
            
            code_error = state.code_error;

            if (action.payload.data.errorCode === "999") {
                code_error = true;
            }
            state = {
                ...state,
                code_error: code_error
            }
            break;
        case SET_ERROR_MAP_FALSE:
            error_map = state.error_map;
            error_map = false;
            state = {
                ...state,
                error_map: error_map
            }
            break;
        case SET_ERROR_MAP_TRUE:
            error_map = state.error_map;
            error_map = true;
            state = {
                ...state,
                error_map: error_map
            }
            
            // toast.error(action.payload.data.message, {position: "top-center"});

            Swal.fire({
                position: "top-center",
                title: "Oops!",
                text: action.payload.data.message,
                icon: "error",
                showCancelButton: false,
                showConfirmButton: false,
                timer: 1500
            });

            break;


            code_error = state.code_error;

            if (action.payload.data.errorCode === "999") {
                code_error = true;
            }
            state = {
                ...state,
                code_error: code_error
            }
            break;
        case SET_MAP_CODE_SELECTED:
            map_code_selected = state.map_code_selected;
            map_code_selected = action.payload;
            state = {
                ...state,
                map_code_selected: map_code_selected
            }
            break;
        case SET_REDIRECT_TO_HOME_FALSE:
            redirect_to_home = state.redirect_to_home;
            redirect_to_home = false;
            state = {
                ...state,
                redirect_to_home: redirect_to_home
            }
            break;
        case SET_ERROR_CODE_FALSE:
            code_error = state.code_error;
            code_error = false;
            state = {
                ...state,
                code_error: code_error
            }
            break;
        case SET_NEW_UPDATE_VALUE:
            new_update = Object.assign({}, state.new_update);
            new_update[action.payload.field] = action.payload.value;
            state = {
                ...state,
                new_update: new_update
            }
            break;
        case UPLOAD_NEW_UPDATE:
            upload_loading = state.upload_loading;
            upload_loading = true;
            state = {
                ...state,
                upload_loading: upload_loading
            }
            break;
        case UPLOAD_NEW_UPDATE.concat('_SUCCESS'):
            upload_loading = state.upload_loading;
            upload_loading = false;

            actObservationData = Object.assign({}, state.act_observation_data);
            actObservationData['document_ids'] = [...action.payload.ids];
            // console.log("AAAAAAAA---->", action.payload.ids);
            state = {
                ...state,
                upload_loading: upload_loading,               
                act_observation_data: actObservationData,
            }
            // toast.success('Dokumentat u ruajten me sukses!', {position: "top-center"});

            Swal.fire({
                position: "top-center",
                title: "",
                text: "Dokumenti/Dokumentat u ruajten me sukses!",
                icon: "success",
                showCancelButton: false,
                showConfirmButton: false,
                timer: 1500
            });

            break;
        case UPLOAD_NEW_UPDATE.concat('_FAILURE'):
            upload_loading = state.upload_loading;
            upload_loading = false;
            state = {
                ...state,
                upload_loading: upload_loading
            }
            // toast.error('Ngarkimi i dokumentit dështoi! Provoni përsëri!', {position: "top-center"});
            
            Swal.fire({
                position: "top-center",
                title: "Oops!",
                text: "Ngarkimi i dokumentit/dokumentave dështoi! Ju lutem Provoni përsëri!",
                icon: "error",
                showCancelButton: false,
                showConfirmButton: false,
                timer: 1500
            });

            break;



        case UPLOAD_WORKING_GROUP_SIGNATURE:
            upload_loading = state.upload_loading;
            upload_loading = true;
            state = {
                ...state,
                upload_loading: upload_loading
            }
            break;
        case UPLOAD_WORKING_GROUP_SIGNATURE.concat('_SUCCESS'):
            upload_loading = state.upload_loading;
            upload_loading = false;

            actObservationData = Object.assign({}, state.act_observation_data);
            actObservationData['working_group_sign_document_id'] = [...action.payload.ids];
            state = {
                ...state,
                upload_loading: upload_loading,               
                act_observation_data: actObservationData,
            }
            // toast.success('Dokumenti u ruajten me sukses!', {position: "top-center"})

            Swal.fire({
                position: "top-center",
                title: "",
                text: "Firma e antarëve të grupit të punës u ruajten me sukses!",
                icon: "success",
                showCancelButton: false,
                showConfirmButton: false,
                timer: 1500
            });


            break;
        case UPLOAD_WORKING_GROUP_SIGNATURE.concat('_FAILURE'):
            upload_loading = state.upload_loading;
            upload_loading = false;
            state = {
                ...state,
                upload_loading: upload_loading
            }
            // toast.error('Ngarkimi i dokumentit dështoi! Provoni përsëri!', {position: "top-center"});


            Swal.fire({
                position: "top-center",
                title: "Oops!",
                text: "Ngarkimi i firmës së antarëve të grupit të punës dështoi! Ju lutem provoni përsëri!",
                icon: "error",
                showCancelButton: false,
                showConfirmButton: false,
                timer: 1500
            });


            break;


        case UPLOAD_FAMILY_MEMBERS_SIGNATURE:
            upload_loading = state.upload_loading;
            upload_loading = true;
            state = {
                ...state,
                upload_loading: upload_loading
            }
            break;

        case UPLOAD_FAMILY_MEMBERS_SIGNATURE.concat('_SUCCESS'):
            upload_loading = state.upload_loading;
            upload_loading = false;

            actObservationData = Object.assign({}, state.act_observation_data);
            actObservationData['family_members_sign_document_id'] = [...action.payload.ids];
            state = {
                ...state,
                upload_loading: upload_loading,               
                act_observation_data: actObservationData,
            }
            // toast.success('Dokumenti u ruajten me sukses!', {position: "top-center"});


            Swal.fire({
                position: "top-center",
                title: "",
                text: "Firma e antarëve të familjes u ruajten me sukses!",
                icon: "success",
                showCancelButton: false,
                showConfirmButton: false,
                timer: 1500
            });


            break;
        case UPLOAD_FAMILY_MEMBERS_SIGNATURE.concat('_FAILURE'):
            upload_loading = state.upload_loading;
            upload_loading = false;
            state = {
                ...state,
                upload_loading: upload_loading
            }
            // toast.error('Ngarkimi i dokumentit dështoi! Provoni përsëri!', {position: "top-center"});

            Swal.fire({
                position: "center-center",
                title: "Oops!",
                text: "Ngarkimi i firmës së antarëve të familjes dështoi! Ju lutem provoni përsëri!",
                icon: "error",
                showCancelButton: false,
                showConfirmButton: false,
                timer: 1500
            });

            break;

        case UPLOAD_MEDIA:
            uploaded_documents = action.payload;

            console.log("DOCUMENTS_UPLOAD", uploaded_documents)
            state = {
                ...state,
                uploaded_documents: uploaded_documents
            }

            break;
        case CLEAR_FORM:
            new_update = Object.assign({}, state.new_update);
            new_update.benchmark = '';
            new_update.title = '';
            new_update.description = '';
            new_update.file = null;
            state = {
                ...state,
                new_update: new_update
            }
            break;

        case GET_DISTRICT_LIST:
            district_list = Object.assign({}, state.district_list);
            district_list.list.loading = true;
            state = {
                ...state,
            }
            break;
        case GET_DISTRICT_LIST.concat("_SUCCESS"):
            district_list = Object.assign({}, state.district_list);
            district_list.list.loading = false;
            district_list.list.data = action.payload;

            console.log("district_list", district_list)

            state = {
                ...state,
                district_list: district_list,
            };
            break;
        case GET_DISTRICT_LIST.concat("_FAILURE"):
            console.log(4)
            district_list = Object.assign({}, state.district_list);
            district_list.loading = false;
            state = {
                ...state,
                district_list: district_list
            }
            break;
        
        case GET_MUNICIPALITY_LIST:
            municipality_list = Object.assign({}, state.municipality_list);
            municipality_list.list.loading = true;
            state = {
                ...state,
            }
            break;
        case GET_MUNICIPALITY_LIST.concat("_SUCCESS"):
            municipality_list = Object.assign({}, state.municipality_list);
            municipality_list.list.loading = false;
            municipality_list.list.data = action.payload;

            console.log("Municipality List", municipality_list);
            state = {
                ...state,
                municipality_list: municipality_list,
            };
            break;
        case GET_MUNICIPALITY_LIST.concat("_FAILURE"):
            municipality_list = Object.assign({}, state.municipality_list);
            municipality_list.loading = false;
            state = {
                ...state,
                municipality_list: municipality_list
            }
            break;
            
        case GET_ADMINISTRATIVE_UNIT_LIST:
            administrative_unit_list = Object.assign({}, state.administrative_unit_list);
            administrative_unit_list.list.loading = true;
            state = {
                ...state,
            }
            break;
        case GET_ADMINISTRATIVE_UNIT_LIST.concat("_SUCCESS"):

            administrative_unit_list = Object.assign({}, state.administrative_unit_list);
            administrative_unit_list.list.loading = false;
            administrative_unit_list.list.data = action.payload;

            state = {
                ...state,
                administrative_unit_list: administrative_unit_list,
            };
            break;
        case GET_ADMINISTRATIVE_UNIT_LIST.concat("_FAILURE"):
            console.log(4)
            district_list = Object.assign({}, state.district_list);
            district_list.loading = false;
            state = {
                ...state,
                administrative_unit_list: administrative_unit_list
            }
            break;
        
        case GET_VILLAGE_LIST:
            village_list = Object.assign({}, state.village_list);
            village_list.list.loading = true;
            state = {
                ...state,
            }
            break;
        case GET_VILLAGE_LIST.concat("_SUCCESS"):
            village_list = Object.assign({}, state.village_list);
            village_list.list.loading = false;
            village_list.list.data = action.payload;

            console.log("village_list", village_list)

            state = {
                ...state,
                village_list: village_list,
            };
            break;
        case GET_VILLAGE_LIST.concat("_FAILURE"):
            console.log(4)
            village_list = Object.assign({}, state.village_list);
            village_list.loading = false;
            state = {
                ...state,
                village_list: village_list
            }
            break;
        default:
            state = { ...state };
            break;
    }

    return state;
};
