export const SET_REGISTRATION_VALUE = 'SET_REGISTRATION_VALUE';
export const SET_ERROR_MAP_FALSE = 'SET_ERROR_MAP_FALSE';
export const SET_ERROR_MAP_TRUE = 'SET_ERROR_MAP_TRUE';
export const REGISTER_NEW_USER = 'REGISTER_NEW_USER';
export const SET_MAP_CODE_SELECTED = 'SET_MAP_CODE_SELECTED';
export const SET_REDIRECT_TO_HOME_FALSE = 'SET_REDIRECT_TO_HOME_FALSE';
export const SET_ERROR_CODE_FALSE = 'SET_ERROR_CODE_FALSE';
// export const UPLOAD_MEDIA = ' UPLOAD_MEDIA';
// export const UPLOAD_NEW_UPDATE = 'UPLOAD_NEW_UPDATE';
// export const SET_NEW_UPDATE_VALUE = 'SET_NEW_UPDATE_VALUE';
export const CLEAR_FORM = 'CLEAR_FORM';
export const GET_USER_ROLES = 'GET_USER_ROLES';

