import {
    SET_ERROR_MAP_FALSE,
    SET_ERROR_MAP_TRUE,
    SET_REGISTRATION_VALUE,
    REGISTER_NEW_USER,
    SET_MAP_CODE_SELECTED,
    SET_REDIRECT_TO_HOME_FALSE,
    SET_ERROR_CODE_FALSE,
    // UPLOAD_MEDIA,
    CLEAR_FORM,
    GET_USER_ROLES,
} from "./actionTypes";

import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import cookie from "react-cookies";
// import { SET_NEW_UPDATE_VALUE, UPLOAD_NEW_UPDATE } from "../user/actionTypes";

function setTokenCookie(token) {
    cookie.save("token", token, {
        "path": "/"
    });
}


const initialState = {
    registration_data: {},
    error_map: false,
    map_code_selected: '',
    redirect_to_home: false,
    code_error: false,
    // new_update: {
    //     bussines_img: null
    // },
    upload_loading: false,
    user_roles: {
        list: {
            data: [],
            loading: false
        }
    },
};

export default (state = initialState, action) => {
    let shareholder_data;
    let registrationData;
    let error_map;
    let map_code_selected;
    let redirect_to_home;
    let code_error;
    let profile_categories;
    let new_update;
    let upload_loading;
    let user_roles;
    switch (action.type) {
        case SET_REGISTRATION_VALUE:
            registrationData = Object.assign({}, state.registration_data);
            console.log("registration_data", registrationData);

            registrationData[action.payload.field] = action.payload.value;
            state = {
                ...state,
                registration_data: registrationData
            }
            break;
        case SET_ERROR_MAP_FALSE:
            error_map = state.error_map;
            error_map = false;
            state = {
                ...state,
                error_map: error_map
            }
            break;
        case SET_ERROR_MAP_TRUE:
            error_map = state.error_map;
            error_map = true;
            state = {
                ...state,
                error_map: error_map
            }
            break;
        case REGISTER_NEW_USER.concat("_SUCCESS"):
            toast.success('Të dhënat e profilit u ruajtën me sukses!', {position: "top-center"});
            setTokenCookie(action.payload.token);            
            redirect_to_home = state.redirect_to_home;
            redirect_to_home = true;
            state = {
                ...state,
                redirect_to_home: redirect_to_home
            }
            break;
        case REGISTER_NEW_USER.concat("_FAILURE"):
            toast.error(action.payload.data.message, {position: "top-center"});
            code_error = state.code_error;

            if (action.payload.data.errorCode === "999") {
                code_error = true;
            }
            state = {
                ...state,
                code_error: code_error
            }
            break;
        case SET_MAP_CODE_SELECTED:
            map_code_selected = state.map_code_selected;
            map_code_selected = action.payload;
            state = {
                ...state,
                map_code_selected: map_code_selected
            }
            break;
        case SET_REDIRECT_TO_HOME_FALSE:
            redirect_to_home = state.redirect_to_home;
            redirect_to_home = false;
            state = {
                ...state,
                redirect_to_home: redirect_to_home
            }
            break;
        case SET_ERROR_CODE_FALSE:
            code_error = state.code_error;
            code_error = false;
            state = {
                ...state,
                code_error: code_error
            }
            break;
        // case SET_NEW_UPDATE_VALUE:
        //     new_update = Object.assign({}, state.new_update);
        //     new_update[action.payload.field] = action.payload.value;
        //     state = {
        //         ...state,
        //         new_update: new_update
        //     }
        //     break;
        // case UPLOAD_NEW_UPDATE:
        //     upload_loading = state.upload_loading;
        //     upload_loading = true;
        //     state = {
        //         ...state,
        //         upload_loading: upload_loading
        //     }
        //     break;
        // case UPLOAD_NEW_UPDATE.concat('_SUCCESS'):
        //     upload_loading = state.upload_loading;
        //     upload_loading = false;
        //     state = {
        //         ...state,
        //         upload_loading: upload_loading
        //     }
        //     toast.success('Caricato con successo!', {position: "top-center"})
        //     break;
        // case UPLOAD_NEW_UPDATE.concat('_FAILURE'):
        //     upload_loading = state.upload_loading;
        //     upload_loading = false;
        //     state = {
        //         ...state,
        //         upload_loading: upload_loading
        //     }
        //     toast.error('Caricamento fallito!', {position: "top-center"});
        //     break;
        // case UPLOAD_MEDIA:
        //     new_update = Object.assign({}, state.new_update);
        //     new_update.bussines_img = action.payload;
        //     console.log("LOGO UPLOAD", new_update.file)
        //     state = {
        //         ...state,
        //         new_update: new_update
        //     }

        //     break;
        case CLEAR_FORM:
            new_update = Object.assign({}, state.new_update);
            new_update.benchmark = '';
            new_update.title = '';
            new_update.description = '';
            new_update.bussines_img = null;
            state = {
                ...state,
                new_update: new_update
            }
            break;
        case GET_USER_ROLES:
            console.log(2)
            user_roles = Object.assign({}, state.user_roles);
            user_roles.list.loading = true;
            state = {
                ...state,
            }
            break;
        case GET_USER_ROLES.concat("_SUCCESS"):
            user_roles = Object.assign({}, state.user_roles);
            user_roles.list.loading = false;
            user_roles.list.data = action.payload;

            console.log(3, user_roles)

            state = {
                ...state,
                user_roles: user_roles,
            };
            break;
        case GET_USER_ROLES.concat("_FAILURE"):
            console.log(4)
            user_roles = Object.assign({}, state.user_roles);
            user_roles.loading = false;
            state = {
                ...state,
                user_roles: user_roles
            }
            break;
        default:
            state = { ...state };
            break;
    }

    return state;
};
