import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { doLogout } from '../../store/auth/actions'

import logo from '../../assets/images/logo.png'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      is_open: false,
      logged_out: {
        login_active: false,
        register_active: false,
      },
      logged_in: {
        profile_active: true,
      },
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.handleNavbarActiveTab()
    }
  }

  componentDidMount() {
    this.handleNavbarActiveTab()
  }

  toggleMenu = () => {
    document.getElementById('isToggle').classList.toggle('open')
    const isOpen = document.getElementById('navigation')
    if (isOpen.style.display === 'block') {
      isOpen.style.display = 'none'
    } else {
      isOpen.style.display = 'block'
    }
  }

  logout = () => {
    const { doLogout } = this.props
    doLogout()
  }

  goToUserProfile = () => {
    console.log("gotoprofile")
    const self = this
    // const url = '/'.concat(self.props.auth.user?.role.toLowerCase().concat('-profile'))
    // console.log("URL", url);


    self.props.history.push('/dashboard');
  }

  handleToggedLoggedIn = (type) => {
    const self = this

    console.log("Type", type);

    if (type === 'profile') {
      self.setState((prevState) => ({
        ...prevState,
        logged_in: {
          profile_active: true,
        },
      }))
      self.goToUserProfile()
    }
  }

  handleToggedLoggedOut = (type) => {
    const self = this
    if (type === 'login') {
      self.setState((prevState) => ({
        ...prevState,
        logged_out: {
          login_active: true,
        },
      }))
      self.props.history.push('/login')
    }
  }

  handleHeaderLogoClick = (e) => {
    const self = this
    e.preventDefault()
    self.setState((prevState) => ({
      ...prevState,
      logged_in: {
        profile_active: false,
      },
      logged_out: {
        login_active: false,
      },
    }))
    self.props.history.push('/')
  }

  goToPdf = (e) => {
    e.preventDefault()
    alert('go to fdf')
  }

  handleNavbarActiveTab = () => {
    const self = this
    if (self.props.location.pathname.includes('profile')) {
      self.setState((prevState) => ({
        ...prevState,
        logged_in: {
          profile_active: true,
        },
      }))
    }
    if (self.props.location.pathname === '/') {
      self.setState((prevState) => ({
        ...prevState,
        logged_in: {
          profile_active: false,
        },
        logged_out: {
          login_active: false,
        },
      }))
    }

    if (self.props.location.pathname === '/login') {
      self.setState((prevState) => ({
        ...prevState,
        logged_in: {
          profile_active: false,
        },
        logged_out: {
          login_active: true,
        },
      }))
    }

    if (self.props.location.pathname === '/krijo-perdorues') {
      self.setState((prevState) => ({
        ...prevState,
        logged_in: {
          profile_active: false,
        },
        logged_out: {
          login_active: false,
        },
      }))
    }
  }

  render() {
    const { currentUser } = this.props
    const { logged_in, logged_out } = this.state
    return (
      <React.Fragment>
        <header id="basf-header" className="">
          <div className="container">
            <a className="logo" onClick={(e) => this.handleHeaderLogoClick(e)}>
              <img
                src={logo}
                height="60"
                className="logo-light-mode cursor_pointer"
                alt=""
              />
            </a>
          </div>
        </header>
        <section className="top-bar" id="topnav">
          <div className="container">
            <div className="menu-extras">
              <div className="menu-item">
                <a
                  className="navbar-toggle"
                  id="isToggle"
                  onClick={() => this.toggleMenu()}
                >
                  <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </a>
              </div>
            </div>
            {/* <div className="naming">
              <a
                href={process.env.REACT_APP_URL.concat('/crediti')}
                target="_blank"
                className="text_green"
                title="Shërbimi Social Shtetëror®"
              >
                Shërbimi Social Shtetëror<sup>®</sup>
              </a>
            </div> */}
            <div id="navigation">
              {currentUser !== null ? (
                <ul className="navigation-menu mt-sm-1 mt-0">
                  <li>
                    <a
                      className={
                        logged_in.profile_active
                          ? 'sub-menu-item btn btn-primary border-button'
                          : 'sub-menu-item btn btn-outline-primary border-button'
                      }
                      onClick={() => this.handleToggedLoggedIn('profile')}
                    >
                      Dashboard
                    </a>
                  </li>
                  <li>
                    <a
                      className="sub-menu-item btn btn-outline-primary border-button"
                      onClick={() => this.logout()}
                    >
                      Shkyçu
                    </a>
                  </li>
                </ul>
              ) : (
                <ul className="navigation-menu mt-sm-1 mt-0 w-50%">
                  <li>
                    <a
                      className={
                        logged_out.login_active
                          ? 'sub-menu-item btn btn-primary border-button'
                          : 'sub-menu-item btn btn-outline-primary border-button'
                      }
                      onClick={() => this.handleToggedLoggedOut('login')}
                    >
                      Identifikohu
                    </a>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.user,
    auth: state.auth,
  }
}

const mapDispatchToProps = {
  doLogout,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
