import {
    REGISTER_ACT_OBSERVATION,
    SET_ERROR_CODE_FALSE,
    SET_ERROR_MAP_FALSE,
    SET_ERROR_MAP_TRUE,
    SET_MAP_CODE_SELECTED,
    SET_REDIRECT_TO_HOME_FALSE,


    SET_NEW_UPDATE_VALUE,
    
    SET_ACT_OBSERVATION_VALUE,
    SET_FAMILY_MEMBER_VALUE,
    UPLOAD_MEDIA,
    UPLOAD_NEW_UPDATE,
    UPLOAD_WORKING_GROUP_SIGNATURE,
    UPLOAD_FAMILY_MEMBERS_SIGNATURE,
    CLEAR_FORM,

    GET_DISTRICT_LIST,
    GET_MUNICIPALITY_LIST,    
    GET_ADMINISTRATIVE_UNIT_LIST,
    GET_VILLAGE_LIST,
} from "./actionTypes";



export const setNewUpdateValue = (field, value) => {
    return {
        type: SET_NEW_UPDATE_VALUE,
        payload: {
            field,
            value
        }
    }
}

export const setActObservationValue = (field, value) => {    
    return {
        type: SET_ACT_OBSERVATION_VALUE,
        payload: {
            field,
            value
        }
    }
}

export const setFamilyMemberValue = (field, value) => {
    return {
        type: SET_FAMILY_MEMBER_VALUE,
        payload: {
            field,
            value
        }
    }
}

export const registerAktObservation = (data) => {
    console.log("REGISTER_ACT_OBSERVATION", data)
    return {
        type: REGISTER_ACT_OBSERVATION,
        payload: data,
        api: true
    }
}

export const setErrorMapFalse = () => {
    return {
        type: SET_ERROR_MAP_FALSE
    }
}

export const setErrorMapTrue = () => {
    return {
        type: SET_ERROR_MAP_TRUE
    }
}

export const uploadNewUpdate = (selectedFiles) => {
    console.log("Selected Files")
    const formData = new FormData();
    formData.append('user_id', selectedFiles.user_id);
    formData.append('file_reference', selectedFiles.file_reference);

    for (const file of selectedFiles) {
      formData.append('files', file);
    }

    return {
        type: UPLOAD_NEW_UPDATE,
        payload: formData,
        api: true
    }
}

export const uploadWorkingGroupSignature = (selectedFiles) => {
    console.log("working group files")
    const formData = new FormData();
    formData.append('user_id', selectedFiles.user_id);
    formData.append('file_reference', selectedFiles.file_reference);

    for (const file of selectedFiles) {
      formData.append('files', file);
    }

    return {
        type: UPLOAD_WORKING_GROUP_SIGNATURE,
        payload: formData,
        api: true
    }
}

export const uploadFamilyMembersSignature = (selectedFiles) => {
    console.log("family members files")
    const formData = new FormData();
    formData.append('user_id', selectedFiles.user_id);
    formData.append('file_reference', selectedFiles.file_reference);

    for (const file of selectedFiles) {
      formData.append('files', file);
    }

    return {
        type: UPLOAD_FAMILY_MEMBERS_SIGNATURE,
        payload: formData,
        api: true
    }
}

export const setMapCodeSelected = (code) => {
    return {
        type: SET_MAP_CODE_SELECTED,
        payload: code
    }
}

export const setRedirectToHomeFalse = () => {
    return {
        type: SET_REDIRECT_TO_HOME_FALSE
    }
}

export const setErrorCodeFalse = () => {
    return {
        type: SET_ERROR_CODE_FALSE
    }
}

export const uploadMedia = (files) => {
    console.log("uploading files", files);
    return {
        type: UPLOAD_MEDIA,
        payload: files
    }
}

export const clearForm = () => {
    return {
        type: CLEAR_FORM
    }
}

export const getDistrictList = () => {
    console.log("get_district_list")
    return {
        type: GET_DISTRICT_LIST,
        api: true
    }
}

export const getMunicipalityList = () => {
    console.log("get_municipality_list")
    return {
        type: GET_MUNICIPALITY_LIST,
        api: true
    }
}

export const getAdministrativeList = () => {
    console.log("getAdministrativeList")
    return {
        type: GET_ADMINISTRATIVE_UNIT_LIST,
        api: true
    }
}

export const getVillageList = () => {
    console.log("getVillageList")
    return {
        type: GET_VILLAGE_LIST,
        api: true
    }
}