import React, { Component } from 'react'
import { connect } from 'react-redux'

import { setRedirectToHomeFalse } from '../store/user/actions'

import background_image from '../assets/images/bg.jpg'
import first_image from '../assets/images/1-1.jpg'
import second_image from '../assets/images/homepage.jpg'
import { jarallax } from 'jarallax'
import cookie from 'react-cookies'

import '../assets/css/bootstrap.min.css'
import '../assets/css/tobii.min.css'
import '../assets/css/materialdesignicons.min.css'
import '../assets/css/style.min.css'
import '../assets/css/custom.css'
import '../assets/css/colors/green.css'

import dotenv from "dotenv";
dotenv.config();

class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    jarallax(document.querySelectorAll('.jarallax'), { speed: 0.5 })

    const { user, setRedirectToHomeFalse } = this.props
    if (user.redirect_to_home) {
      setRedirectToHomeFalse()
    }
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="bg-auth-home d-table w-100 jarallax jarallax-img"
          style={{ background: `url(${background_image})`, backgroundColor: '#c62b02', position: 'relative', display: 'table' }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="title-heading">
                  <h1 className="heading fw-bold mb-5 mb-sm-2  mt-4 text-white">
                    Shërbimi Social Shtetëror
                  </h1>
                  {/* <h1 className="heading fw-bold text-white">
                    Krijoni Profilin e Biznesit Tuaj
                  </h1> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="section mt-5 pt-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="display-3 mb-5 font-400">Hai almeno 5 ettari di cereali?</div>
                            </div>
                            <div className="col-lg-5 col-md-6 order-md-1 order-3">
                                <div className="row align-items-center">
                                    <div className="col-lg-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                                        <div className="card overflow-hidden rounded-0 border-0">
                                            <div className="card-body p-0">
                                                <img src={first_image} className="img-fluid"
                                                     alt="Hai almeno 5 ettari di cereali?"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0 order-md-2 order-1 bg-light">
                                <div className="ms-lg-4">
                                    <div className="section-title">
                                        <div className="d-flex align-items-center pt-4 pb-2 border-bottom">
                                            <div className="number-li me-4">1</div>
                                            <div className="title-li font-700 text_green">Acquista Revycare<sup>®</sup> dal tuo rivenditore</div>
                                        </div>
                                        <div className="d-flex align-items-center pt-3 pb-2 border-bottom">
                                            <div className="number-li me-4">2</div>
                                            <div className="title-li font-700 text_green">Iscriviti e partecipa al programma <strong>Revy+</strong></div>
                                        </div>
                                        <div className="d-flex align-items-center pt-3 pb-2 border-bottom">
                                            <div className="number-li me-4">3</div>
                                            <div className="title-li font-700 text_green">Monitora il tuo campo benchmark di riferimento</div>
                                        </div>
                                        <div className="d-flex align-items-center pt-3 pb-3">
                                            <div className="number-li me-4">4</div>
                                            <div className="title-li font-700 text_green">Ottieni la possibilità di un rimborso a fine campagna</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!cookie.load('token') &&
                            <div className="col-md-6 offset-md-3 text-center order-2 mt-2 mt-sm-5">
                                <a className="mt-3 btn btn-primary border-button" style={{padding: "10px 30px"}}
                                   onClick={() => this.props.history.push('/krijo-perdorues')}><i
                                    className="uil uil-arrow-circle-right"/> Registrati</a>
                            </div>
                            }
                        </div>
                    </div>
                </section> */}

        <div className="position-relative">
          <div className="shape overflow-hidden text-light">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>

        <section className="bg-light">
          <div className="container pt-5 pb-5">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div className="section-title me-lg-5 font_helvetica">
                  <h2 className="section-title title mb-4 text_green">
                    Programi ynë
                  </h2>
                  {/* <p>
                    Con il programma <strong>Revy+</strong>, BASF si impegna a
                    dimostrare che l’utilizzo del suo rivoluzionario fungicida
                    <a
                      // href={process.env.REACT_APP_URL.concat('/krijo-perdorues')}
                      href={process.env.REACT_APP_URL}
                      target="_blank"
                      className="text_green"
                      title="Nëse nuk keni një profil Regjistrohu"
                    >
                      {' '}
                      Revycare<sup>®</sup>{' '}
                    </a>
                    nella strategia di difesa, oltre a proteggere il frumento e
                    l’orzo dalle più importanti malattie fungine, consente di
                    ottenere un incremento di produzione.
                  </p> */}
                  <p className="text-black">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 order-1 order-md-2">
                <img
                  src={second_image}
                  className="img-fluid"
                  alt="Programi SHSSH"
                />
              </div>
              <div className="col-md-6 offset-md-3 text-center order-2 mt-2 mt-sm-5">
                {/* <a
                  className="mt-3 btn btn-primary border-button"
                  style={{ padding: '10px 30px' }}
                  title="Scopri Revycare®"
                  href={process.env.REACT_APP_URL.concat('/krijo-perdorues')}
                  target="_blank"
                >
                  Nëse nuk keni një profil Regjistrohu
                </a> */}

                {!cookie.load('token') && (
                  <div className="col-md-6 offset-md-3 text-center order-2 mt-2 mt-sm-5">
                    <a
                      className="mt-3 btn btn-primary border-button"
                      style={{ padding: '10px 70px' }}
                      onClick={() => this.props.history.push('/krijo-perdorues')}
                    >
                      <i className="uil uil-arrow-circle-right" style={{marginRight: '5px'}}/>
                      Regjistrohu
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}

const mapDispatchToProps = {
  setRedirectToHomeFalse,
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
